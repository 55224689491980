import React, { useState, useEffect } from 'react'
import { Button, Col, notification, Popconfirm, Row, Space, Tooltip } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { capitalize, isEmpty, orderBy } from 'lodash'
import {
  Can,
  Copyable,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  PageContainer,
  PageTitleRow,
} from '../../components'
import { withConfig } from '../../modules/'
import { configPropTypes } from '../../types'
import {
  createDownloadLink,
  localizedDate,
  getTableHeaderCheckboxFilter,
  getTableHeaderDatePicker,
  getTableHeaderSearchInput,
  handleBackendError,
  useCustomSearchParams,
} from '../../utils'
import ScanModal from './ScanModal'
import ScanDetails from './ScanDetails'
import ScanCancellationModal from './ScanCancellationModal'

import { getScanList, exportScans, createScan } from './api'
import { getCountryList } from '../country-management/api'

export const Scan = ({ config }) => {
  const statusOptions = orderBy(config.config?.scan_statuses.map(status => ({ text: status, value: status })), 'text', 'desc')
  const types = orderBy(config.config?.user_types.map(type => ({ text: type, value: type })), 'text', 'desc')
  const scanTypes = [
    { text: 'Admin', value: 'admin' },
    { text: 'User', value: 'user' },
  ]

  const [searchParams] = useSearchParams()
  const [scans, setScans] = useState([])
  const [errorMessage, setErrorMessage] = useState()
  const [countries, setCountries] = useState([])
  const [selectedScan, setSelectedScan] = useState([])
  const [selectedBatchScans, setSelectedBatchScans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isDetailsVisible, setDetailsVisibility] = useState(false)
  const [isCancellationModalVisible, setCancellationModalVisibility] = useState(false)
  const [metaData, setMetaData] = useState()

  useCustomSearchParams({ items_per_page: 25, page: 1, sort_by: 'created_at desc' })

  const statusColor = {
    cancelled: 'red',
    completed: 'green',
    failed: 'red',
    pending: 'blue',
    rejected: 'red',
  }

  useEffect(() => {
    getCountryList()
      .then(({ data }) => {
        const filters = orderBy(data.data.map(({ id, name }) => ({ id, text: name, value: name })), 'text', 'asc')

        setCountries(filters)
      })
      .catch((error) => {
        handleFailure(error, 'Fetching countries failed!')
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchScanList(searchParams.toString())
  }, [searchParams])

  const fetchScanList = (params) => {
    setIsLoading(true)

    getScanList(params)
      .then(({ data }) => {
        setScans(data.data)
        setMetaData(data.meta)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        handleFailure(error, 'Fetching scan list failed!')
      })
  }

  const tableColumns = [
    {
      className: 'userIdSelenium',
      filteredValue: searchParams.getAll('user_id_eq'),
      key: 'user_id_eq',
      render: (_, { user_id }) => (
        <div style={{ maxWidth: 175 }}>
          <Copyable text={user_id} />
        </div>
      ),
      title: 'User Id',
      ...getTableHeaderSearchInput('userIdSelenium', 'user_id_eq'),
    },
    {
      className: 'scanTypeSelenium',
      dataIndex: 'scan_type',
      filteredValue: searchParams.getAll('scan_type_eq'),
      key: 'scan_type',
      render: (_, { scan_type }) => (
        <IconContainer>
          <Tooltip title={capitalize(scan_type)}>
            <Icon cursor="default" type={scan_type} />
          </Tooltip>
        </IconContainer>
      ),
      sorter: true,
      title: 'Scan type',
      ...getTableHeaderCheckboxFilter('scanTypeSelenium', scanTypes, 'scan_type_eq', 'single'),
    },
    {
      className: 'statusSelenium',
      dataIndex: 'status',
      filteredValue: searchParams.getAll('status_in[]'),
      key: 'status_in',
      render: (_, { status }) => (
        <Row gutter={8} justify="space-between" wrap={false}>
          <Col span={16}>
            <div style={{
              color: `${statusColor[status]}`,
              whiteSpace: 'nowrap',
            }}
            >
              {status}
            </div>
          </Col>
        </Row>),
      sorter: true,
      title: 'Status',
      ...getTableHeaderCheckboxFilter('statusSelenium', statusOptions, 'status_in', 'multiple'),
    },
    {
      className: 'userTypeSelenium',
      filteredValue: searchParams.getAll('user_type_eq'),
      key: 'user_type_eq',
      render: (_, { user }) => (
        <IconContainer>
          <Tooltip title={capitalize(user.user_type)}>
            <Icon cursor="default" type={user.user_type} />
          </Tooltip>
        </IconContainer>
      ),
      title: 'User Type',
      ...getTableHeaderCheckboxFilter('userTypeSelenium', types, 'user_type_eq', 'single'),
    },
    {
      className: 'userNameSelenium',
      dataIndex: 'user_name',
      filteredValue: searchParams.getAll('user_name_cont'),
      key: 'user_name_cont',
      render: (_, { user }) => (
        <span>{user.name}</span>
      ),
      sorter: true,
      title: 'User name',
      ...getTableHeaderSearchInput('userIdSelenium', 'user_name_cont'),
    },
    {
      className: 'storeNameSelenium',
      dataIndex: 'user_store_name_cont',
      filteredValue: searchParams.getAll('user_store_name_cont'),
      key: 'user_store_name_cont',
      render: (_, { user }) => (
        <span>{user.store_name}</span>
      ),
      title: 'Store name',
      ...getTableHeaderSearchInput('storeNameSelenium', 'user_store_name_cont'),
    },
    {
      className: 'countrySelenium',
      dataIndex: 'user_country_name',
      filteredValue: searchParams.getAll('user_country_name_eq'),
      key: 'user_country_name',
      render: (_, { user }) => (
        <span>{user.country_name}</span>
      ),
      sorter: true,
      title: 'Country',
      ...getTableHeaderCheckboxFilter('countrySelenium', countries, 'user_country_name_eq', 'single'),
    },
    {
      className: 'createdAtSelenium',
      dataIndex: 'created_at',
      defaultSortOrder: 'descend',
      filteredValue: searchParams.getAll(''),
      key: 'created_at',
      render: dateTime => (
        <span>{localizedDate(dateTime)}</span>
      ),
      sorter: true,
      title: 'Created at',
      ...getTableHeaderDatePicker('createdAtDatePickerSelenium', 'created_at_gteq', 'created_at_lteq'),
    },
    {
      className: 'finalizedAtSelenium',
      dataIndex: 'finalized_at',
      filteredValue: searchParams.getAll(''),
      key: 'finalized_at',
      render: dateTime => (
        <span>{localizedDate(dateTime)}</span>
      ),
      sorter: true,
      title: 'Finalized at',
      ...getTableHeaderDatePicker('finalizedAtDatePickerSelenium', 'finalized_at_gteq', 'finalized_at_lteq'),
    },
    {
      className: 'amountSelenium',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, {user, amount}) => (
        <div>{new Intl.NumberFormat(user.country_locales[0], { currency: user.currency_symbol, style: 'currency' }).format(amount)}</div>
      ),
      sorter: true,
      title: 'Amount',
    },
    {
      className: 'qrCodeSelenium',
      filteredValue: searchParams.getAll('qr_code_cont'),
      key: 'qr_code_cont',
      render: (_, record) => (
        <Tooltip title="Open scan details">
          <Can
            name="exportScansSelenium"
            no={() => (
              <p>{record.qr_code}</p>
            )}
            requiredPermission="scan.get.scan_id"
            yes={() => (
              <Button data-testid="scanDetailsBtn" name="qrCodeBtnSelenium" onClick={() => handleOpenDetailsPage (record)} type="link">{record.qr_code}</Button>
            )}
          />
        </Tooltip>
      ),
      title: 'QR code',
      ...getTableHeaderSearchInput('qrCodeSelenium', 'qr_code_cont'),
    },
    {
      className: 'actionsSelenium',
      key: 'actions',
      render: (_, record) => (
        <Col>
          <IconContainer>
            {record.status === 'pending' &&
            (
              <Can
                requiredPermission="scan.put.scan_id_cancel"
                yes={() => (
                  <Tooltip title="Cancel scan">
                    <Popconfirm
                      cancelText="No"
                      okText="Yes"
                      onConfirm={() => handleOpenCancellationPage([record.id])}
                      title="Are you sure you want to set scan status to 'cancelled'?"
                    >
                      <Icon cursor="pointer" data-testid="cancelIconSelenium" name="cancelIconSelenium" style={{ color: 'red' }} type="closeCircle" />
                    </Popconfirm>
                  </Tooltip>
                )}
              />
            )}
          </IconContainer>
        </Col>
      ),
      title: 'Actions',
    },

  ]

  const handleExportScansCSV = (type = 'export') => {
    exportScans(type, searchParams.toString())
      .then(({ blob, fileName }) => {
        createDownloadLink(blob, fileName)

        notification.success({
          description: 'Scans exported!',
          duration: 3,
          message: 'Success',
        })
      })
      .catch((error) => {
        handleFailure(error, 'Scans export failed!')
      })
  }

  const handleOpenModal = () => {
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchScanList(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)
    setCancellationModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const handleFormSubmit = (formData) => {
    createScan(formData)
      .then(() =>
        handleSuccess({ description: 'Scan added!' }),
      )
      .catch((error) =>
        handleFailure(error, 'Adding scan failed!'),
      )
  }

  const handleOpenDetailsPage = (selectedScan) => {
    setSelectedScan(selectedScan)
    setDetailsVisibility(true)
  }

  const handleOpenCancellationPage = (selectedScan) => {
    setSelectedScan(selectedScan)
    setCancellationModalVisibility(true)
  }

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.status !== 'pending',
    }),
    onChange: (selectedRowKeys) => {
      setSelectedBatchScans(selectedRowKeys)
    },
    selectedRowKeys: selectedBatchScans,
    type: 'checkbox',
  }

  const closeModalAndFetchList = (isSuccess = false) => {
    setCancellationModalVisibility(false)

    if (isSuccess) {
      fetchScanList(searchParams.toString())
      setSelectedBatchScans([])
    }
  }

  return (
    <PageContainer>
      <PageTitleRow title="Scans">
        <Space size="middle">
          <IconContainer>
            <Can
              requiredPermission="scan.put.scan_id_cancel"
              yes={() => (
                <Tooltip title="Cancel scan(s)">
                  <Button
                    danger
                    data-testid="batchCancelSelenium"
                    disabled={selectedBatchScans.length === 0}
                    name="batchCancelSelenium"
                    onClick={() => handleOpenCancellationPage(selectedBatchScans)}
                  >
                    {`Cancel selected scans ${selectedBatchScans.length > 0 ? ` (${selectedBatchScans.length})` : ''}`}
                  </Button>
                </Tooltip>
              )}
            />
          </IconContainer>
          <Can
            name="exportScansSelenium"
            requiredPermission="scan.get.export"
            yes={() => (
              <Button data-testid="exportScansWithoutItemsSelenium" name="exportScansWithoutItemsSelenium" onClick={() => handleExportScansCSV()}>
                Export CSV without items
              </Button>
            )}
          />
          <Can
            name="exportScansWithItemsSelenium"
            requiredPermission="scan.get.export_with_items"
            yes={() => (
              <Button data-testid="exportScansWithItemsSelenium" name="exportScansWithItemsSelenium" onClick={() => handleExportScansCSV('export_with_items')}>
                Export CSV with items
              </Button>
            )}
          />
          <Can
            requiredPermission="scan.post.create"
            yes={() => (
              <Button
                data-testid="createScanSelenium"
                name="createScanSelenium"
                onClick={() => handleOpenModal()}
                type="primary"
              >
                NEW ADMIN SCAN
              </Button>
            )}
          />
        </Space>
      </PageTitleRow>
      <Row>
        <Col span={24}>
          <CustomTable
            className="scanPageTableSelenium"
            columns={tableColumns}
            data-testid="scanPageTableSelenium"
            dataSource={scans}
            defaultOrder= {{
              sortBy: 'created_at',
              sortDir: 'descend',
            }}
            loading={isLoading}
            metaData={metaData}
            rowSelection={rowSelection}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible && (
            <ScanModal
              countries={countries}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              setModalLoading={setModalLoading}
            />
          )}

          {isDetailsVisible && (
            <ScanDetails
              hideDetails={() => setDetailsVisibility(false)}
              selectedScan={selectedScan}
            />
          )}

          {isCancellationModalVisible && (
            <ScanCancellationModal
              hideModal={closeModalAndFetchList}
              selectedScanIds={selectedScan}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

Scan.propTypes = {
  config: configPropTypes,
}

export default withConfig(Scan)
