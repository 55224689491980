import React, { useState } from 'react'
import styled from 'styled-components'
import { Route, Routes } from 'react-router-dom'
import { Layout, Row } from 'antd'
import {
  AdminUsersPage,
  BinsPage,
  BinTypesPage,
  ChangePasswordPage,
  CountryManagementPage,
  CreateNewStore,
  FaqCategories,
  FaqPage,
  HomePage,
  LoginPage,
  LogoutPage,
  NotificationPage,
  PageNotFound,
  StoresPage,
  ScanPage,
  UsersPage,
  WithdrawHistoryPage,
} from './pages'
import { Loading, SideMenu, TopMenu, getPermittedValues} from './components'
import { withConfig, withProfile } from './modules'
import { configPropTypes, userPropTypes } from './types'
import './default.scss'

const { Content, Header, Sider } = Layout

const POSSIBLE_PATHS = {
  admin_user: {
    component: <AdminUsersPage />,
    path: '/admin-user',
  },
  bin: {
    component: <BinsPage />,
    path: '/bins',
  },
  bin_type: {
    component: <BinTypesPage />,
    path: '/bin-types',
  },
  country: {
    component: <CountryManagementPage />,
    path: '/countries',
  },
  faq: {
    component: <FaqPage />,
    path: '/FAQs',
  },
  faq_category: {
    component: <FaqCategories />,
    path: '/faq_categories',
  },
  notification: {
    component: <NotificationPage />,
    path: '/notifications',
  },
  scan: {
    component: <ScanPage />,
    path: '/scans',
  },
  store: {
    component: <StoresPage />,
    path: '/stores',
  },
  user: {
    component: <UsersPage />,
    path: '/users',
  },
  withdrawal: {
    component: <WithdrawHistoryPage />,
    path: '/withdrawals',
  },
}

export const AppWithUser = ({ config, user }) => {
  const [isCollapsed, setCollapse] = useState(false)
  const permittedRoutes = getPermittedValues(POSSIBLE_PATHS, config.config.user_permissions)

  return (
    <>
      <StyledHeader>
        <div className="logo" />

        <TopMenu userEmail={user.me.email} />
      </StyledHeader>

      <LayoutWithMinheight hasSider>
        <StyledSider collapsed={isCollapsed} collapsible onCollapse={setCollapse}>
          <SideMenu userPermissions={config.config.user_permissions} />
        </StyledSider>

        <ContentLayout $collapsed={isCollapsed} className="site-layout">
          <StyledContent>
            <Routes>
              {permittedRoutes.map(({ component, path }) => <Route element={component} exact key={path} path={path} />)}

              <Route element={<ChangePasswordPage />} path="/change-password" />
              <Route element={<HomePage />} path="/" />
              <Route element={<LogoutPage />} path="/logout" />
              <Route element={<PageNotFound />} path="*" />
            </Routes>
          </StyledContent>
        </ContentLayout>
      </LayoutWithMinheight>
    </>
  )
}

AppWithUser.propTypes = {
  config: configPropTypes,
  user: userPropTypes,
}

export const AppWithoutUser = () => (
  <StyledFullPageContent className="site-layout">
    <Row align="middle" justify="center" type="flex">
      <Routes>
        <Route element={<CreateNewStore />} path="/new-store/:id" />
        <Route element={<LoginPage />} path="*" />
      </Routes>
    </Row>
  </StyledFullPageContent>
)

export const AppWithLayout = ({ config, user }) => {
  if (user.isLoading || config.isLoading)
    return <Loading />

  return (
    <Layout>
      {user.me?.id && config.config
        ? <AppWithUser config={config} user={user} />
        : <AppWithoutUser />
      }
    </Layout>
  )
}

const StyledFullPageContent = styled(Content)`
  min-height: 100vh;

  padding: 0 50px;
  margin: 64px 0;

  @media (max-width: 756px) {
    padding: 0;
    margin: 0;
  }
`

const StyledHeader = styled(Header)`
  position: sticky;
  z-index: 99;
  width: 100%;

  top: 0;

  @media (max-width: 756px) {
    padding: 0;
  }
`

const ContentLayout = styled(Layout)`
  margin: 16px 16px 0 ${props => props.$collapsed ? '80px' : '200px'};
  padding: 0 24px 24px;

  -moz-transition: all 0.3s ease-out;  /* FF4+ */
  -o-transition: all 0.3s ease-out;  /* Opera 10.5+ */
  -webkit-transition: all 0.3s ease-out;  /* Saf3.2+, Chrome */
  -ms-transition: all 0.3s ease-out;  /* IE10 */
  transition: all 0.3s ease-out;

  @media (max-width: 756px) {
    margin-right: 0;
  }
`

const StyledContent = styled(Content)`
  padding: 0;
  overflow: initial;
`

const LayoutWithMinheight = styled(Layout)`
  min-height: 100vh;
`

const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 64px;
  bottom: 0;
`

AppWithLayout.propTypes = {
  config: configPropTypes,
  user: userPropTypes,
}

export default withConfig(withProfile(AppWithLayout))
