import React from 'react'
import getDisplayName from '../../../hoc-utils/getDisplayName'
import { ConfigContext } from '../../'

export const withConfig = (Component) => {
  const WithConfig = (props) => (
    <ConfigContext.Consumer>
      {data => <Component {...props} config={data} />}
    </ConfigContext.Consumer>
  )

  WithConfig.displayName = `withConfig(${getDisplayName(Component)})`

  return WithConfig
}
