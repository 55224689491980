import React, { useEffect, useState } from 'react'
import { Button, Col, notification, Popconfirm, Row, Tooltip } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isEmpty, orderBy } from 'lodash'
import {
  Can,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  PageContainer,
  PageTitleRow,
} from '../../components'
import {
  handleBackendError,
  getTableHeaderCheckboxFilter,
  getTableHeaderSearchInput,
  useCustomSearchParams,
} from '../../utils'
import { withConfig, withProfile } from '../../modules'
import { configPropTypes, userPropTypes } from '../../types'
import AdminDetailsModal from './AdminDetailsModal'
import { createAdmin, deleteAdmin, getAdminList, updateAdmin } from './api'

export const AdminUsers = ({ config, user }) => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [admins, setAdmins] = useState([])
  const [selectedAdmin, setSelectedAdmin] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const isSuperAdmin = user?.me.roles.includes('super_admin')

  const [metaData, setMetaData] = useState()

  useCustomSearchParams({ items_per_page: 25, page: 1, sort_by: 'name asc' })

  const roles = orderBy(config.config?.admin_user_roles.map(admin_user_roles => ({ text: admin_user_roles, value: admin_user_roles })), 'text', 'asc')

  const navigate = useNavigate()

  useEffect(() => {
    if (isSuperAdmin) {
      if (!isEmpty(searchParams.toString()))
        fetchAdminList(searchParams.toString())
    } else {
      navigate('/')
    }
  }, [isSuperAdmin, navigate, searchParams])

  const fetchAdminList = (params) => {
    setIsLoading(true)

    getAdminList(params)
      .then(({ data }) => {
        setMetaData(data.meta)
        setAdmins(data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)

        handleFailure(error, 'Fetching admin users failed!')
      })
  }

  const tableColumns = [
    {
      className: 'nameSelenium',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      filteredValue: searchParams.getAll('name'),
      key: 'name',
      sorter: true,
      title: 'Name',
      ...getTableHeaderSearchInput('nameSelenium', 'name'),
    },
    {
      className: 'emailSelenium',
      dataIndex: 'email',
      filteredValue: searchParams.getAll('email'),
      key: 'email',
      title: 'Email',
      ...getTableHeaderSearchInput('emailSelenium', 'email'),
    },
    {
      className: 'roleSelenium',
      dataIndex: 'roles',
      filteredValue: searchParams.getAll('roles[]'),
      key: 'roles',
      render: (_, record) => (
        <div>{record.roles.join(', ')}</div>
      ),
      title: 'Role',
      ...getTableHeaderCheckboxFilter('roleSelenium', roles, 'roles', 'multiple'),
    },
    {
      className: 'countryActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          <Can
            requiredPermission="admin_user.get.admin_user_id"
            yes={() => (
              <Tooltip title="Edit">
                <Icon
                  data-testid="editBtn"
                  name="updateAdminSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="edit"
                />
              </Tooltip>
            )}
          />

          <Can
            requiredPermission="admin_user.delete.admin_user_id"
            yes={() => (
              <Tooltip title="Delete">
                <Popconfirm
                  cancelText="No"
                  className="action-list-button"
                  okText="Yes"
                  onConfirm={() => handleDeleteAdmin(record)}
                  title="Are you sure you want to delete this admin?"
                >
                  <Icon data-testid="deleteBtn" name="deleteAdminSelenium" type="delete" />
                </Popconfirm>
              </Tooltip>
            )}
          />
        </IconContainer>
      ),
      title: 'Actions',
    },
  ]

  const handleFormSubmit = (actionType, formData) => {
    switch (actionType) {
    case 'UPDATE':
      return updateAdmin(formData)
        .then(() =>
          handleSuccess({ description: 'Admin user updated' }),
        )
        .catch((error) =>
          handleFailure(error, 'Admin user update failed!'),
        )

    case 'CREATE':
      return createAdmin(formData)
        .then(() =>
          handleSuccess({ description: 'Admin user has been created' }),
        )
        .catch((error) =>
          handleFailure(error, 'Admin user creation failed!'),
        )
    default:
      return
    }
  }

  const handleDeleteAdmin = (record) => {
    return deleteAdmin(record.id)
      .then(() => handleSuccess({ description: 'Admin removed' }, true))
      .catch((error) => handleFailure(error, 'Admin removal failed!'))
  }

  const handleOpenModal = (selectedAdmin) => {
    setSelectedAdmin(selectedAdmin)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchAdminList(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  return (
    <PageContainer>
      <PageTitleRow title="Admins">
        <Can
          requiredPermission="admin_user.post.create"
          yes={() => (
            <Button
              name="createAdminSelenium"
              onClick={() => handleOpenModal(null)}
              type="primary"
            >
              NEW ADMIN
            </Button>
          )}
        />
      </PageTitleRow>
      <Row>
        <Col span={24}>
          <CustomTable
            className="adminUserPageTableSelenium"
            columns={tableColumns}
            data-testid="adminPage"
            dataSource={admins}
            defaultOrder={{
              sortBy: 'name',
              sortDir: 'ascend',
            }}
            loading={isLoading}
            metaData={metaData}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible && (
            <AdminDetailsModal
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onDelete={handleDeleteAdmin}
              onSubmit={handleFormSubmit}
              selectedAdmin={selectedAdmin}
              setModalLoading={setModalLoading}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

AdminUsers.propTypes = {
  config: configPropTypes,
  user: userPropTypes,
}

export default withConfig(withProfile(AdminUsers))
