import instance from '../../api'
import apiExportResponseHandler from '../../api-utils/apiExportResponseHandler'

const BASE_ENDPOINT = '/api/admin/users'

export const deactivateUser = (id) => instance.put(`${BASE_ENDPOINT}/${id}/deactivate`)

export const depersonalizeUser = (id) => instance.delete(`${BASE_ENDPOINT}/${id}`)

export const getUserTransactions = (id, search) => instance.get(`${BASE_ENDPOINT}/${id}/transactions/${search ? `?${search}` : ''}`)

export const getUserList = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const getUserFilteredList = (search) => instance.get(`${BASE_ENDPOINT}/search_for_autocomplete${search ? `?${search}` : ''}`)

export const updateUser = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.id}`, fields)

export const confirmUser = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.id}/confirm`, fields)

export const exportUsers = (filter) => (
  fetch(`${BASE_ENDPOINT}/export${filter ? `?${filter}` : ''}`,
    {
      headers: {
        'content-type': 'text/csv;charset=UTF-8',
      },
    })
    .then(apiExportResponseHandler)
)
