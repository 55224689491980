import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { omit } from 'lodash'
import Icon, {
  AccountBookOutlined,
  ArrowRightOutlined,
  BarcodeOutlined,
  BankOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DesktopOutlined,
  EditOutlined,
  EyeOutlined,
  PartitionOutlined,
  RobotOutlined,
  ShoppingOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons'

const iconTypes = {
  adjust: CarryOutOutlined,
  admin: DesktopOutlined,
  arrowRight: ArrowRightOutlined,
  bank: BankOutlined,
  barcode: BarcodeOutlined,
  checkCircle: CheckCircleOutlined,
  close: CloseOutlined,
  closeCircle: CloseCircleOutlined,
  corporate: ShoppingOutlined,
  delete: DeleteOutlined,
  duplicate: CopyOutlined,
  edit: EditOutlined,
  eye: EyeOutlined,
  partition: PartitionOutlined,
  payOut: AccountBookOutlined,
  regular: UserOutlined,
  user: UserOutlined,
  userInactivate: UserDeleteOutlined,
  virtual: RobotOutlined,
}

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

const CustomIcon = React.forwardRef((props, ref) => {
  const cleanedProps = props.disabled ? omit(props, 'onClick') : props

  return <Icon component={iconTypes[props.type]} data-testid="customIcon" ref={ref} {...cleanedProps} />
})

const StyledIcon = styled(CustomIcon)`
  cursor: ${({ cursor, disabled = false }) => disabled ? 'not-allowed' : cursor} !important;

  opacity: ${({ disabled }) => disabled ? .4 : 1};

  font-size: 18px;
  margin: 0 5px;

  &:hover {
    color: #0095FF;
  };

`

CustomIcon.displayName = 'Icon'

CustomIcon.propTypes = {
  cursor: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
}

export default StyledIcon
