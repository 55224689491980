import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Divider, Layout, Row } from 'antd'

export const PageContainer = styled(Layout.Content)`
  margin: 24px 16px;
  padding: 1em 1.5em;
  background-color: white;

  @media (max-width: 756px) {
    margin: 24px 0;
  }
`

const PageTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: 0;
  margin-block-end: 0;

  margin-top: 0;
`

export const TitleRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 1em 0 0;
`

export const PageTitleRow = ({ children, title, titleChildren }) => (
  <>
    <TitleRow>
      <PageTitle>{title}</PageTitle>
      {titleChildren}
      <div>{children}</div>
    </TitleRow>
    <Divider />
  </>
)

PageTitleRow.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  titleChildren: PropTypes.node,
}
