import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isArray } from 'lodash'
import { Button, Empty, Table } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useCustomSearchParams } from '../../utils'

const RowContext = React.createContext({})

export const DragButton= () => {
  const { setActivatorNodeRef, listeners } = React.useContext(RowContext)

  return (
    <Button
      icon={<HolderOutlined />}
      ref={setActivatorNodeRef}
      size="small"
      style={{
        cursor: 'move',
      }}
      type="text"
      {...listeners}
    />
  )
}

const StyledTable = styled(Table)`
  margin-top: 10px;

  .ant-table-row:nth-child(even) {
    background-color: #f6f6f6 !important;
  }

  thead > tr > th {
    background-color: #f0f0f0;
  }
`

export const TableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    data: {
      position: isArray(props?.children) ? props.children[0].props.record.position : null,
    },
    id: props['data-row-key'],
  })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isArray(props?.children) && isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  }

  const contextValue = React.useMemo(() => ({
    listeners,
    setActivatorNodeRef,
  }), [setActivatorNodeRef, listeners])

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  )
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  'data-row-key': PropTypes.string,
}

const CustomTable = (props) => {
  const {
    defaultOrder = { sortBy: 'created_at', sortDir: 'descend' },
    isModalTable = false,
    metaData,
    pagination = false,
    rowKey = 'id',
  } = props

  const [sortDir, setSortDir] = useState(defaultOrder.sortDir)
  const [sortBy, setSortBy] = useState(defaultOrder.sortBy)

  const [search, setSearch] = useCustomSearchParams()

  const handleFilterChange = (_, __, sorter) => {
    if (sortBy && sortDir && (!sorter.column || !sorter.order)) {
      const tempParams = { ...search }

      delete tempParams.sort_by

      setSortBy(undefined)
      setSortDir(undefined)
      setSearch({ ...tempParams })
    } else if (sortBy !== sorter.column?.dataIndex || sortDir !== sorter.order) {
      const sortDirection = sorter.order === 'ascend' ? 'asc' : 'desc'

      setSortBy(sorter.column.dataIndex)
      setSortDir(sorter.order)
      setSearch(
        isModalTable ?
          { ...search, modal_items_per_page: metaData.items_per_page, modal_page: metaData.page, modal_sort_by: `${sorter.column.dataIndex} ${sortDirection}` }
          : { ...search, items_per_page: metaData.items_per_page, page: metaData.page, sort_by: `${sorter.column.dataIndex} ${sortDirection}` }
      )
    }
  }

  return (
    <StyledTable
      {...props}
      bordered
      locale={{ emptyText: <Empty description="No data to show" /> }}
      onChange={handleFilterChange}
      pagination={pagination}
      rowKey={rowKey}
      scroll={{ x: true }}
    />
  )
}

CustomTable.propTypes = {
  defaultOrder: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
  }),
  isModalTable: PropTypes.bool,
  metaData: PropTypes.object,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  rowKey: PropTypes.string,
}

export const DragAndDrop = ({ children, items, onDragEnd }) => (
  <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
    <SortableContext items={items} strategy={verticalListSortingStrategy}>
      {React.cloneElement(children, {
        components: {
          body: {
            row: TableRow,
          },
        },
      })}
    </SortableContext>
  </DndContext>
)

DragAndDrop.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
  onDragEnd: PropTypes.func.isRequired,
}

export default CustomTable
