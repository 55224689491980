import instance from '../../api'

export const saveEmail = (email) => {
  localStorage.setItem('user_email', email)
}

export const removeEmail = () => {
  localStorage.removeItem('user_email')
}

export const getAccessEmail = () => localStorage.getItem('user_email')
export const isEmailExist = () => !!localStorage.getItem('user_email')

export const tokenFetchRequest = (requestBody) => (
  instance.post('/admin_users/sign_in', requestBody)
)

export const signOut = () => (
  instance.delete('/admin_users/sign_out')
)

export const signIn = ({ email, password }) => {
  const requestBody = {
    admin_user: {
      email,
      password,
    },
  }

  return tokenFetchRequest(requestBody)
}
