import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { ModifiedModalFooter } from '../../elements'
import { Can } from '../../components'
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Spin,
} from 'antd'
import { handleBackendError } from '../../utils'
import { getUserFilteredList } from '../users/api'

const { Option } = Select

export const ScanModal = ({
  countries,
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const [filteredUserList, setFilteredUsers] = useState([])
  const [fetching, setFetching] = useState(false)
  const onUserSearch = (searchValue) => {
    if (!form.getFieldValue('country_id') || searchValue.length < 3)
      return null

    const selectedCountryId = form.getFieldValue('country_id')

    const searchParam = `id_or_name_or_email=${searchValue}&country_id=${selectedCountryId}`
    setFetching(true)

    getUserFilteredList(searchParam)
      .then(({ data }) => {
        const result = data.data.map((user) => ({
          label: `${user.name} (${user.email})`,
          value: user.id,
        }))

        setFilteredUsers(result)
        setFetching(false)
      })
      .catch((error) => {
        notification.error({
          description: handleBackendError(error, 'Fetching user list failed!'),
          message: 'Error',
        })

        setFetching(false)
      })
  }

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const user_id = form.getFieldValue('user_id')
          onSubmit({
            ...form.getFieldsValue(['qr_code', 'comment']),
            user_id,
          })

        }).catch((error) => {
          notification.error({
            description: handleBackendError(error, 'Form is invalid!'),
            message: 'Error',
          })
        })
    } else {
      hideModal()
    }
  }

  const hasCountryId = Form.useWatch('country_id', form)
  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <Space>
            <Button key="back" name="cancelScanModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              name="exportScansSelenium"
              requiredPermission="scan.post.create"
              yes={() => (
                <Button data-testid="submitScanModalSelenium" key="submit" loading={isModalLoading} name="submitScanModalSelenium" onClick={handleSubmit} type="primary">
                  Ok
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      open
      title="New Admin Scan"
    >
      <Form
        data-test="scan-details-form"
        data-testid="scanForm"
        form={form}
        layout="vertical"
      >
        <Form.Item label="Country" name="country_id" rules={[{ message: 'Please select a country!', required: true }]}>
          <Select data-testid="country_id">
            {countries.map(({ id, text }) => <Option key={id} value={id}>{text}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label="User name or email" name="user_id" rules={[{ message: 'Please select a user!', required: true }]}>
          <Select
            data-testid="user_id"
            disabled={!hasCountryId}
            filterOption={false}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onSearch={debounce(onUserSearch, 1000)}
            options={filteredUserList}
            showSearch
          />
        </Form.Item>

        <Form.Item label="QR code" name="qr_code" rules={[{ message: 'Please provide a valid QR code!', required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Comment"
          name="comment"
          rules={[{ message: 'Please provide your comment!', required: true }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

ScanModal.propTypes = {
  countries: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setModalLoading: PropTypes.func.isRequired,
}

export default ScanModal
