const createDownloadLink = (blob, originalFileName) => {
  const fileName = originalFileName.replace('attachment; filename*=UTF-8\'\'', '')

  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export default createDownloadLink
