import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '../../'
import instance from '../../../api'

class UserProvider extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      user: null,
    }
  }

  componentDidMount() {
    this.refetchUser()
  }

  refetchUser = () => {
    instance.get('/api/admin/admin_users/me')
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          user: data.data,
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          user: null,
        })
      })
  }

  render() {
    const { children } = this.props
    const { isLoading, user } = this.state
    const value = {
      isLoading,
      me: user || null,
      refetch: this.refetchUser,
    }

    return (
      <UserContext.Provider value={value}>
        {children}
      </UserContext.Provider>
    )
  }
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserProvider
