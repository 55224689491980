import React, { useState }from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Form, Input, Button, notification, Typography, Space } from 'antd'
import { PageContainer }from '../../../components'
import { withProfile } from '../../../modules'
import { userPropTypes } from '../../../types'
import { handleBackendError } from '../../../utils'
import { changePassword } from './api'

const { Title } = Typography

const ChangePassword = ({ user }) => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (form) => {
    const { changedPassword, confirmPassword } = form

    setIsLoading(true)

    changePassword(user.me.id, {
      password: changedPassword,
      password_confirmation: confirmPassword,
    })
      .then(() => {
        notification.success({
          description: 'Password has been changed',
          duration: 3,
          message: 'Success',
        })

        navigate('/logout')
      })
      .catch((error) => {
        notification.error({
          description: handleBackendError(error, 'Password change failed!'),
          message: 'Error',
        })

        setIsLoading(false)
      })
  }

  return (
    <PageContainer>
      <Row align="middle" justify="center" type="flex">
        <Col className="site-layout-content site-layout-background" span="14">
          <Form
            labelCol={{ span: 16 }}
            layout="vertical"
            name="password"
            onFinish={onFinish}
          >
            <Space
              direction="vertical"
              size="large"
              style={{
                display: 'flex',
              }}
            >
              <Title level={4}>Change Password</Title>
              <Form.Item
                hasFeedback
                label="New Password"
                name="changedPassword"
                rules={[
                  {
                    message: 'Please input your new password!',
                    required: true,
                  },
                  {
                    message: 'Password must be minimum 8 characters.', min: 8,
                  },
                  {
                    message: 'Must have at least an uppercase letter',
                    pattern: /^.*[A-Z].*$/,
                  },
                  {
                    message: 'Must have at least a number',
                    pattern: /.*[0-9].*/ ,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Space>

            <Form.Item
              dependencies={['changedPassword']}
              hasFeedback
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                {
                  message: 'Please confirm your password!',
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('changedPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('The two passwords do not match!'))
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item className="center" wrapperCol={{ offset: 6, span: 12 }}>
              <Button
                disabled={isLoading}
                htmlType="submit"
                name="changeSubmitBtnSelenium"
                type="primary"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </PageContainer>
  )
}

ChangePassword.propTypes = {
  user: userPropTypes,
}

export default withProfile(ChangePassword)

