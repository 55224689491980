import instance from '../../api'
import apiExportResponseHandler from '../../api-utils/apiExportResponseHandler'

const BASE_ENDPOINT = '/api/admin/stores'

export const createStore = (fields) => instance.post(BASE_ENDPOINT, fields)

export const getStoreList = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const getBankData = (id) => instance.get(`${BASE_ENDPOINT}/${id}/bank_data`)

export const updateStore = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.id}`, fields)

export const getBalanceForRange = (id, dateRange) => instance.get(`${BASE_ENDPOINT}/${id}/balance_between?begin_date=${dateRange.begin_date}&end_date=${dateRange.end_date}`)

export const adjustBalanceBetween = (id, range) => instance.post(`${BASE_ENDPOINT}/${id}/adjust_balance_between`, range)

export const getStoresBatchBalance = (search) => instance.get(`${BASE_ENDPOINT}/batch_balance_between${search ? `?${search}` : ''}`)

export const createStoreLink = (fields) => instance.post(`${BASE_ENDPOINT}/create_with_share_link`, fields)

export const updateStoreLink = (id) => instance.put(`${BASE_ENDPOINT}/new_link/${id}`)

export const switchVirtualStore = (id) => instance.put(`${BASE_ENDPOINT}/${id}/switch_to_virtual`)

export const exportStores = (filter) => (
  fetch(`${BASE_ENDPOINT}/export${filter ? `?${filter}` : ''}`,
    {
      headers: {
        'content-type': 'text/csv;charset=UTF-8',
      },
    })
    .then(apiExportResponseHandler)
)
