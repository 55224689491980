import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { notification, Pagination } from 'antd'
import { isEmpty } from 'lodash'
import { useCustomSearchParams } from '../../utils'
import instance from '../../api'

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: end;
  padding-top: 1.5rem !important;
`

const CustomPagination = ({ metaData, prefix='', customParams={} }) => {
  const items_per_page = `${prefix}items_per_page`
  const page = `${prefix}page`

  const [search, setSearch] = useCustomSearchParams()

  useEffect(() => {
    if (isEmpty(search))
      setSearch({
        ...customParams,
        [items_per_page]: 25,
        [page]: 1,
      })
  }, [])

  const handlePaginationChange = (selectedPage, pageSize) => {
    setSearch({
      ...search,
      [items_per_page]: pageSize,
      [page]: pageSize.toString() !== search[items_per_page] ? 1 : selectedPage,
    })
  }

  instance.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.data?.details?.base) {
      if (error?.response?.data?.details?.base[0] && !!~error?.response?.data?.details?.base[0].search('page in')) {
        setTimeout(() => {
          notification.destroy()
        }, 10)

        setSearch({
          ...search,
          [page]: 1,
        })
      }
    }

    return Promise.reject(error)
  })

  return (
    <StyledPagination
      current={parseInt(search[page], 10) || 1}
      defaultCurrent={1}
      defaultPageSize={25}
      onChange={handlePaginationChange}
      pageSize={parseInt(search[items_per_page], 10) || 25}
      pageSizeOptions={[ 10, 25, 50, 100 ]}
      responsive
      showSizeChanger
      showTotal={(total) => `Total ${total} items`}
      total={metaData?.total_items}
    />
  )
}

CustomPagination.propTypes = {
  customParams: PropTypes.object,
  metaData: PropTypes.object,
  prefix: PropTypes.string,
}

export default CustomPagination
