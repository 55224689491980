import fallbackImage from './fallback-image.png'
import androidPreview from './android.png'
import iphonePreview from './iphone.png'
import longAndroidPreview from './long_android.png'
import longIphonePreview from './long_ios.png'

const images = {
  expanded: {
    androidPreview: longAndroidPreview,
    iphonePreview: longIphonePreview,
  },
  fallbackImage,
  initial: {
    androidPreview,
    iphonePreview,
  },
}

export default images
