import { isEmpty } from 'lodash'

const handleBackendError = (error, message) => {
  const errorMessage = error?.response?.data
    ? (error.response?.data?.details && !isEmpty(error.response?.data?.details))
      ?`${error.response?.data?.error}: "${Object.keys(error.response?.data?.details)[0]}" - ${Object.values(error.response?.data?.details)[0]}`
      : error.response?.data?.error
    : message

  return errorMessage.replace('abartn', 'ACH Routing Number')
}

export default handleBackendError
