import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { ModifiedModalFooter } from '../../elements'
import { Can } from '../../components'
import { Alert, Button, Form, Input, Modal, Popconfirm, Select, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const { Option } = Select

export const DeleteButton = ({ onDelete }) => (
  <Can
    key="delete"
    requiredPermission="bin_type.delete.bin_type_id"
    yes={() => (
      <Popconfirm
        cancelText="No"
        className="action-list-button"
        okText="Yes"
        onConfirm={onDelete}
        title="Are you sure you want to delete this bin type?"
      >
        <Button
          danger
          data-testid="deleteBtn"
          icon={<DeleteOutlined />}
          name="deleteBinTypeModalSelenium"
          style={{ float: 'left' }}
        >
          Delete
        </Button>
      </Popconfirm>
    )}
  />
)

DeleteButton.propTypes = {
  isModalAnUpdate: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
}

export const BinTypeModal = ({
  countries,
  errorMessage,
  hideModal,
  isModalLoading,
  onDelete,
  onSubmit,
  selectedBinType,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const isModalAnUpdate = !!selectedBinType

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const requiredFieldsValue = form.getFieldsValue([
            'country_id',
            'title',
            'slug',
            'info',
          ])
          const fieldsValue = {
            ...requiredFieldsValue,
            ...(isModalAnUpdate && { id: selectedBinType?.id }),
          }

          const actionType = isModalAnUpdate ? 'UPDATE' : 'CREATE'

          onSubmit(actionType, fieldsValue)
        })
    } else {
      hideModal()
    }
  }

  const handleNameChange = ({ target: { value }}) => {
    form.setFieldsValue({ slug: kebabCase(value) })
  }

  const initialValues = {
    country_id: selectedBinType?.country_id,
    info: selectedBinType?.info,
    slug: selectedBinType?.slug,
    title: selectedBinType?.title,
  }

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          {(isModalAnUpdate && (!selectedBinType?.bins_count || selectedBinType?.bins_count === 0)) &&
            <DeleteButton key="delete" onDelete={() => onDelete(selectedBinType)} />
          }
          <Space>
            <Button data-testid="cancelBtn" key="back" name="cancelBinTypeModalSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              key={isModalAnUpdate ? 'update' : 'add'}
              requiredPermission={isModalAnUpdate ? 'bin_type.put.bin_type_id' : 'bin_type.post.create'}
              yes={() => (
                <Button data-testid={isModalAnUpdate ? 'updateBtn' : 'addBtn'} key="submit" loading={isModalLoading} name={isModalAnUpdate ? 'updateBinTypeModalSelenium' : 'addBinTypeSelenium'} onClick={handleSubmit} type="primary">
                  {isModalAnUpdate ? 'Update' : 'Add'}
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title={isModalAnUpdate ? 'Update Bin type' : 'Create Bin type'}
    >
      <Form data-test="bin-type-details-form" form={form} initialValues={initialValues} layout="vertical">
        <Form.Item
          label="Name"
          min={15}
          name="title"
          onChange={handleNameChange}
          rules={[{ message: 'Please provide a bin type name!', required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Slug"
          name="slug"
          rules={[{ message: 'Please provide slug!', required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Country" name="country_id" rules={[{ message: 'Please select a country!', required: true }]}>
          <Select>
            {countries.map((country) =>
              <Option key={country.id} name="binTypeCountrysOption" value={country.id}>{country.name}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Info copy" name="info">
          <Input />
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

BinTypeModal.propTypes = {
  countries: PropTypes.array,
  errorMessage: PropTypes.node,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedBinType: PropTypes.object,
  setModalLoading: PropTypes.func.isRequired,
}

export default BinTypeModal
