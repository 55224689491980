import React, { useState } from 'react'
import {
  Alert,
  Button,
  Form,
  Modal,
  notification,
  Progress,
  Select,
  Space,
} from 'antd'
import PropTypes from 'prop-types'

import { setScanStatusToCanceled } from './api'

const { Option } = Select

export const ScanCancellationModal = ({
  hideModal,
  selectedScanIds,
}) => {
  const [form] = Form.useForm()
  const [isModalLoading, setModalLoading] = useState(false)
  const [progressBarPercent, setProgressBarPercent] = useState(0)
  const [progressBarColors, setProgressBarColors] = useState([])
  const [errors, setErrors] = useState([])
  let currentErrors = []

  const batchCancelCallApi = (cancellation_reason, index = 0) => {
    const hasNextStep = (index + 1) < selectedScanIds.length
    const progressBarPercent = parseFloat(((index + 1) / selectedScanIds.length) * 100).toFixed(2)
    const scan_id = selectedScanIds[index]

    setScanStatusToCanceled({
      cancellation_reason,
      scan_id,
    })
      .then(() => {
        setProgressBarColors(colors => [...colors, 'green'])
      })
      .catch((error) => {
        const message =error?.response?.data?.error || error.message

        setProgressBarColors(colors => [...colors, 'red'])
        currentErrors.push(`${index}: ${message}`)
      })
      .finally(() => {
        setTimeout(() => {
          setProgressBarPercent(progressBarPercent)

          if (hasNextStep) {
            batchCancelCallApi(cancellation_reason, index + 1)
          } else {
            if (currentErrors.length === 0) {
              notification.success({
                description: 'All selected scan set to cancelled!',
                message: 'Success',
              })
              hideModal(true)
            } else {
              setModalLoading(false)
              notification.error({
                description: '1 or more scan status change failed!',
                message: 'Error',
              })

              setErrors(currentErrors)
            }
          }
        }, 200)
      })
  }

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()
    const { cancellation_reason } = form.getFieldsValue()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)
          batchCancelCallApi(cancellation_reason)
        })
    } else {
      hideModal()
    }
  }

  const initialValues = {
    cancellation_reason: '',
  }

  return (
    <Modal
      footer={[
        <Space key="padding">
          <Button
            className="mr-3"
            data-testid="hideBtn"
            key="back"
            onClick={() => hideModal()}
            variant="outlined"
          >
              Cancel
          </Button>
          <Button
            className="bg-default"
            data-testid="cancelScan"
            key="submit"
            loading={isModalLoading}
            name="cancelScan"
            onClick={handleSubmit}
            type="primary"
          >
              Send
          </Button>
        </Space>,
      ]}
      onCancel={() => hideModal()}
      open
      title="Cancel Scan"
      width="40%"
    >
      <Form
        data-test="scanCancellationReason"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={handleSubmit}
      >

        <Form.Item
          className="cancellationReason"
          label="Cancellation Reason"
          name="cancellation_reason"
          rules={[
            {
              message: 'Please select a cancellation reason',
              required: true,
            },
          ]}
        >
          <Select data-testid="scanCancellationReason">
            <Option value="user_error">User error</Option>,
            <Option value="fraudulent_scan">Fraudulent scan</Option>,
            <Option value="180+_days_since_scan">180+ days since scan</Option>,
            <Option value="internal_testing">Internal testing</Option>,
            <Option value="balance_adjusted">Balance adjusted</Option>,
            <Option value="lost_sticker">Lost sticker</Option>,
          </Select>
        </Form.Item>

        {(isModalLoading || errors.length > 0) &&
          <Progress
            percent={progressBarPercent}
            status={(isModalLoading) ? 'normal' : (errors.length > 0) ? 'exception' : 'success'}
            strokeColor={progressBarColors}
          />
        }

        {(!isModalLoading && errors.length > 0) &&
        <Space direction="vertical" style={{ width: '100%' }}>
          {errors.map((error) => <Alert key={error} message={error} type="error" />)}
        </Space>
        }
      </Form>
    </Modal>
  )
}

ScanCancellationModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  selectedScanIds: PropTypes.array,
}

export default ScanCancellationModal
