import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { capitalize, compact, has, isEmpty, orderBy } from 'lodash'
import { Button, Col, notification, Popconfirm, Row, Tooltip } from 'antd'
import {
  Can,
  Copyable,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  PageContainer,
  PageTitleRow,
  TransactionHistoryModal,
} from '../../components'
import { withConfig } from '../../modules'
import { configPropTypes } from '../../types'
import {
  createDownloadLink,
  getTableHeaderCheckboxFilter,
  getTableHeaderDatePicker,
  getTableHeaderSearchInput,
  handleBackendError,
  localizedDate,
  useCustomSearchParams,
} from '../../utils'
import { getCountryList } from '../country-management/api'
import UserDetailsModal from './UserDetailsModal'
import UserConfirmationModal from './UserConfirmationModal'
import { confirmUser, deactivateUser, getUserList, updateUser, exportUsers, depersonalizeUser } from './api'

const StyledButton = styled(Button)`
  margin: 5px 0;
  font-size: 11px;
`

export const Users = ({ config }) => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [countryFilters, setCountryFilters] = useState([])
  const typeFilters = orderBy(config.config?.user_types.map(type => ({ text: type, value: type })), 'text', 'asc')
  const statusFilters = orderBy(config.config?.user_statuses.map(status => ({ text: status, value: status })), 'text', 'desc')
  const consentsFilters = [{ text: 'Market research consent - Accepted', value: true }, { text: 'Market research consent - Declined', value: false }]

  const [metaData, setMetaData] = useState()

  const [search, setSearch] = useCustomSearchParams({ items_per_page: 25, page: 1, sort_by: 'created_at desc' })

  useEffect(() => {
    getCountryList()
      .then(({ data }) => {
        setCountries(data.data)
        const filters = orderBy(data.data.map(country => ({ text: country.name, value: country.id })), 'text', 'asc')

        setCountryFilters(filters)
      })
      .catch((error) => {
        handleFailure(error, 'Fetching the countries failed!')
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()) && !isModalVisible.transactions)
      fetchUserList(searchParams.toString())
  }, [searchParams])

  const fetchUserList = (params) => {
    setIsLoading(true)

    getUserList(params)
      .then(({ data }) => {
        setUsers(data.data)
        setMetaData(data.meta)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)

        handleFailure(error, 'Fetching user list failed!')
      })
  }

  const userCanConfirm = has(config.config.user_permissions, 'user.put.user_id_confirm')

  const tableColumns = compact([
    {
      className: 'userIdSelenium',
      dataIndex: 'id',
      filteredValue: searchParams.getAll('id'),
      key: 'id',
      render: (_, { id }) => (
        <div style={{ maxWidth: 100 }}>
          <Copyable text={id} />
        </div>
      ),
      sorter: true,
      title: 'Id',
      ...getTableHeaderSearchInput('userIdSelenium', 'id'),
    },
    {
      className: 'userTypeSelenium',
      dataIndex: 'user_type',
      filteredValue: searchParams.getAll('user_type'),
      key: 'user_type',
      render: (_, record) => (
        <IconContainer>
          {
            <Tooltip title={capitalize(record.user_type)}>
              <Icon cursor="default" type={record.user_type} />
            </Tooltip>
          }

        </IconContainer>
      ),
      title: 'User type',
      ...getTableHeaderCheckboxFilter('userTypeSelenium', typeFilters, 'user_type', 'single'),
    },
    {
      className: 'userNameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.getAll('name'),
      key: 'name',
      sorter: true,
      title: 'Name',
      ...getTableHeaderSearchInput('userNameSelenium', 'name'),
    },
    {
      className: 'emailSelenium',
      dataIndex: 'email',
      filteredValue: searchParams.getAll('email'),
      key: 'email',
      render: (_, record) => (
        <Copyable text={record.email} />
      ),
      title: 'Email',
      ...getTableHeaderSearchInput('emailSelenium', 'email'),
    },
    {
      className: 'userCountrySelenium',
      filteredValue: searchParams.getAll('country_id'),
      key: 'country_id',
      render: (_, record) => (
        <>{record.country_name}</>
      ),
      title: 'Country',
      ...getTableHeaderCheckboxFilter('userCountrySelenium', countryFilters, 'country_id', 'single'),
    },
    {
      className: 'zipCodeSelenium',
      dataIndex: 'zip_code',
      filteredValue: searchParams.getAll('zip_code'),
      key: 'zip_code',
      title: 'Zip code',
      ...getTableHeaderSearchInput('zipCodeSelenium', 'zip_code'),
    },
    {
      className: 'storeSelenium',
      filteredValue: searchParams.getAll('store_name'),
      key: 'store_name',
      render: (_, record) => (
        <>{record.store_name}</>
      ),
      title: 'Store name',
      ...getTableHeaderSearchInput('storeSelenium', 'store_name'),
    },
    {
      className: 'statusSelenium',
      dataIndex: 'status',
      filteredValue: searchParams.getAll('status[]'),
      key: 'status',
      sorter: true,
      title: 'Status',
      ...getTableHeaderCheckboxFilter('statusSelenium', statusFilters, 'status', 'multiple'),
    },
    userCanConfirm
      ? {
        className: 'confirmationOptionsSelenium',
        key: 'confirmationOptions',
        render: (_, record) => (
          <Row>
            {record.status === 'validation_needed' &&
              <StyledButton
                data-testid="confirmBtn"
                name="confirmationButton"
                onClick={() => { handleOpenModal('confirmation', record)}}
                style={{ background: 'rgba(0,214,143,.08)', borderColor: '#00d68f', color: '#00d68f', width: '100%' }}
              >
                CONFIRM
              </StyledButton>
            }
          </Row>
        ),
        title: 'Confirmation options',
      }
      : null,
    {
      className: 'createdAtSelenium',
      dataIndex: 'created_at',
      filteredValue: searchParams.getAll(''),
      key: 'created_at',
      render: dateTime => (
        <span>{localizedDate(dateTime)}</span>
      ),
      sorter: true,
      title: 'Created at',
      ...getTableHeaderDatePicker('createdAtDatePickerSelenium', 'created_at_gteq', 'created_at_lteq'),
    },
    {
      className: 'numberOfPackagesReturnedSelenium',
      dataIndex: 'number_of_returned_items',
      key: 'number_of_returned_items',
      sorter: true,
      title: 'Number of packages returned',
    },
    {
      className: 'consentsSelenium',
      filteredValue: searchParams.getAll('market_research_consent'),
      key: 'market_research_consent',
      render: (_, record) => (
        <Row className="icon-wrapper">
          <Tooltip title="Marketing emails"><Icon cursor="default" type={record.marketing_consent ? 'checkCircle' : 'closeCircle'} /></Tooltip>
          <Tooltip title="Market research consent"><Icon cursor="default" type={record.market_research_consent ? 'checkCircle' : 'closeCircle'} /></Tooltip>
          <Tooltip title="Marketing push notification"><Icon cursor="default" type={record.marketing_notification_consent ? 'checkCircle' : 'closeCircle'} /></Tooltip>
        </Row>
      ),
      title: 'Consents',
      ...getTableHeaderCheckboxFilter('consentsSelenium', consentsFilters, 'market_research_consent', 'single'),
    },
    {
      className: 'balanceSelenium',
      dataIndex: 'balance',
      key: 'balance',
      render: (_, record) => (
        <Can
          no={() => (
            new Intl.NumberFormat(record.country_locales[0], { currency: record.currency_symbol, style: 'currency' }).format(record.balance)
          )}
          requiredPermission="user.get.user_id_transactions"
          yes={() => (
            <Tooltip title="Open transaction history">
              <Button
                data-testid="balanceBtnSelenium"
                name="balanceBtnSelenium"
                onClick={() => handleOpenModal('transactions', record)}
                type="link"
              >
                {new Intl.NumberFormat(record.country_locales[0], { currency: record.currency_symbol, style: 'currency' }).format(record.balance)}
              </Button>
            </Tooltip>
          )}
        />
      ),
      sorter: true,
      title: 'Balance',
    },
    {
      className: 'userActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          {record.status !== 'inactive' && record.user_type !== 'virtual' &&
          <Can
            no={() => (
              <Tooltip title="View">
                <Icon
                  data-testid="viewBtn"
                  name="updateUserSelenium"
                  onClick={() => { handleOpenModal('details', record)}}
                  type="eye"
                />
              </Tooltip>
            )}
            requiredPermission="user.put.user_id"
            yes={() => (
              <Tooltip title="Edit">
                <Icon
                  data-testid="editBtn"
                  name="updateUserSelenium"
                  onClick={() => { handleOpenModal('details', record)}}
                  type="edit"
                />
              </Tooltip>
            )}
          />
          }
          {record.status !== 'inactive' && record.user_type === 'regular' &&
            <Can
              requiredPermission="user.delete.user_id"
              yes={() => (
                <Tooltip title="Deactivate and Depersonalize">
                  <Popconfirm
                    cancelText="No"
                    className="action-list-button"
                    okText="Yes"
                    onConfirm={() => handleDepersonalizeUser(record)}
                    title="Are you sure you want to deactivate and depersonalize this user?"
                  >
                    <Icon data-testid="depersonalizeUserSelenium" name="depersonalizeUserSelenium" type="userInactivate" />
                  </Popconfirm>
                </Tooltip>
              )}
            />
          }
          {record.status !== 'inactive' && record.user_type === 'corporate' &&
            <Can
              requiredPermission="user.put.user_id_deactivate"
              yes={() => (
                <Tooltip title="Deactivate">
                  <Popconfirm
                    cancelText="No"
                    className="action-list-button"
                    okText="Yes"
                    onConfirm={() => handleDeactivateUser(record)}
                    title="Are you sure you want to deactivate this user?"
                  >
                    <Icon data-testid="deactivateUserSelenium" name="deactivateUserSelenium" type="userInactivate" />
                  </Popconfirm>
                </Tooltip>
              )}
            />
          }
        </IconContainer>
      ),
      title: 'Actions',
    },
  ])

  const handleExportUsersCSV = () => {
    exportUsers(searchParams.toString())
      .then(({ blob, fileName }) => {
        createDownloadLink(blob, fileName)

        notification.success({
          description: 'Users exported',
          duration: 3,
          message: 'Success',
        })
      })
      .catch((error) => {
        handleFailure(error, 'Users export failed!')
      })
  }

  const handleDeactivateUser = (record) => {
    deactivateUser(record.id)
      .then(() => handleSuccess({ description: 'User deactivated!' }))
      .catch((error) => handleFailure(error, 'User deactivation failed!'))
  }

  const handleDepersonalizeUser = (record) => {
    depersonalizeUser(record.id)
      .then(() => handleSuccess({ description: 'User deactivated and depersonalized!' }))
      .catch((error) => handleFailure(error, 'User deactivation and depersonalize failed!'))
  }

  const handleFormSubmit = (formData) => {
    return updateUser(formData)
      .then(() =>
        handleSuccess({ description: 'User updated' }),
      )
      .catch((error) =>
        handleFailure(error, 'User update failed!'),
      )
  }

  const handleOpenModal = (selectedModal, selectedUser) => {
    setModalVisibility({ [selectedModal]: true })
    setErrorMessage(null)
    setSelectedUser(selectedUser)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchUserList(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const handleUserConfirmation = (fields) => {
    confirmUser(fields)
      .then(() =>
        handleSuccess({ description: 'User confirmed' }),
      )
      .catch((error) =>
        handleFailure(error, 'User confirmation request failed!'),
      )
  }

  const resetModalFilters = () => {
    let tempFilterData = {}

    Object.keys(search).forEach(key => {
      if (!~key.indexOf('modal_'))
        tempFilterData[key] = search[key]
    })

    setSearch(tempFilterData)
  }

  return (
    <PageContainer>
      <PageTitleRow title="Users">
        <Can
          name="exportUsersSelenium"
          requiredPermission="user.get.export"
          yes={() => (
            <Button
              data-testid="exportCSVBtnSelenium"
              name="exportCSVBtnSelenium"
              onClick={handleExportUsersCSV}
            >
              Export CSV
            </Button>
          )}
        />
      </PageTitleRow>

      <Row>
        <Col span={24}>
          <CustomTable
            className="usersPageTableSelenium"
            columns={tableColumns}
            data-testid="usersTable"
            dataSource={users}
            defaultOrder={{
              sortBy: undefined,
              sortDir: undefined,
            }}
            loading={isLoading}
            metaData={metaData}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible.confirmation &&
            <UserConfirmationModal
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onConfirm={handleUserConfirmation}
              selectedUser={selectedUser}
              setModalLoading={setModalLoading}
            />
          }

          {isModalVisible.details &&
            <UserDetailsModal
              countries={countries}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onDelete={handleDeactivateUser}
              onSubmit={handleFormSubmit}
              selectedUser={selectedUser}
              setModalLoading={setModalLoading}
            />
          }

          {isModalVisible.transactions && (
            <TransactionHistoryModal
              config={config}
              errorMessage={errorMessage}
              fetchUserList={fetchUserList}
              hideModal={() => {setModalVisibility(false); resetModalFilters()}}
              selectedUser={selectedUser}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

Users.propTypes = {
  config: configPropTypes,
}

export default withConfig(Users)
