import React, { useState } from 'react'
import { border, layout, space } from 'styled-system'
import { isEmpty, truncate } from 'lodash'
import PropTypes from 'prop-types'
import { Col, Row, Segmented } from 'antd'
import styled from 'styled-components'
import assets from '../../../../assets/images'

const height = {
  expanded: '320px',
  initial: '180px',
}

const textLength = {
  expanded: 245,
  initial: 100,
}

const PushNotificationPreview = ({ text, title }) => {
  const [view, setView] = useState('initial')

  return (
    <div style={{ marginLeft: 10 }}>
      <Row>
        <Col span={24}>
          <Segmented
            onChange={(value) => setView(value)}
            options={[
              {
                label: 'Initial state',
                value: 'initial',
              },
              {
                label: 'Expanded view',
                value: 'expanded',
              },
            ]}
          />

          <PreviewBox>
            <PhoneBox px="20px" py="16%" type="androidPreview" view={view}>
              {(!isEmpty(title) || !isEmpty(text)) &&
                <TextReview delay={0} maxHeight={view === 'initial' ? 100 : 200}>
                  <b>{truncate(title, { length: 34 })}</b>
                  <p>{truncate(text, { length: textLength[view] })}</p>
                </TextReview>
              }
            </PhoneBox>

            <PhoneBox px="26px" py="14%" type="iphonePreview" view={view}>
              {(!isEmpty(title) || !isEmpty(text)) &&
                <TextReview borderRadius={5} delay={.5} maxHeight={view === 'initial' ? 100 : 200}>
                  <b>{truncate(title, { length: 34 })}</b>
                  <p>{truncate(text, { length: textLength[view] })}</p>
                </TextReview>
              }
            </PhoneBox>
          </PreviewBox>
        </Col>
      </Row>
    </div>
  )
}

const TextReview = styled.div`
  ${layout}
  ${border}

  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64 ,67, .15);
  background-color: #ffffff;
  margin: 4px;
  padding: 8px;

  overflow: hidden;

  font-size: 12px;

  opacity: 0;
  animation: fadeIn .5s ease-in both;
  animation-delay: ${props => props.delay}s;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate3d(0, -20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`

const PreviewBox = styled.div`
  background-color: rgba(0, 0, 0, 0.04);

  padding: 20px 10px 10px;
`

const PhoneBox = styled.div`
  ${space}

  height: ${props => height[props.view]};
  width: 100%;
  
  background-image: url(${props => assets[props.view][props.type]});
  background-size: contain;
  background-repeat: no-repeat;

  margin-bottom: 20px;
`

PushNotificationPreview.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
}

export default PushNotificationPreview
