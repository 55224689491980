import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Form, Input, Select, Space, Typography } from 'antd'
import { CustomSelect } from '../../../components'
import { countryCodes, translations, usStates } from '../../../constants'

const { Option } = Select
const { Title } = Typography

const UsForm = ({
  hideModal,
  isFormNeedPaymentMethods = true,
  locale = 'us',
  onSubmit,
  selectedStore,
}) => {
  const initialValues = { accountType: 'CHECKING', store_id: selectedStore.id }
  const [form] = Form.useForm()
  const [isModalLoading, setModalLoading] = useState(false)
  const selectedCountryCode = Form.useWatch('country', form)

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      setModalLoading(true)

      form
        .validateFields()
        .then(() => {
          const fields = {
            ...form.getFieldsValue(['store_id', 'abartn', 'accountNumber', 'accountType']),
            address: {
              state: null,
              ...form.getFieldsValue([
                'country',
                'city',
                'postCode',
                'firstLine',
              ]),
            },
            store_id: selectedStore.id,
          }

          if (fields.address.country === 'US') {
            fields.address = {
              ...fields.address,
              ...form.getFieldsValue(['state']),
            }
          }

          onSubmit({
            country:'us',
            requiredFieldsValue: fields,
            setModalLoading,
          })
        })
    }
  }

  return (
    <Form
      data-test="bank-details-form"
      data-testid="usForm"
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item
        className="bankDetailsAbartn"
        label={translations[locale].abartn}
        name="abartn"
        rules={[
          {
            message: 'Please make sure your ACH routing number is correct!',
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="bankDetailsAccountNumber"
        label={translations[locale].accountNumber}
        name="accountNumber"
        rules={[
          {
            message: 'Please enter your account number!',
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="bankDetailsAccountType"
        label={translations[locale].accountType}
        name="accountType"
        rules={[
          {
            message: 'Please provide an account type!',
            required: true,
          },
        ]}
      >
        <Select>
          <Option value="CHECKING">CHECKING</Option>,
          <Option value="SAVINGS">SAVINGS</Option>,
        </Select>
      </Form.Item>

      <Divider />

      <Title level={5}>{translations[locale].address}</Title>

      <Form.Item
        className="bankDetailsCountry"
        initialValue="US"
        label={translations[locale].country}
        name="country"
        rules={[
          {
            message: 'Please provide a country!',
            required: true,
          },
        ]}
      >
        <CustomSelect entries={countryCodes} />
      </Form.Item>

      {selectedCountryCode === 'US' &&
        <Form.Item
          className="bankDetailsState"
          label={translations[locale].state}
          name="state"
        >
          <CustomSelect entries={usStates} />
        </Form.Item>
      }

      <Form.Item
        className="bankDetailsCity"
        label={translations[locale].city}
        name="city"
        rules={[
          {
            message: 'Please provide a city!',
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="bankDetailsPostCode"
        label={translations[locale].postCode}
        name="postCode"
        rules={[
          {
            message: 'Please provide a post code!',
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="bankDetailsAddress"
        label={translations[locale].address}
        name="firstLine"
        rules={[
          {
            message: 'Please provide address!',
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider />

      <Space
        size="middle"
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button className="bankDetailsSubmit" htmlType="submit" loading={isModalLoading} name="submitBtnSelenium" type="primary">{translations[locale].submit}</Button>
        {isFormNeedPaymentMethods &&<Button className="bankDetailsCancel" onClick={hideModal}>Cancel</Button>}
      </Space>
    </Form>
  )
}

UsForm.propTypes = {
  handleFailure: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
  isFormNeedPaymentMethods: PropTypes.bool,
  locale: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
}

export default UsForm
