import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { ModifiedModalFooter } from '../../elements'
import { Alert, Button, Checkbox, Form, notification, Input, InputNumber, Modal, Select, Space, Spin } from 'antd'
import { handleBackendError } from '../../utils'
import { Can, ModalDeleteButton } from '../../components'
import { withConfig } from '../../modules'
import { configPropTypes } from '../../types'

import { getStoreList } from '../stores/api'

const { TextArea } = Input
const { Option } = Select

export const UserDetailsModal = ({
  config,
  errorMessage,
  hideModal,
  isModalLoading,
  onDelete,
  onSubmit,
  selectedUser,
}) => {
  const [isCorporateUser, setIsCorporateUser] = useState(selectedUser?.user_type === 'corporate')
  const [form] = Form.useForm()
  const isUpdate = !!selectedUser
  const isUserTypeSelectDisabled = !selectedUser.is_editable

  const [alreadySearched, setAlreadySearched] = useState(false)
  const [filteredStoreList, setFilteredStores] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    if (selectedUser) {
      setFilteredStores([{ label: selectedUser.store_name, value: selectedUser.store_id }])
    }
  }, [])

  const onStoreSearch = (searchValue) => {
    if (searchValue.length > 2) {
      const searchParam = `name_cont=${searchValue}&country_id_eq=${selectedUser.country_id}`

      setFetching(true)
      if (!alreadySearched) setAlreadySearched(true)

      getStoreList(searchParam)
        .then(({ data }) => {
          const result = data.data.map(({ name, id }) => ({
            label: name,
            value: id,
          }))

          setFilteredStores(result)
          setFetching(false)
        })
        .catch((error) => {
          notification.error({
            description: handleBackendError(error, 'Fetching store list failed!'),
            message: 'Error',
          })

          setFetching(false)
        })
    }
  }

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()
    isFormUpdated
      ? form
        .validateFields()
        .then(() => {
          const requiredFieldsValue = form.getFieldsValue([
            'email',
            'market_research_consent',
            'marketing_consent',
            'marketing_notification_consent',
            'name',
            'user_type',
            'zip_code',
          ])

          const fieldsValue = {
            ...requiredFieldsValue,
            ...{ store_id: isCorporateUser ? form.getFieldValue('store_id') : null },
            ...{ id: selectedUser.id },
          }

          onSubmit(fieldsValue)
        })
      : hideModal()

  }

  const handleUserChange = (value) => {
    setIsCorporateUser(value === 'corporate')
  }

  const initialValues = {
    balance: selectedUser.balance,
    confirmation_comment: selectedUser.confirmation_comment ?? '',
    country: selectedUser.country_name,
    email: selectedUser.email,
    market_research_consent: selectedUser.market_research_consent,
    marketing_consent: selectedUser.marketing_consent,
    marketing_notification_consent: selectedUser.marketing_notification_consent,
    name: selectedUser.name,
    status: selectedUser.status,
    store_id: selectedUser.store_id || null,
    user_type: selectedUser.user_type,
    zip_code: selectedUser.zip_code,
  }

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <ModalDeleteButton
            confirmationText="Are you sure you want to deactivate this user?"
            isUpdate={isUpdate && selectedUser?.status !== 'inactive'}
            key="delete"
            name="deactivateUserModalBtnSelenium"
            onDelete={() => onDelete(selectedUser)}
            requiredPermission="user.put.user_id_deactivate"
          />
          <Space>
            <Button data-testid="hideBtn" key="back" name="cancelUserModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              key="update"
              requiredPermission="user.put.user_id"
              yes={() => (
                <Button data-testid="updateUserModalBtn" key="submit" loading={isModalLoading} name="updateUserModalBtnSelenium" onClick={handleSubmit} type="primary">
                  Update
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title="Update user"
    >
      <Form data-test="user-details-form" form={form} initialValues={initialValues} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              message: 'Please provide an email!',
              required: true,
            },
            {
              message: 'The input is not valid email!',
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Name" name="name" rules={[{ message: 'Please provide a name!', required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select disabled>
            <Option name="statusOption" value={selectedUser.status}>{selectedUser.status}</Option>,
          </Select>
        </Form.Item>

        <Form.Item label="Confirmation comment" name="confirmation_comment">
          <TextArea disabled />
        </Form.Item>

        <Form.Item label="Type" name="user_type" rules={[{ message: 'Please select a user type!', required: true }]}>
          <Select disabled={isUserTypeSelectDisabled} onChange={handleUserChange}>
            {config.config?.user_types
              .filter(type => type !== 'virtual')
              .sort()
              .map((type) =>
                <Option key={type} name="userTypeOption" value={type}>{type}</Option>,
              )}
          </Select>
        </Form.Item>

        {isCorporateUser &&
          <Form.Item label="Store name" name="store_id" rules={[{ message: 'Please select a store!', required: true }]}>
            <Select
              allowClear={true}
              filterOption={false}
              notFoundContent={fetching ? <Spin size="small" /> : (alreadySearched ? 'No store was found' : null)}
              onSearch={debounce(onStoreSearch, 1000)}
              options={filteredStoreList}
              showSearch
            />
          </Form.Item>
        }

        <Form.Item label="Marketing emails consent" name="marketing_consent" valuePropName="checked">
          <Checkbox value={true}>
            Accepted
          </Checkbox>
        </Form.Item>

        <Form.Item label="Market research consent" name="market_research_consent" valuePropName="checked">
          <Checkbox value={true}>
            Accepted
          </Checkbox>
        </Form.Item>

        <Form.Item label="Marketing push notification consent" name="marketing_notification_consent" valuePropName="checked">
          <Checkbox value={true}>
            Accepted
          </Checkbox>
        </Form.Item>

        <Form.Item label="Country" name="country">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Zip code" name="zip_code" rules={[{ message: 'Zip code must be minimum 5 characters.', min: 5 }, { message: 'Please provide a zip code!', required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Balance" name="balance">
          <InputNumber disabled style={{ width: '100%' }} type="number" />
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

UserDetailsModal.propTypes = {
  config: configPropTypes,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
}

export default withConfig(UserDetailsModal)
