import React, { useEffect, useState } from 'react'
import { Col, notification, Row, Tooltip, Switch } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import {
  Can,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  PageContainer,
  PageTitleRow,
} from '../../components'
import { handleBackendError, useCustomSearchParams } from '../../utils'
import CountryDetailsModal from './CountryDetailsModal'

import { getCountryList, updateCountry } from './api'

const CountryManagement = () => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [metaData, setMetaData] = useState()

  const [search, setSearch] = useCustomSearchParams()

  useEffect(() => {
    if (isEmpty(search)) {
      setSearch({ items_per_page: 25, page: 1 })
    }
  }, [search, setSearch])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchCountryList(searchParams.toString())
  }, [searchParams])

  const fetchCountryList = (params) => {
    setIsLoading(true)

    getCountryList(params)
      .then(({ data }) => {
        setCountries(data.data)
        setMetaData(data.meta)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)

        handleFailure(error, 'Fetching countries failed!')
      })
  }

  const tableColumns = [
    {
      className: 'countryNameSelenium',
      dataIndex: 'code',
      key: 'name',
      title: 'Country name',
    },
    {
      className: 'numberOfReturnedPackagesSelenium',
      dataIndex: 'number_of_returned_items',
      key: 'number_of_returned_items',
      title: 'Number of returned packages',
    },
    {
      className: 'privacyPolicyUrlSelenium',
      dataIndex: 'privacy_policy_url',
      key: 'privacyPolicyUrl',
      render: (_, record) => (
        <a href={record.privacy_policy_url} rel="noopener noreferrer">
          {record.privacy_policy_url}
        </a>
      ),
      title: 'Link to terms and conditions',
    },
    {
      className: 'transactionalEmailsSelenium',
      key: 'transaction_email',
      render: (_, record) => (
        <Can
          requiredPermission="country.put.country_id"
          yes={() => (
            <Switch checked={record.transaction_email} onChange={(value) => handleFormSubmit({ id: record.id, transaction_email: value })} />
          )}
        />
      ),
      title: 'Transactional emails',
    },
    {
      className: 'countryActionSelenium',
      key: 'action',
      render: (_, record) => (
        <Can
          no={() => (
            <IconContainer>
              <Tooltip title="View">
                <Icon
                  data-testid="viewBtn"
                  name="updateCountrySelenium"
                  onClick={() => handleOpenModal(record)}
                  type="eye"
                />
              </Tooltip>
            </IconContainer>
          )}
          requiredPermission="country.put.country_id"
          yes={() => (
            <IconContainer>
              <Tooltip title="Edit">
                <Icon
                  data-testid="editBtn"
                  name="updateCountrySelenium"
                  onClick={() => handleOpenModal(record)}
                  type="edit"
                />
              </Tooltip>
            </IconContainer>
          )}
        />
      ),
      title: 'Actions',
    },
  ]

  const handleFormSubmit = (formData) => {
    updateCountry(formData)
      .then(() =>
        handleSuccess({ description: 'Country updated' }),
      )
      .catch((error) =>
        handleFailure(error, { description: 'Country update failed' }),
      )
  }

  const handleOpenModal = (selectedCountry) => {
    setSelectedCountry(selectedCountry)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchCountryList(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)
    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  return (
    <PageContainer>
      <PageTitleRow title="Countries" />
      <Row>
        <Col span={24}>
          <CustomTable
            className="countryManagementPageTableSelenium"
            columns={tableColumns}
            data-testid="countries"
            dataSource={countries}
            loading={isLoading}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible && (
            <CountryDetailsModal
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              selectedCountry={selectedCountry}
              setModalLoading={setModalLoading}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

export default CountryManagement
