import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ModifiedModalFooter } from '../../elements'
import { Alert, Button, Divider, DatePicker, Modal, notification, Popconfirm, Space, Typography } from 'antd'
import { handleBackendError } from '../../utils'

import { getBalanceForRange } from './api'

const { RangePicker } = DatePicker
const { Title } = Typography
const defaultRangeDate = {
  begin_date: null,
  end_date: null,
}

const ModifiedRangePicker = styled(RangePicker)`
  margin-bottom: 15px;
`

const BalanceModal = ({
  errorMessage,
  hideModal,
  isAdjustment = true,
  isLoading = false,
  onSubmit,
  selectedStore,
}) => {
  const [dateRange, setDateRange] = useState(defaultRangeDate)
  const [dateError, setDateError] = useState(null)
  const [isModalLoading, setModalLoading] = useState()
  const [rangeAmount, setRangeAmount] = useState()

  useEffect(() => {
    if (dateRange.begin_date && dateRange.end_date) {
      setModalLoading(true)
      getBalanceForRange(selectedStore.id, dateRange)
        .then(({ data }) => {
          setRangeAmount(data)
          setModalLoading(false)
        })
        .catch((error) => {
          setModalLoading(false)

          notification.error({
            description: handleBackendError(error, 'Amount calculation failed!'),
            message: 'Error',
          })
        })
    }}, [dateRange, selectedStore])

  const onChange = (_, date) => {
    setDateRange({
      begin_date: date[0],
      end_date: date[1],
    })
  }

  const handleClick = () => {
    setDateError(null)

    if (!dateRange.end_date || !dateRange.begin_date) {
      setDateError('Missing start or end date!')

      return false
    }

    const actionType = isAdjustment ? 'ADJUST' : 'PAYOUT'
    const finalData = {
      begin_date: dateRange.begin_date,
      end_date: dateRange.end_date,
      store_id: selectedStore.id,
    }

    onSubmit(actionType, finalData)
  }

  return (
    <Modal
      data-testid={isAdjustment ? 'adjustBalanceModal' : 'payoutModalTitle'}
      footer={[
        <ModifiedModalFooter key="padding">
          <Space>
            <Button key="back" name="cancelStoreModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Popconfirm
              cancelText="Cancel"
              okText="Confirm"
              onConfirm={handleClick}
              title={`Are you sure you want to confirm ${isAdjustment ? 'balance adjustment?' : 'payout?'}`}
            >
              <Button
                data-testid={isAdjustment ? 'adjustBalanceModalBtn' : 'payoutModalBtn'}
                key="submit"
                loading={isModalLoading || isLoading}
                name={isAdjustment ? 'adjustBalanceSelenium' : 'payOutSelenium'}
                type="primary"
              >
                {isAdjustment ? 'Adjust Balance' : 'Pay out'}
              </Button>
            </Popconfirm>
          </Space>
        </ModifiedModalFooter>,

      ]}
      onCancel={hideModal}
      open
      title={isAdjustment ? 'Balance Adjustment' : 'Pay out'}
    >
      <p>Select period!</p>

      <div>
        <ModifiedRangePicker
          onChange={onChange}
          size="medium"
        />
      </div>

      <Divider orientation="left" orientationMargin={0} plain>
        Balance for the selected period
      </Divider>

      <Title level={4}>
        {rangeAmount ? new Intl.NumberFormat(selectedStore.country_locales[0], { currency: selectedStore.currency, style: 'currency' }).format(rangeAmount) : '-'}
      </Title>

      {(errorMessage || dateError) && (
        <Alert
          description={errorMessage || dateError}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

BalanceModal.propTypes = {
  errorMessage: PropTypes.node,
  hideModal: PropTypes.func.isRequired,
  isAdjustment: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
}

export default BalanceModal
