import React from 'react'
import styled from 'styled-components'
import { Result } from 'antd'
import PropTypes from 'prop-types'
import { translations } from '../../../constants'

const StoreCreateErrorPage = ({ language }) => (
  <CustomResult
    status="404"
    subTitle={translations[language].errorPageSubtitle}
    title={translations[language].errorPageTitle}
  />
)

const CustomResult = styled(Result)`
  white-space: pre; 
`

StoreCreateErrorPage.propTypes = {
  language: PropTypes.string,
}

export default StoreCreateErrorPage
