import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ModifiedModalFooter } from '../../elements'
import { Alert, Button, Form, Input, Modal, Space } from 'antd'

const FieldGroup = styled.div`
  margin: 20px 0;
  `

const Label = styled.div`
  margin: 3px 0;
  `

const FieldValue = styled.div`
  margin: 3px 0 15px 0;
  
  font-weight: bold;
`

const { TextArea } = Input

const UserConfirmationModal = ({
  errorMessage,
  hideModal,
  isModalLoading,
  onConfirm,
  selectedUser,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const [isConfirmDisabled, setConfirmDisabled] = useState(true)

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    isFormUpdated
      ? form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const confirmForm = {
            ...form.getFieldsValue(),
            id: selectedUser.id,
          }

          onConfirm(confirmForm)
        })
      : hideModal()
  }

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <Space>
            <Button key="back" name="cancelConfirmModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              data-testid="confirmModalBtn"
              disabled={isConfirmDisabled}
              key="submit"
              loading={isModalLoading}
              name="confirmUserModalBtnSelenium"
              onClick={handleSubmit}
              type="primary"
            >
              Confirm
            </Button>
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title="Confirm user"
    >
      <FieldGroup key="confirmInfo" name="confirmInfo">
        <Label>Name</Label>
        <FieldValue>{selectedUser.name}</FieldValue>
        <Label>Email</Label>
        <FieldValue>{selectedUser.email}</FieldValue>
      </FieldGroup>

      <Form
        data-test="user-confirmation-form"
        form={form}
        layout="vertical"
        onFieldsChange={() => setConfirmDisabled(false)}
      >
        <Form.Item
          label="Comment"
          name="comment"
          rules={[
            {
              message: 'Please provide a comment!',
              required: true,
            },
          ]}
        >
          <TextArea />
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

UserConfirmationModal.propTypes = {
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  setModalLoading: PropTypes.func.isRequired,
}

export default UserConfirmationModal
