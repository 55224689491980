import instance from '../../api'
import apiExportResponseHandler from '../../api-utils/apiExportResponseHandler'

const BASE_ENDPOINT = '/api/admin/bins'

export const createBin = (fields) => instance.post(BASE_ENDPOINT, fields)

export const deleteBin = (id) => instance.delete(`${BASE_ENDPOINT}/${id}`)

export const getBinList = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const updateBin = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.id}`, fields)

export const exportBins = (filter) => (
  fetch(`${BASE_ENDPOINT}/export${filter ? `?${filter}` : ''}`,
    {
      headers: {
        'content-type': 'text/csv;charset=UTF-8',
      },
    })
    .then(apiExportResponseHandler)
)
