import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { compact, has, isEmpty, orderBy } from 'lodash'
import { Button, Col, notification, Popconfirm, Row, Space, Tag, Tooltip } from 'antd'
import {
  BankDetailsModal,
  Can,
  Copyable,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  InfoModal,
  PageContainer,
  PageTitleRow,
} from '../../components'
import { createDownloadLink, handleBackendError, getTableHeaderCheckboxFilter, getTableHeaderSearchInput } from '../../utils'
import { withConfig } from '../../modules'
import { getCountryList } from '../country-management/api'
import StoreDetailsModal from './StoreDetailsModal'
import BalanceModal from './BalanceModal'
import BatchPayoutModal from './BatchPayoutModal'
import { configPropTypes } from '../../types'

import {
  adjustBalanceBetween,
  createStore,
  getStoreList,
  updateStore,
  exportStores,
  getBankData,
  switchVirtualStore,
} from './api'
import { payoutBalance } from '../withdraw-history/api'

const statusFilter = [
  { text: 'active', value: 'true' },
  { text: 'inactive', value: 'false' },
]

const virtualFilter = [
  { text: 'virtual', value: 'true' },
  { text: 'regular', value: 'false' },
]

export const Stores = ({ config }) => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState({})
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [countryFilters, setCountryFilters] = useState([])
  const [metaData, setMetaData] = useState()

  useEffect(() => {
    getCountryList()
      .then(({ data }) => setCountryFilters(() => orderBy(data.data.map(({ code, name, id }) => ({ code, text: name, value: id })), 'text', 'asc')))
      .catch((error) => handleFailure(error, 'Fetching the countries failed!'))
  }, [])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchStoreList(searchParams.toString())
  }, [searchParams])

  const fetchStoreList = (params) => {
    setIsLoading(true)

    getStoreList(params)
      .then(({ data }) => {
        setMetaData(data.meta)
        setStores(data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)

        handleFailure(error, 'Fetching store list failed!')
      })
  }

  const handleVirtualStore = (selectedStore) => {
    switchVirtualStore(selectedStore.id)
      .then(() =>
        handleSuccess({ description: 'Switched to virtual store!' }),
      )
      .catch((error) =>
        handleFailure(error, 'Change the store to virtual store is failed!' ),
      )
  }

  const userCanCheckBankData = has(config.config.user_permissions, 'store.get.store_id_bank_data')

  const tableColumns = compact([
    {
      className: 'storeIdSelenium',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => (
        <div style={{ maxWidth: 150 }}>
          <Copyable text={record.id} />
        </div>
      ),
      title: 'ID',
      width: 60,
    },
    {
      className: 'storeNameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.getAll('name_cont'),
      key: 'name',
      title: 'Name',
      ...getTableHeaderSearchInput('storeNameSelenium', 'name_cont'),
    },
    {
      className: 'storeCountrySelenium',
      dataIndex: 'country_name',
      filteredValue: searchParams.getAll('country_id_eq'),
      key: 'country_name',
      title: 'Country',
      ...getTableHeaderCheckboxFilter('storeCountrySelenium', countryFilters, 'country_id_eq', 'single'),
    },
    {
      children: [
        {
          className: 'storeStateSelenium',
          dataIndex: 'state',
          key: 'state',
          title: 'State',
        },
        {
          className: 'storeCitySelenium',
          dataIndex: 'city',
          key: 'city',
          title: 'City',
        },
        {
          className: 'storeZipCodeSelenium',
          dataIndex: 'zip_code',
          key: 'zip_code',
          title: 'Zip',
        },
        {
          className: 'storeAddressLineSelenium',
          dataIndex: 'address_line',
          key: 'address_line',
          title: 'Address Line',
        },
      ],
      className: 'storeAddressSelenium',
      dataIndex: 'address',
      filteredValue: searchParams.getAll('state_or_zip_code_or_city_or_address_line_cont'),
      key: 'address',
      title: 'Address',
      ...getTableHeaderSearchInput('storeAddressSelenium', 'state_or_zip_code_or_city_or_address_line_cont'),
    },
    {
      className: 'storeStatusSelenium',
      dataIndex: 'active',
      filteredValue: searchParams.getAll('active_eq'),
      key: 'active',
      render: (_, record) => (
        <span>{record.active ? 'Active' : 'Inactive'}</span>
      ),
      title: 'Status',
      ...getTableHeaderCheckboxFilter('storeStatusSelenium', statusFilter, 'active_eq', 'single'),
    },
    {
      className: 'storeLegalEntitySelinium',
      dataIndex: 'legal_entity',
      filteredValue: searchParams.getAll('legal_entity_cont'),
      key: 'legal_entity',
      title: 'Legal Entity',
      ...getTableHeaderSearchInput('legalEntitySelenium', 'legal_entity_cont'),
    },
    {
      className: 'storeReportingEmailSelinium',
      dataIndex: 'reporting_email',
      filteredValue: searchParams.getAll('reporting_email_cont'),
      key: 'reporting_email',
      title: 'Reporting email',
      ...getTableHeaderSearchInput('reportingEmailSelenium', 'reporting_email_cont'),
    },
    (userCanCheckBankData)
      ? {
        className: 'toSelenium',
        key: 'to',
        render: (_, record) => (
          <IconContainer>
            <Tooltip title={record.wise_id ? null : 'Please add Bank data first!'}>
              <Button data-testid="bankDataBtn" disabled={!record.wise_id} name="bankInfoSelenium" onClick={() => handleOpenModal('bankInfo', record)} type="primary">BANK DATA</Button>
            </Tooltip>
          </IconContainer>
        ),
        title: 'To',
      }
      : null,
    {
      className: 'storeWiseIdSelenium',
      dataIndex: 'wise_id',
      filteredValue: searchParams.getAll('payment_methods_account_number_eq'),
      key: 'wise_id',
      title: 'Wise ID',
      ...getTableHeaderSearchInput('storeWiseIdSelenium', 'payment_methods_account_number_eq'),
    },
    {
      className: 'storeBalanceSelenium',
      dataIndex: 'balance',
      key: 'balance',
      render: (_, record) => (
        <div>{new Intl.NumberFormat(record.country_locales[0], { currency: record.currency, style: 'currency' }).format(record.balance)}</div>
      ),
      title: 'Balance',
    },
    {
      className: 'storeVirtualBoolSelenium',
      dataIndex: 'virtual',
      filteredValue: searchParams.getAll('virtual_eq'),
      key: 'virtual',
      render: (_, record) => (
        <Tag color={record.virtual ? 'green' : 'blue'}>{record.virtual ? 'virtual' : 'regular'}</Tag>
      ),
      title: 'Store type',
      ...getTableHeaderCheckboxFilter('storeVirtualBoolSelenium', virtualFilter, 'virtual_eq', 'single'),
    },
    {
      className: 'storeActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          <Can
            no={() => (
              <Tooltip title="View">
                <Icon
                  data-testid="viewBtn"
                  name="updateStoreSelenium"
                  onClick={() => handleOpenModal('details', record)}
                  type="eye"
                />
              </Tooltip>
            )}
            requiredPermission="store.put.store_id"
            yes={() => (
              <Tooltip title="Edit">
                <Icon
                  data-testid="editBtn"
                  name="updateStoreSelenium"
                  onClick={() => handleOpenModal('details', record)}
                  type="edit"
                />
              </Tooltip>
            )}
          />

          <Can
            requiredPermission="payment_method.post"
            yes={() => (
              <Tooltip title="Add bank details">
                <Icon
                  data-testid="addBankDetails"
                  name="addBankDetailsIconSelenium"
                  onClick={() => handleOpenModal('bank', record)}
                  type="bank"
                />
              </Tooltip>
            )}
          />

          <Can
            requiredPermission="store.post.store_id_adjust_balance_between"
            yes={() => (
              <Tooltip title="Adjust balance">
                <Icon
                  data-testid="addjustBalanceBtn"
                  name="adjustBalanceSelenium"
                  onClick={() => handleOpenModal('adjust', record)}
                  type="adjust"
                />
              </Tooltip>
            )}
          />

          <Can
            requiredPermission="withdrawal.post.payout_balance"
            yes={() => (
              <Tooltip title={record.wise_id ? 'Pay out' : 'Please add Bank data first!'}>
                <Icon
                  data-testid="payoutBtn"
                  disabled={!record.wise_id}
                  name="payOutSelenium"
                  onClick={() => handleOpenModal('payOut', record)}
                  type="payOut"
                />
              </Tooltip>
            )}
          />

          <Tooltip title="Show users">
            <Link to={`/users?items_per_page=50&page=1&sort_by=created_at+desc&store_name=${record.name}`}>
              <Icon
                name="showUsersSelenium"
                type="user"
              />
            </Link>
          </Tooltip>

          <Can
            requiredPermission="store.put.store_id"
            yes={() => (
              <Tooltip title="Switch to virtual store">
                <Popconfirm
                  cancelText="No"
                  className="action-list-button"
                  okText="Yes"
                  onConfirm={() => handleVirtualStore(record)}
                  title="Are you sure you want to make this store virtual?"
                >
                  <Icon
                    data-testid="virtualStoreBtn"
                    disabled={record.virtual}
                    name="virtualStoreSelenium"
                    type="virtual"
                  />
                </Popconfirm>
              </Tooltip>
            )}
          />
        </IconContainer>
      ),
      title: 'Actions',
    },
  ])

  const handleExportStoresCSV = () => {
    exportStores(searchParams.toString())
      .then(({ blob, fileName }) => {
        createDownloadLink(blob, fileName)

        notification.success({
          description: 'Stores exported',
          duration: 3,
          message: 'Success',
        })
      })
      .catch((error) => {
        handleFailure(error, 'Stores export failed!')
      })
  }

  const handleFormSubmit = (actionType, formData) => {
    setModalLoading(true)

    switch (actionType) {
    case 'UPDATE':
      updateStore(formData)
        .then(() =>
          handleSuccess({ description: 'Store updated' }),
        )
        .catch((error) =>
          handleFailure(error, 'Store update failed!'),
        )
      break

    case 'CREATE':
      createStore(formData)
        .then(() =>
          handleSuccess({ description: 'Store created' }),
        )
        .catch((error) =>
          handleFailure(error, 'Store creation failed!' ),
        )
      break

    case 'ADJUST':
      adjustBalanceBetween(formData.store_id, formData)
        .then(() => {
          handleSuccess({ description: 'Balance adjusted' })
          setModalVisibility({
            adjust: false,
          })
        })
        .catch((error) =>
          handleFailure(error, 'Adjustment failed!'),

        )
      break

    case 'PAYOUT':
      payoutBalance(formData)
        .then(() => {
          handleSuccess({ description: 'Pay out complete' })
          setModalVisibility({
            payout: false,
          })
        })
        .catch((error) =>
          handleFailure(error, 'Pay out failed!'),
        )
      break

    default:
      setModalLoading(false)
      notification.error({
        description: 'No submission available',
        message: 'Error',
      })
      break
    }
  }

  const handleOpenModal = (modalName, selectedStore) => {
    setSelectedStore(selectedStore)
    setErrorMessage(null)
    setModalVisibility({ [modalName]: true })

    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchStoreList(searchParams.toString())
    setErrorMessage(null)
    setModalLoading(false)
    setModalVisibility({})

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error.response)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  return (
    <PageContainer>
      <PageTitleRow title="Stores">
        <Space>
          <Can
            requiredPermission="store.get.export"
            yes={() => (
              <Button
                data-testid="exportBtn"
                name="exportStoreSelenium"
                onClick={handleExportStoresCSV}
              >
                Export CSV
              </Button>
            )}
          />
          <Can
            requiredPermission="withdrawal.post.payout_balance"
            yes={() => (
              <Button
                data-testid="batchPayout"
                name="batchStorePayoutSelenium"
                onClick={() => handleOpenModal('batchPayout')}
                type="primary"
              >
                BATCH STORE PAYOUT
              </Button>
            )}
          />
          <Can
            requiredPermission="store.post.create"
            yes={() => (
              <Button
                data-testid="addNewBtn"
                name="createStoreSelenium"
                onClick={() => handleOpenModal('details')}
                type="primary"
              >
                NEW STORE
              </Button>
            )}
          />
        </Space>
      </PageTitleRow>

      <Row>
        <Col span={24}>
          <CustomTable
            className="storePageTableSelenium"
            columns={tableColumns}
            dataSource={stores}
            defaultOrder={{
              sortBy: undefined,
              sortDir: undefined,
            }}
            loading={isLoading}
            metaData={metaData}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible.details && (
            <StoreDetailsModal
              countries={countryFilters}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility({})}
              isModalLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              selectedStore={selectedStore}
              setModalLoading={setModalLoading}
              tdsStores={config.config.stores}
            />
          )}

          {isModalVisible.bank && (
            <BankDetailsModal
              errorMessage={errorMessage}
              handleFailure={handleFailure}
              handleSuccess={handleSuccess}
              hideModal={() => setModalVisibility({})}
              selectedStore={selectedStore}
            />
          )}

          {(isModalVisible.payOut || isModalVisible.adjust ) && (
            <BalanceModal
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility({})}
              isAdjustment={!!isModalVisible.adjust}
              isLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              selectedStore={selectedStore}
            />
          )}

          {isModalVisible.bankInfo && (
            <InfoModal
              apiRequest={getBankData}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility({})}
              selectedId={selectedStore.id}
              title="Bank data details info"
            />
          )}

          {isModalVisible.batchPayout &&
            <BatchPayoutModal
              countries={countryFilters}
              hideModal={() => setModalVisibility({})}
            />
          }
        </Col>
      </Row>
    </PageContainer>
  )
}

Stores.propTypes = {
  config: configPropTypes,
}

export default withConfig(Stores)
