import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { find, findIndex, isEmpty } from 'lodash'
import { ModifiedModalFooter } from '../../elements'
import { Can } from '../../components'
import {
  Alert,
  Button,
  Col,
  Collapse,
  Descriptions,
  Form,
  Modal,
  Popconfirm,
  Row,
  Space,
  Steps,
} from 'antd'
import PushNotificationPreview from './PushNotificationPreview'
import { DeleteOutlined } from '@ant-design/icons'

import {
  NotificationStep,
  SchedulingStep,
  TargetStep,
} from './steps/'

import { getCountryList } from '../country-management/api'
import { getTargetItems, getUserCount } from './api'

import './notification.css'

dayjs.extend(utc)
dayjs.extend(timezone)

const notificationTimeZone = {
  BST: 'Europe/London',
  CEST: 'Europe/Paris',
  EDT: 'America/New_York',
  JST: 'Asia/Tokyo',
}

export const DeleteButton = ({ onDelete }) => (
  <Can
    key="delete"
    requiredPermission="notification.delete.notification_id"
    yes={() => (
      <Popconfirm
        cancelText="No"
        className="action-list-button"
        okText="Yes"
        onConfirm={onDelete}
        title="Are you sure you want to delete this notification?"
      >
        <Button
          danger
          icon={<DeleteOutlined />}
          name="deleteNotificationModalSelenium"
          style={{ float: 'left' }}
        >
          Delete
        </Button>
      </Popconfirm>
    )}
  />
)

DeleteButton.propTypes = {
  isModalAnUpdate: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
}

export const NotificationModal = ({
  errorMessage,
  hideModal,
  isDuplicating = false,
  onDelete,
  onSubmit,
  resetBackendErrorMessage,
  selectedNotification,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState({
    countryList: false,
    potentialUsers: false,
    targetItemList: false,
  })
  const [isFormLoading, setFormLoading] = useState(false)
  const [hasTimeError, setHasTimeError] = useState(false)
  const [potentialUsers, setPotentialUsers] = useState({
    count_of_notified_users: 0,
    percentage_in_selected_country: 0,
  })
  const [countries, setCountries] = useState([])
  const [targetItemList, setTargetItemList] = useState([])
  const [scheduling, setScheduling] = useState('now')
  const [activePanel, setActivePanel ] = useState(1)

  const isModalAnUpdate = !!selectedNotification

  useEffect(() => {
    fetchCountryList()

    if (selectedNotification?.send_at && !isDuplicating) {
      setScheduling(dayjs.utc(selectedNotification.send_at))
    }
  }, [])

  const getPotentialUsers = () => {
    const {
      country_id,
      target_skus,
      user_type,
      zip_codes,
    } = form.getFieldsValue(['country_id', 'target_skus', 'user_type', 'zip_codes'])

    setIsLoading(prevState => ({
      ...prevState,
      potentialUsers: true,
    }))

    getUserCount({ country_id, target_skus, user_type, zip_codes })
      .then(({ data }) => {
        setPotentialUsers(data)

        setIsLoading(prevState => ({
          ...prevState,
          potentialUsers: false,
        }))
      })
      .catch((error) => {
        setIsLoading(prevState => ({
          ...prevState,
          potentialUsers: false,
        }))

        console.error(error, 'Fetching potential user number is failed!')
      })
  }

  const onCountryChange = (countryId) => {
    form.setFieldsValue({
      target_skus: [],
      zip_codes: [],
    })

    getPotentialUsers()
    fetchTargetItems(countryId)
  }

  const fetchCountryList = async (params) => {
    setIsLoading(prevState => ({
      ...prevState,
      countryList: true,
    }))

    await getCountryList(params)
      .then(({ data }) => {
        setCountries(data.data)

        if (isModalAnUpdate) {
          fetchTargetItems(selectedNotification?.country.id)
        } else {
          form.setFieldsValue({ country_id: data.data[0].id })
          fetchTargetItems(data.data[0].id)
        }

        setIsLoading(prevState => ({
          ...prevState,
          countryList: false,
        }))

        getPotentialUsers()
      })
      .catch((error) => {
        setIsLoading(prevState => ({
          ...prevState,
          countryList: false,
        }))

        console.error(error, 'Fetching countries failed!')
      })
  }

  const fetchTargetItems = (countryId) => {
    setIsLoading(prevState => ({
      ...prevState,
      targetItemList: true,
    }))

    getTargetItems(countryId)
      .then(({ data }) => {
        setTargetItemList(data)

        setIsLoading(prevState => ({
          ...prevState,
          targetItemList: false,
        }))
      })
      .catch((error) => {
        setIsLoading(prevState => ({
          ...prevState,
          targetItemList: false,
        }))

        console.error(error, 'Fetching target items failed!')
      })
  }

  const showPreview = () => {
    form
      .validateFields()
      .then(() => {
        setHasTimeError(false)
        resetBackendErrorMessage()

        const { schedule_type, schedule_hour_min } = form.getFieldsValue(['schedule_type', 'schedule_hour_min'])

        if (schedule_type[1]) {
          const [day, month, year] = schedule_type[1].split('.')
          const time = schedule_hour_min ? schedule_hour_min.format('HH:mm') : '00:00'
          const selectedTime = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${time}`

          if (dayjs().isAfter(dayjs.tz(selectedTime, notificationTimeZone[selectedCountryTimeZone]))) {
            setHasTimeError(true)
            return
          }
        }

        setActivePanel(2)
      })
  }

  const handleSubmit = (_, isDraft = false) => {
    const isFormUpdated = form.isFieldsTouched()
    const isDraftUpdating = selectedNotification?.state === 'draft'

    if (!isDraftUpdating && !isFormUpdated && !isDuplicating) {
      hideModal()

      return false
    }

    setFormLoading(true)

    const {
      name,
      title,
      body,
      country_id,
      target_skus,
      schedule_type,
      schedule_hour_min,
      expires_in_value,
      expires_in_type,
      user_type,
      zip_codes,
    } = form.getFieldsValue([
      'name',
      'title',
      'body',
      'country_id',
      'target_skus',
      'schedule_type',
      'schedule_hour_min',
      'expires_in_value',
      'expires_in_type',
      'user_type',
      'zip_codes',
    ])

    const formData = {
      body,
      country_id,
      expires_in_type,
      expires_in_value,
      name,
      send_at: schedule_type[1] ? `${schedule_type[1]} ${schedule_hour_min?.format('HH:mm')}` : null,
      state: isDraft ? 'draft' : 'pending',
      target_skus,
      title,
      user_type,
      zip_codes,
    }

    onSubmit(formData, isDuplicating ? null : selectedNotification?.id || null, setFormLoading)
  }

  const getButtonText = () => (activePanel === 1) ? 'Preview' : 'Publish'

  const getTargetNames = () => {
    const skus = form.getFieldValue('target_skus')

    if (isEmpty(skus))
      return null

    return `Products: ${skus.map((sku) => find(targetItemList, { sku })?.name).join(', ')}`
  }

  const getZipCodes = () => {
    const zipCodes = form.getFieldValue('zip_codes')

    if (isEmpty(zipCodes))
      return null

    return `Zip code start with : ${zipCodes.join(' OR ')}`
  }

  const getScheduleName = () => {
    const scheduleType = form.getFieldValue('schedule_type')[1]

    return scheduleType
      ? `${form.getFieldValue('schedule_type')[1]} ${form.getFieldValue('schedule_hour_min').format('HH:mm')}`
      : 'Send now'
  }

  const initialValues = {
    body: selectedNotification?.body,
    country_id: selectedNotification?.country.id,
    expires_in_type: selectedNotification?.expires_in_type || 'week',
    expires_in_value: selectedNotification?.expires_in_value || 4,
    name: selectedNotification?.name,
    schedule_hour_min: (selectedNotification?.send_at && !isDuplicating) ? dayjs.utc(selectedNotification.send_at) : null,
    schedule_type: (selectedNotification?.send_at && !isDuplicating) ? ['Schedule', dayjs.utc(selectedNotification.send_at).format('DD.MM.YYYY')] : ['now'],
    state: selectedNotification?.state,
    target_skus: selectedNotification?.target_skus || [],
    title: selectedNotification?.title,
    user_type: selectedNotification?.user_type || 'regular_and_corporate',
    zip_codes: selectedNotification?.zip_codes || [],
  }

  const selectedCountryTimeZone = countries[findIndex(countries, { id: Form.useWatch('country_id', form) })]?.time_zone_name

  const ReviewPanel = () => (
    <Row>
      <Col span={24}>
        <Descriptions column={1} layout="vertical" title="Review message">
          <Descriptions.Item label="Notification content" labelStyle={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            <b>{form.getFieldValue('title')}</b>
            <p>{form.getFieldValue('body')}</p>
          </Descriptions.Item>

          <Descriptions.Item label="Target" labelStyle={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            <p>Country: {countries[findIndex(countries, { id: form.getFieldValue('country_id', form) })]?.name}</p>
            <p>{getTargetNames()}</p>
            <p>{getZipCodes()}</p>
            <p>User type: {form.getFieldValue('user_type') || 'Regular and corporate'}</p>
          </Descriptions.Item>

          <Descriptions.Item label="Scheduling" labelStyle={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            {getScheduleName()}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )

  return (
    <StyledModal
      footer={[
        <ModifiedModalFooter key="padding">
          {(isModalAnUpdate && (!selectedNotification?.id || selectedNotification?.is_deletable)) &&
            <DeleteButton key="delete" onDelete={() => onDelete(selectedNotification.id)} />
          }
          <Space>
            <Button data-testid="saveDraftNotificationModalSelenium" key="saveDraft" name="saveDraftNotificationModalSelenium" onClick={(e) => handleSubmit(e, true)}>
            Save draft
            </Button>

            <Button key="back" name="cancelNotificationModalSelenium" onClick={() => activePanel === 1 ? hideModal() : setActivePanel(1)}>
              {activePanel === 1 ? 'Cancel' : 'Back'}
            </Button>

            <Can
              key={isModalAnUpdate ? 'update' : 'add'}
              requiredPermission={(isModalAnUpdate && !isDuplicating) ? 'notification.put.notification_id' : 'notification.post.create'}
              yes={() => (
                <Button
                  data-testid={(isModalAnUpdate && !isDuplicating) ? 'updateNotificationModalSelenium' : 'addNotificationModalSelenium'}
                  key="submit"
                  loading={isFormLoading}
                  name={(isModalAnUpdate && !isDuplicating) ? 'updateNotificationModalSelenium' : 'addNotificationModalSelenium'}
                  onClick={activePanel === 1 ? showPreview : handleSubmit}
                  type="primary"
                >
                  {getButtonText()}
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title={isModalAnUpdate ? isDuplicating ? 'Duplicate notification' : 'Update notification' : 'Create notification'}

    >
      <Collapse
        activeKey={activePanel}
        bordered={false}
        ghost
        items={[
          {
            children: (
              <Row>
                <StyledCol lg={{ span:16 }} xs={{ span: 24 }}>
                  <Form data-test="notification-details-form" form={form} initialValues={initialValues} layout="vertical">
                    <Steps
                      current={0}
                      direction="vertical"
                      items={[
                        {
                          description: <NotificationStep />,
                          title: 'Notification',
                        },
                        {
                          description:(
                            <TargetStep
                              countries={countries}
                              getPotentialUsers={getPotentialUsers}
                              isLoading={isLoading}
                              onCountryChange={onCountryChange}
                              potentialUsers={potentialUsers}
                              targetItemList={targetItemList}
                            />
                          ),
                          title: 'Target',
                        },
                        {
                          description:(
                            <SchedulingStep
                              hasTimeError={hasTimeError}
                              scheduling={scheduling}
                              selectedCountryTimeZone={selectedCountryTimeZone}
                              setScheduling={setScheduling}
                            />
                          ),
                          title: 'Scheduling',
                        },
                      ]}
                    />
                  </Form>
                </StyledCol>

                <StyledPreviewCol lg={{ span: 8 }} xs={{ span: 24 }}>
                  <PushNotificationPreview
                    text={Form.useWatch('body', form)}
                    title={Form.useWatch('title', form)}
                  />
                </StyledPreviewCol>
              </Row>
            ),
            key: '1',
            showArrow: false,
          },
          {
            children: <ReviewPanel />,
            key: '2',
            showArrow: false,
          },
        ]}
      />

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </StyledModal>
  )
}

const StyledCol = styled(Col)`
  padding-left: 30px;
`

const StyledPreviewCol = styled(Col)`
  @media (min-width: 1440px) {
    max-width: 404px;
  }
`

const StyledModal = styled(Modal)`
  @media (min-width: 992px) {
    width: 70% !important;
  }

  @media (max-width: 768px) {
    width: 90% !important;
  }
`

NotificationModal.propTypes = {
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  isDuplicating: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resetBackendErrorMessage: PropTypes.func.isRequired,
  selectedNotification: PropTypes.object,
}

export default NotificationModal
