import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const Loading = () => (
  <div className="center">
    <Spin
      indicator={
        <LoadingOutlined data-testid="loading" spin style={{ fontSize: 48, marginTop: 60 }} />
      }
    />
  </div>
)

export default Loading
