import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isObject, omit } from 'lodash'
import styled from 'styled-components'
import { Alert, Button, Modal, notification } from 'antd'
import { getCorrectFieldName, handleBackendError } from '../../utils'

const FieldGroup = styled.div`
  margin: 20px 0;
`

const Label = styled.div`
  margin: 3px 0;

  font-weight: bold;
`

const FieldValue = styled.div`
  margin: 3px 0;

  text-transform: capitalize;
`

export const InfoModal = ({
  apiRequest,
  errorMessage,
  hideModal,
  selectedId,
  title,
}) => {
  const [data, setData]= useState({})

  useEffect(() => {
    apiRequest(selectedId)
      .then(({ data }) => setData(omit(data, ['full_address'])))
      .catch((error) => {
        notification.error({
          description: handleBackendError(error, 'Fetching data failed!'),
          duration: 3,
          message: 'Error',
        })

        hideModal()
      })
  }, [selectedId])

  return (
    <Modal
      footer={[
        <Button data-testid="cancelInfoModalBtnSelenium" key="back" name="cancelInfoModalBtnSelenium" onClick={hideModal}>
          Close
        </Button>,
      ]}
      onCancel={hideModal}
      open
      title={title}
    >
      <div>
        {
          Object.keys(data).map(key => (
            <FieldGroup key={key} name={key}>
              <Label>{getCorrectFieldName(key)}</Label>
              {isObject(data[key])
                ?
                Object.keys(data[key]).map(objectKey => (
                  <FieldGroup key={objectKey} name={objectKey}>
                    <Label>{getCorrectFieldName(objectKey)}</Label>
                    <FieldValue>{data[key][objectKey]}</FieldValue>
                  </FieldGroup>
                ))
                : <FieldValue>{data[key]}</FieldValue>
              }
            </FieldGroup>
          ))
        }
      </div>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

InfoModal.propTypes = {
  apiRequest: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default InfoModal
