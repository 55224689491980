import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash'
import { Alert, Button, Collapse, Form, Input, InputNumber, Modal, notification } from 'antd'
import { Can, CustomPagination, CustomTable } from '../'
import {
  getTableHeaderDatePicker,
  getTableHeaderCheckboxFilter,
  handleBackendError,
  localizedDate,
  useCustomSearchParams,
} from '../../utils'

import { getUserTransactions } from '../../pages/users/api'
import { createBalanceAdjustment } from './api'

const { TextArea } = Input

const FieldGroup = styled.div`
  margin: 20px 0;

  &:first-child {
    margin-top: 0px;
  }
`

const Label = styled.div`
  margin: 3px 0;
`

const FieldValue = styled.div`
  margin: 3px 0;

  font-weight: bold;
`

const TransactionHistoryModal = ({
  config,
  errorMessage,
  hideModal,
  selectedUser,
}) => {
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useCustomSearchParams()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(true)
  const [userTransactions, setUserTransactions] = useState([])

  const typeFilters = orderBy(config.config?.transaction_types.map(type => ({ text: type, value: type })), 'text', 'asc')
  const statusFilters = orderBy(config.config?.transaction_statuses.map(status => ({ text: status, value: status })), 'text', 'asc')
  const categoryFilters = orderBy(config.config?.transaction_categories.map(type => ({ text: type.split('::')[1], value: type })), 'text', 'asc')

  const [metaData, setMetaData] = useState()

  const isZeroDecimal = selectedUser.country_locales[0] === 'ja-JP'

  useEffect(() => {
    setSearch({ ...search, modal_items_per_page: 10, modal_page: 1, modal_sort_by: 'created_at asc' })
  }, [])

  useEffect(() => {
    if (search.modal_page === 'null')
      return

    let filterParams = {}

    Object.keys(search).forEach((key => {
      if (!!~key.indexOf('modal_')) {
        filterParams[key.replace('modal_', '')] = search[key]
      }
    }))

    setIsLoading(true)

    getUserTransactions(selectedUser.id, new URLSearchParams(filterParams).toString())
      .then(({ data }) => {
        setMetaData(data.meta)
        setUserTransactions(data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        notification.error({
          description: handleBackendError(error, 'Fetching transaction history failed!'),
          message: 'Error',
        })
      })

  }, [searchParams, selectedUser])

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setIsLoading(true)

          const fieldsValue = form.getFieldsValue([
            'amount',
            'comment',
          ])

          const requiredFieldsValue = {
            ...fieldsValue,
            user_id: selectedUser.id,
          }

          createBalanceAdjustment(requiredFieldsValue)
            .then(() => {
              notification.success({
                description: 'Balance adjustment complete!',
                message: 'Success',
              })

              hideModal()
            })
            .catch((error) => {
              setIsLoading(false)

              notification.error({
                description: handleBackendError(error, 'Creating balance adjustment failed!'),
                message: 'Error',
              })
            })
        })
    }
  }

  const tableColumns = [
    {
      className: 'transactionCategorySelenium',
      dataIndex: 'category',
      filteredValue: searchParams.getAll('modal_category'),
      key: 'category',
      render: (_, record) => (
        <span>{record.category?.split('::')[1]}</span>
      ),
      sorter: true,
      title: 'Category',
      ...getTableHeaderCheckboxFilter('transactionCategorySelenium', categoryFilters, 'modal_category', 'single'),
    },
    {
      className: 'transactionTypeSelenium',
      dataIndex: 'type',
      filteredValue: searchParams.getAll('modal_type'),
      key: 'type',
      render: (_, record) => (
        <span>{record.type}</span>
      ),
      sorter: true,
      title: 'Type',
      ...getTableHeaderCheckboxFilter('transactionTypeSelenium', typeFilters, 'modal_type', 'single'),
    },
    {
      className: 'transactionStatusSelenium',
      dataIndex: 'status',
      filteredValue: searchParams.getAll('modal_status'),
      key: 'status',
      render: (_, record) => record.status || 'N/A',
      sorter: true,
      title: 'Status',
      ...getTableHeaderCheckboxFilter('transactionStatusSelenium', statusFilters, 'modal_status', 'single'),
    },
    {
      className: 'transactionCreatedAtSelenium',
      dataIndex: 'created_at',
      defaultSortOrder: 'descend',
      filteredValue: searchParams.getAll(''),
      key: 'created_at',
      render: (_, record) => localizedDate(record.created_at),
      sorter: true,
      title: 'Created at',
      ...getTableHeaderDatePicker('createdAtDatePickerSelenium', 'modal_created_at_gteq', 'modal_created_at_lteq'),
    },
    {
      className: 'transactionUpdatedAtSelenium',
      dataIndex: 'updated_at',
      filteredValue: searchParams.getAll(''),
      key: 'updated_at',
      render: (_, record) => localizedDate(record.updated_at),
      sorter: true,
      title: 'Updated at',
      ...getTableHeaderDatePicker('transactionUpdatedAtSelenium', 'modal_updated_at_gteq', 'modal_updated_at_lteq'),
    },
    {
      className: 'transactionAmountSelenium',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => new Intl.NumberFormat(selectedUser.country_locales[0], { currency: selectedUser.currency_symbol, style: 'currency' }).format(record.amount),
      sorter: true,
      title: 'Amount',
    },
    {
      className: 'transactionNoteSelenium',
      dataIndex: 'comment',
      key: 'note',
      title: 'Note',
    },
  ]

  const BalanceAdjustmentPanel = () => (
    <Form data-testid="balanceAdjustmentForm" form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item label="Amount" name="amount" rules={[{ message: 'Please adjust User\'s balance!', required: true }]}>
        <InputNumber
          addonBefore={selectedUser.currency_symbol.toUpperCase()}
          data-testid="amount"
          precision={isZeroDecimal ? 0 : 2}
          style={{ width: '100%' }}
          type="number"
        />
      </Form.Item>

      <Form.Item label="Comment (will be visible for the user)" name="comment">
        <TextArea />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" name="saveButtonSelenium" type="primary">
          Save
        </Button>
      </Form.Item>
    </Form>
  )

  return (
    <Modal
      data-testid="transacionModal"
      footer={[]}
      onCancel={hideModal}
      open
      title={`${selectedUser.name} transaction history`}
      width={1000}
    >
      {selectedUser.status !== 'inactive' && selectedUser.user_type === 'regular' &&
        <Can
          requiredPermission="adjustment.post.create"
          yes={() => (
            <Collapse
              items={[
                {
                  children: <BalanceAdjustmentPanel />,
                  key: '1',
                  label: 'Balance Adjustment',
                },
              ]}
            />
          )}
        />
      }

      <FieldGroup name="userName">
        <Label>User name</Label>
        <FieldValue>{selectedUser.name}</FieldValue>
      </FieldGroup>

      <FieldGroup name="userId">
        <Label>User id</Label>
        <FieldValue>{selectedUser.id}</FieldValue>
      </FieldGroup>

      <FieldGroup name="email">
        <Label>E-mail</Label>
        <FieldValue>{selectedUser.email}</FieldValue>
      </FieldGroup>

      <FieldGroup name="country">
        <Label>Country</Label>
        <FieldValue>{selectedUser.country_name}</FieldValue>
      </FieldGroup>

      <FieldGroup name="balance">
        <Label>Balance</Label>
        <FieldValue>{new Intl.NumberFormat(selectedUser.country_locales[0], { currency: selectedUser.currency_symbol, style: 'currency' }).format(selectedUser.balance)}</FieldValue>
      </FieldGroup>

      <CustomTable
        className="usersTransactionHistoryTableSelenium"
        columns={tableColumns}
        dataSource={userTransactions}
        isModalTable={true}
        loading={isLoading}
        metaData={metaData}
      />

      <CustomPagination metaData={metaData} prefix="modal_" />

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

TransactionHistoryModal.propTypes = {
  config: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
}

export default TransactionHistoryModal
