import React, { useEffect, useState } from 'react'
import { Button, Col, notification, Popconfirm, Row, Tooltip } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Can, CustomPagination, CustomTable, Icon, IconContainer, PageContainer, PageTitleRow } from '../../components'
import { handleBackendError } from '../../utils'
import BinTypesModal from './BinTypeModal'

import { createBinType, deleteBinType, getBinTypes, updateBinType } from './api'
import { getCountryList } from '../country-management/api'

const BinTypes = () => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [binTypes, setBinTypes] = useState([])
  const [selectedBinType, setSelectedBinType] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [countries, setCountries] = useState([])

  const [metaData, setMetaData] = useState()

  useEffect(() => {
    setLoading(true)

    getCountryList()
      .then(({ data }) => {
        setCountries(data.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)

        handleFailure(error, 'Fetching bin types failed!')
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchBinTypesList(searchParams.toString())
  }, [searchParams])

  const fetchBinTypesList = (params) => {
    setLoading(true)

    getBinTypes(params)
      .then(({ data }) => {
        setBinTypes(data.data)
        setMetaData(data.meta)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)

        handleFailure(error, 'Fecthing countries failed!')
      })
  }

  const tableColumns = [
    {
      className: 'binTypeNameSelenium',
      dataIndex: 'title',
      key: 'name',
      title: 'Name',
    },
    {
      className: 'binTypeCountrySelenium',
      dataIndex: 'country_name',
      key: 'country',
      title: 'Country',
    },
    {
      className: 'binTypeInfoCopySelenium',
      dataIndex: 'info',
      key: 'info',
      title: 'Info copy',
    },
    {
      className: 'binTypeCreatedAtSelenium',
      dataIndex: 'created_at',
      key: 'created_at',
      title: 'Created at',
    },
    {
      className: 'binTypeUpdatedAtSelenium',
      dataIndex: 'updated_at',
      key: 'updated_at',
      title: 'Last updated',
    },
    {
      className: 'BinTypesAssignedSelenium',
      dataIndex: 'bins_count',
      key: 'bins_count',
      title: '№ assigned bins',
    },
    {
      className: 'actionsSelenium',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <IconContainer>
          <Can
            no={() => (
              <Tooltip title="View">
                <Icon
                  data-testid="viewBtn"
                  name="updateBinTypeSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="eye"
                />
              </Tooltip>
            )}
            requiredPermission="bin_type.put.bin_type_id"
            yes={() => (
              <Tooltip title="Edit">
                <Icon
                  data-testid="editBtn"
                  name="updateBinTypeSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="edit"
                />
              </Tooltip>
            )}
          />
          {(!record.bins_count || record.bins_count === 0) &&
            <Can
              requiredPermission="bin_type.delete.bin_type_id"
              yes={() => (
                <Tooltip title="Delete">
                  <Popconfirm
                    cancelText="No"
                    className="action-list-button"
                    okText="Yes"
                    onConfirm={() => handleDeleteBinType(record)}
                    title="Are you sure you want to delete this bin type?"
                  >
                    <Icon data-testid="deleteIcon" name="deleteBinTypeSelenium" type="delete" />
                  </Popconfirm>
                </Tooltip>
              )}
            />
          }
        </IconContainer>
      )
      ,
      title: 'Actions',
    },
  ]

  const handleOpenModal = (selectedBinType) => {
    setSelectedBinType(selectedBinType)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchBinTypesList(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const handleDeleteBinType = (record) => {
    deleteBinType(record.id)
      .then(() => handleSuccess({ description: 'Bin type removed!' }, true))
      .catch((error) => handleFailure(error, 'Bin type removal failed!'))
  }

  const handleFormSubmit = (actionType, formData) => {
    if (actionType === 'UPDATE') {
      updateBinType(formData)
        .then(() =>
          handleSuccess({ description: 'Bin type updated!' }),
        )
        .catch((error) =>
          handleFailure(error, 'Bin type update failed!'),
        )
    }

    if (actionType === 'CREATE') {
      createBinType(formData)
        .then(() =>
          handleSuccess({ description: 'Bin type created!' }),
        )
        .catch((error) =>
          handleFailure(error, 'Bin type creation failed!' ),
        )
    }
  }

  return (
    <PageContainer>
      <PageTitleRow title="Bin types">
        <Can
          requiredPermission="bin_type.post.create"
          yes={() => (
            <Button
              data-testid="newBinType"
              name="newBinTypeSelenium"
              onClick={() => handleOpenModal(null)}
              type="primary"
            >
              NEW BIN TYPE
            </Button>
          )}
        />
      </PageTitleRow>
      <Row>
        <Col span={24}>
          <CustomTable
            className="binTypePageSelenium"
            columns={tableColumns}
            data-testid="binTypesTable"
            dataSource={binTypes}
            loading={isLoading}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible && (
            <BinTypesModal
              countries={countries}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onDelete={handleDeleteBinType}
              onSubmit={handleFormSubmit}
              selectedBinType={selectedBinType}
              setModalLoading={setModalLoading}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

export default BinTypes
