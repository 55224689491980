import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { omit } from 'lodash'

const { Option } = Select

const CustomFormSelect = styled(Select)`
  & input {
    cursor: pointer !important;
  }
`

const CustomSelect = (props) => {
  const cleanedProps = omit(props, 'entries')

  return (
    <CustomFormSelect
      {...cleanedProps}
      autoComplete="none"
      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      optionFilterProp="children"
      showSearch
    >
      {props.entries.map(({ iso, name }) =>
        <Option key={iso} value={iso}>{name}</Option>,
      )}
    </CustomFormSelect>
  )
}

CustomSelect.propTypes = {
  entries: PropTypes.array.isRequired,
  onChange: PropTypes.func,
}

export default CustomSelect
