import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'antd'

const { Paragraph } = Typography

export const Copyable = ({ text }) => (
  <ModifiedParagraph copyable ellipsis={{ tooltip: text }}>{text}</ModifiedParagraph>
)

const ModifiedParagraph = styled(Paragraph)`
  &:hover {
    .ant-typography-copy {
      visibility: visible;
    }
  }

  width: 100px;

  margin-bottom: 0 !important;

  .ant-typography-copy {
    visibility: hidden;
  }
`

Copyable.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Copyable
