import PropTypes from 'prop-types'

export default PropTypes.shape({
  config: PropTypes.shape({
    admin_user_roles: PropTypes.arrayOf(PropTypes.string),
    currencies: PropTypes.arrayOf(PropTypes.string),
    scan_statuses: PropTypes.arrayOf(PropTypes.string),
    stores: PropTypes.arrayOf(PropTypes.object),
    user_statuses: PropTypes.arrayOf(PropTypes.string),
    user_types: PropTypes.arrayOf(PropTypes.string),
    widthdraw_statuses: PropTypes.arrayOf(PropTypes.string),
    withdraw_types: PropTypes.arrayOf(PropTypes.string),
  }),
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
})
