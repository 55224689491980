import PropTypes from 'prop-types'

export default PropTypes.shape({
  code: PropTypes.string,
  created_at: PropTypes.string,
  currency_name: PropTypes.string,
  currency_symbol: PropTypes.string,
  id: PropTypes.number,
  locales: PropTypes.arrayOf(PropTypes.string),
  min_withdrawal_amount: PropTypes.string,
  name: PropTypes.string,
  privacy_policy_url: PropTypes.string,
  updated_at: PropTypes.string,
  zipcode_pattern: PropTypes.string,
})
