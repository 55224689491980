import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MDEditor from '@uiw/react-md-editor'
import { find, isUndefined } from 'lodash'
import { ModifiedModalFooter } from '../../elements'
import { Can, ModalDeleteButton } from '../../components'
import { handleBackendError } from '../../utils'
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Space,
} from 'antd'
import { getFaqCategories } from '../faq-categories/api'

const { Option } = Select
const { TextArea } = Input

const FaqDetailsModal = ({
  countries,
  errorMessage,
  hideModal,
  isModalLoading,
  onDelete,
  onSubmit,
  selectedCountryId,
  selectedFaq,
  selectedFaqCategoryType,
  setModalLoading,
}) => {
  const isUpdate = !!selectedFaq
  const activeCountry = isUpdate ? find(countries, { id: selectedFaq.country_id}) : find(countries, { id: selectedCountryId })
  const [categories, setCategories] = useState([])
  const [form] = Form.useForm()
  const [initialCategory, setInitialCategory] = useState([])
  const [categoryErrorMessage, setCategoryErrorMessage] = useState()

  const fetchCategories = (params) => {
    getFaqCategories(params)
      .then(({ data }) => {
        const categoryValue = data.data.map(category => category.id)

        setCategories(data.data)
        setInitialCategory(categoryValue[0])

        form.setFieldsValue({
          faq_category_id: isUpdate ? selectedFaq.faq_category_id : categoryValue[0],
          position: isUpdate ? selectedFaq.position : data.data[0]?.highest_faq_position + 1,
        })
      })
      .catch((error) => {
        handleFailure(error, 'Fetching categories failed!')
      })
  }

  useEffect(() => {
    fetchCategories(`country_id=${activeCountry.id}&category_type=${selectedFaqCategoryType}`)
  }, [])

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fields = ['answer', 'faq_category_id', 'position', 'question']

          const fieldsValue = form.getFieldsValue(fields)
          const id = isUpdate ? selectedFaq.id : undefined

          onSubmit(fieldsValue, id)
        })
    } else {
      hideModal()
    }
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setCategoryErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const CategorySelector = forwardRef((props, ref) => (
    <Select onChange={handleSelect} ref={ref} {...props}>
      {props.categories.map(({ id, title }) =>
        <Option key={id} name="categoryOption" value={id}>{title}</Option>,
      )}
    </Select>
  ))

  const handleSelect = (value) => {
    const currentCategory = categories.filter(category => category.id === value)

    form.setFieldsValue({
      position: currentCategory[0].highest_faq_position + 1,
    })
  }

  const initialValues = {
    answer: isUpdate ? selectedFaq.answer[activeCountry.locales[0]] : '',
    faq_category_id: isUpdate ? selectedFaq.faq_category_id : initialCategory,
    position: isUpdate ? selectedFaq.position : 1,
    question: isUpdate ? selectedFaq.question[activeCountry.locales[0]] : '',
  }

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <ModalDeleteButton
            confirmationText="Are you sure you want to delete this faq?"
            isUpdate={isUpdate}
            key="delete"
            name="deleteAdminModalBtnSelenium"
            onDelete={() => onDelete(selectedFaq.id)}
            requiredPermission="faq.delete.faq_id"
          />
          <Space>
            <Button key="back" name="cancelBinModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              key={isUpdate ? 'update' : 'add'}
              requiredPermission={isUpdate ? 'faq.put.faq_id' : 'faq.post.create'}
              yes={() => (
                <Button
                  data-testid={isUpdate ? 'updateDetailsFaqSelenium' : 'addFaqSelenium'}
                  key="submit"
                  loading={isModalLoading}
                  name={isUpdate ? 'updateDetailsFaqSelenium' : 'addFaqSelenium'}
                  onClick={handleSubmit}
                  type="primary"
                >
                  {isUpdate ? 'Update' : 'Add'}
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title={isUpdate ? 'Edit faq' : 'New faq'}
    >
      {isUndefined(initialCategory) &&
        <Alert description="You can not add FAQ without a category. Please add a category first!" showIcon style={{ marginBottom: 15 }} type="warning" />
      }

      <Form data-test="faq-details-form" form={form} initialValues={initialValues} layout="vertical">
        <Form.Item
          label="Position"
          max={1e+6}
          name="position"
          rules={[{ message: 'Please provide a valid position!', required: true, type: 'number' }]}
        >
          <InputNumber parser={(value) => value.split('.')[0].split(',')[0]} style={{ width: '100%' }} type="number" />
        </Form.Item>

        <Form.Item label="Country">
          <Input disabled value={activeCountry.name} />
        </Form.Item>

        <Form.Item label="Category" name="faq_category_id">
          <CategorySelector categories={categories} onChange={handleSelect} />
        </Form.Item>

        <Form.Item
          label="Question"
          name="question"
          rules={[
            {
              message: 'Please provide a question!',
              required: true,
            },
            {
              max: 2000,
              message: 'Question should be less than 2000 character',
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Answer"
          name="answer"
          rules={[
            {
              message: 'Please provide a answer!',
              required: true,
            },
            {
              max: 2000,
              message: 'Answer should be less than 2000 character',
            },
          ]}
        >
          <MDEditor
            data-color-mode="light"
            overflow={false}
            preview="edit"
            textareaProps={{
              maxLength: 2000,
              placeholder: 'Answer should be less than 2000 character',
            }}
          />
        </Form.Item>
      </Form>

      {(errorMessage || categoryErrorMessage) && (
        <Alert
          description={errorMessage || categoryErrorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

FaqDetailsModal.propTypes = {
  categories: PropTypes.array,
  countries: PropTypes.array,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedCountryId: PropTypes.string,
  selectedFaq: PropTypes.object,
  selectedFaqCategoryType: PropTypes.string,
  setModalLoading: PropTypes.func.isRequired,
}

export default FaqDetailsModal
