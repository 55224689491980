import styled from 'styled-components'

const IconContainer = styled.div`
  display: flex;
  justify-content: center;

  span {
    margin: 0 5px;

    font-size: 20px;
  }
`

export default IconContainer
