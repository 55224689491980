import instance from '../../api'

const BASE_ENDPOINT = '/api/admin/payment_methods/transferwise'

export const getPaymentMethods = (id) => instance.get('/api/admin/payment_methods', id)

export const getBankCode = (params) => instance.get('/api/public/transferwises/bank_codes', params)

export const getBranchCode = (params) => instance.get('/api/public/transferwises/bank_branches', params)

export const createPaymentMethod = (countryCode, fields) => instance.post(`${BASE_ENDPOINT}/${countryCode}`, fields)
