import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Badge, Button, Col, notification, Popconfirm, Row, Tooltip } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { isEmpty, truncate } from 'lodash'
import {
  Can,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  PageContainer,
  PageTitleRow,
} from '../../components'
import {
  getTableHeaderCheckboxFilter,
  getTableHeaderSearchInput,
  handleBackendError,
  useCustomSearchParams,
} from '../../utils'
import { NotificationModal } from './NotificationModal'
import {
  createNotification,
  deleteNotification,
  getNotificationList,
  updateNotification,
} from './api'

dayjs.extend(utc)

const NotificationPage = () => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [notificationList, setNotificationList] = useState([])
  const [isNotificationDuplicating, setIsNotificationDuplicating] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState()
  const [isLoading, setLoading] = useState(false)

  const [metaData, setMetaData] = useState()

  useCustomSearchParams({ items_per_page: 25, page: 1, sort_by: 'start_at desc' })

  const statusFilter = [
    { text: 'Draft', value: 'draft' },
    { text: 'Pending', value: 'pending' },
    { text: 'Delivered', value: 'delivered' },
  ]

  const fetchNotificationList = (search) => {
    setLoading(true)

    getNotificationList(search)
      .then(({ data }) => {
        setNotificationList(data.data)
        setMetaData(data.meta)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)

        handleFailure(error, 'Fetching notification list failed!')
      })
  }

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchNotificationList(searchParams.toString())
  }, [searchParams])

  const tableColumns = [
    {
      className: 'countrySelenium',
      dataIndex: 'country',
      filteredValue: searchParams.getAll('country'),
      key: 'country',
      render: (_, { country }) => <>{country.name}</>,
      title: 'Country',
    },
    {
      className: 'nameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.getAll('name_cont'),
      key: 'name_cont',
      render: (_, { name }) => <>{truncate(name, { length: 35 })}</>,
      sorter: true,
      title: 'Name',
      ...getTableHeaderSearchInput('nameSelenium', 'name_cont'),
    },
    {
      className: 'pushNotificationSelenium',
      dataIndex: 'title_or_body_cont',
      filteredValue: searchParams.getAll('title_or_body_cont'),
      key: 'title_or_body_cont',
      render: (_, { title, body }) => (
        <div>
          {title && <b>{title}</b>}
          <p>{truncate(body, { length: 35 })}</p>
        </div>
      ),
      title: 'Push notification',
      ...getTableHeaderSearchInput('pushNotificationSelenium', 'title_or_body_cont'),
    },
    {
      className: 'start_at',
      dataIndex: 'start_at',
      defaultSortOrder: 'descend',
      filteredValue: searchParams.getAll('start_at'),
      key: 'start_at',
      render: (_, { start_at, state, updated_at }) => (
        <>
          {start_at
            ? dayjs.utc(start_at).format('DD.MM.YYYY HH:mm')
            : state === 'draft'
              ? 'Send now'
              : dayjs.utc(updated_at).format('DD.MM.YYYY HH:mm')
          }
        </>
      ),
      sorter: true,
      title: 'Start',
    },
    {
      className: 'end',
      dataIndex: 'end',
      filteredValue: searchParams.getAll('end'),
      key: 'end',
      render: (_, { expires_in_type, expires_in_value }) => <>{`${expires_in_value} ${expires_in_type}`}</>,
      title: 'End',
    },
    {
      className: 'state',
      dataIndex: 'state',
      filteredValue: searchParams.getAll('state_in[]'),
      key: 'state',
      render: (_, { notified_user_count, state }) => (
        <>
          {
            state === 'delivered'
              ? (
                <>
                  {state} <Badge count={notified_user_count || 0} data-testid="badge" overflowCount={9999} showZero status="processing" />
                </>
              )
              : state
          }
        </>
      ),
      title: 'Status',
      ...getTableHeaderCheckboxFilter('state', statusFilter, 'state_in', 'multiple'),
    },
    {
      className: 'notificationActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          {record.is_editable &&
            <Can
              requiredPermission="notification.get.index"
              yes={() => (
                <Tooltip title="Edit">
                  <Icon
                    data-testid="editBtn"
                    name="updateNotificationSelenium"
                    onClick={() => handleOpenModal(record)}
                    type="edit"
                  />
                </Tooltip>
              )}
            />
          }

          <Can
            requiredPermission="notification.post.create"
            yes={() => (
              <Tooltip title="Duplicate">
                <Icon
                  data-testid="duplicateNotificationSelenium"
                  name="duplicateNotificationSelenium"
                  onClick={() => handleOpenModal(record, true)}
                  type="duplicate"
                />
              </Tooltip>
            )}
          />

          {record.is_deletable &&
            <Can
              requiredPermission="notification.delete.notification_id"
              yes={() => (
                <Tooltip title="Delete">
                  <Popconfirm
                    cancelText="No"
                    okText="Yes"
                    onConfirm={() => handleDelete(record.id)}
                    title="Are you sure you want to delete this notification?"
                  >
                    <Icon data-testid="deleteBtn" name="deleteNotificationSelenium" type="delete" />
                  </Popconfirm>
                </Tooltip>
              )}
            />
          }
        </IconContainer>
      ),
      title: 'Actions',
    },
  ]

  const handleFormSubmit = (formData, id, setFormLoading) => {
    setErrorMessage(null)

    const action = id
      ? updateNotification
      : createNotification

    return action(formData, id)
      .then(() => {
        handleSuccess({ description: `Notification has been ${id ? 'updated' : 'created'}` })
      })
      .catch((error) => {
        setFormLoading(false)
        handleFailure(error, `Notification ${id ? 'updating' : 'creation'} failed!`)
      })
  }

  const handleOpenModal = (selectedNotification, isDuplicating = false) => {
    setSelectedNotification(selectedNotification)
    setIsNotificationDuplicating(isDuplicating)
    setErrorMessage(null)
    setModalVisibility(true)
  }

  const handleSuccess = (messageOptions) => {
    fetchNotificationList(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const resetBackendErrorMessage = () => {
    setErrorMessage(null)
  }

  const handleDelete = (recordId) => {
    deleteNotification(recordId)
      .then(() => handleSuccess({ description: 'Notification removed' }))
      .catch((error) => handleFailure(error, 'Notification removal failed!'))
  }

  return (
    <PageContainer>
      <PageTitleRow title="Notifications">
        <Can
          requiredPermission="notification.post.create"
          yes={() => (
            <Button
              data-testid="createNotificationSelenium"
              name="createNotificationSelenium"
              onClick={() => handleOpenModal(null)}
              type="primary"
            >
              NEW NOTIFICATION
            </Button>
          )}
        />
      </PageTitleRow>
      <Row>
        <Col span={24}>
          <CustomTable
            className="notificationPageTableSelenium"
            columns={tableColumns}
            dataSource={notificationList}
            defaultOrder={{
              sortBy: 'start_at',
              sortDir: 'descend',
            }}
            loading={isLoading}
            metaData={metaData}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible && (
            <NotificationModal
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isDuplicating={isNotificationDuplicating}
              onDelete={handleDelete}
              onSubmit={handleFormSubmit}
              resetBackendErrorMessage={resetBackendErrorMessage}
              selectedNotification={selectedNotification}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

export default NotificationPage
