import React, { useEffect, useState } from 'react'
import BinDetailsModal from './BinDetailsModal'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, notification, Popconfirm, Row, Space, Tooltip } from 'antd'
import { isEmpty, orderBy } from 'lodash'
import {
  Can,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  PageContainer,
  PageTitleRow,
} from '../../components'
import { createDownloadLink, handleBackendError, getTableHeaderCheckboxFilter, getTableHeaderSearchInput } from '../../utils'
import { withConfig } from '../../modules/'
import { configPropTypes } from '../../types'
import { getCountryList } from '../country-management/api'
import { createBin, deleteBin, getBinList, updateBin, exportBins } from './api'
import { getBinTypes } from '../bin-types/api'

export const Bins = ({ config }) => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [bins, setBins] = useState([])
  const [selectedBin, setSelectedBin] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [countryFilters, setCountryFilters] = useState([])
  const [binTypeFilters, setBinTypesFilter] = useState([])
  const [metaData, setMetaData] = useState()
  const retailerFilters = orderBy(config.config?.retailers.map(retailer => ({ text: retailer.name, value: retailer.id })), 'text', 'asc')

  useEffect(() => {
    getCountryList()
      .then(({ data }) => {
        const filters = orderBy(data.data.map(country => ({ text: country.name, value: country.id })), 'text', 'asc')

        if (filters.length > 0) {
          setCountryFilters(filters)
        }
      })
      .catch((error) => {
        handleFailure(error, 'Fetching countries failed!')
      })

    getBinTypes()
      .then(({ data }) => {
        const binTypes = orderBy(data.data.map(binType => ({
          text: `${binType.title} (${binType.country_name})`,
          value: binType.id,
        })), 'text', 'asc')

        if (binTypes?.length > 0) {
          setBinTypesFilter(binTypes)
        }})
      .catch((error) => {
        setBinTypesFilter([])

        handleFailure(error, 'Fetching bin types failed!')
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchBinList(searchParams.toString())
  }, [searchParams])

  const fetchBinList = (params) => {
    setIsLoading(true)

    getBinList(params)
      .then(({ data }) => {
        setMetaData(data.meta)
        setBins(data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)

        notification.error({
          description: error.message,
          message: 'Error',
        })
      })
  }

  const tableColumns = [
    {
      className: 'binNameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.getAll('name'),
      key: 'name',
      sorter: true,
      title: 'Name',
      ...getTableHeaderSearchInput('binNameSelenium', 'name'),
    },
    {
      className: 'binCountrySelenium',
      dataIndex: 'country_id',
      filteredValue: searchParams.getAll('country_id'),
      key: 'country_id',
      render: (_, record) => (
        <>{record.country_name}</>
      ),
      title: 'Country',
      ...getTableHeaderCheckboxFilter('binCountrySelenium', countryFilters, 'country_id', 'single'),
    },
    {
      className: 'binRetailerSelenium',
      dataIndex: 'retailer_id',
      filteredValue: searchParams.getAll('retailer_id'),
      key: 'retailer_id',
      render: (_, record) => (
        <>{record?.retailer?.name}</>
      ),
      title: 'Retailer',
      ...getTableHeaderCheckboxFilter('binRetailerSelenium', retailerFilters, 'retailer_id', 'single'),
    },
    {
      className: 'binLatitudeSelenium',
      dataIndex: 'latitude',
      key: 'latitude',
      title: 'Lat',
    },
    {
      className: 'binLongitudeSelenium',
      dataIndex: 'longitude',
      key: 'longitude',
      title: 'Lon',
    },
    {
      className: 'binTypeSelenium',
      dataIndex: 'bin_type',
      filteredValue: searchParams.getAll('bin_type[]'),
      key: 'type',
      render: (_, record) => (
        <>{record.bin_type_title} ({record.country_name})</>
      ),
      title: 'Type',
      ...getTableHeaderCheckboxFilter('binTypeSelenium', binTypeFilters, 'bin_type', 'multiple'),
    },
    {
      className: 'binDetailsSelenium',
      dataIndex: 'details',
      key: 'details',
      title: 'Details',
    },
    {
      className: 'binActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          <Can
            no={() => (
              <Tooltip title="View">
                <Icon
                  data-testid="viewBtn"
                  name="updateBinSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="eye"
                />
              </Tooltip>
            )}
            requiredPermission="bin.put.bin_id"
            yes={() => (
              <Tooltip title="Edit">
                <Icon
                  data-testid="editBtn"
                  name="updateBinSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="edit"
                />
              </Tooltip>
            )}
          />

          <Can
            requiredPermission="bin.delete.bin_id"
            yes={() => (
              <Tooltip title="Delete">
                <Popconfirm
                  cancelText="No"
                  className="action-list-button"
                  okText="Yes"
                  onConfirm={() => handleDeleteBin(record)}
                  title="Are you sure you want to delete this bin?"
                >
                  <Icon data-testid="deleteIcon" name="deleteBinSelenium" type="delete" />
                </Popconfirm>
              </Tooltip>
            )}
          />
        </IconContainer>
      ),
      title: 'Actions',
    },
  ]

  const handleExportBinsCSV = () => {
    exportBins(searchParams.toString())
      .then(({ blob, fileName }) => {
        createDownloadLink(blob, fileName)

        notification.success({
          description: 'Bins exported',
          duration: 3,
          message: 'Success',
        })
      })
      .catch((error) => {
        handleFailure(error, 'Bins export failed!')
      })
  }

  const handleDeleteBin = (record) => {
    return deleteBin(record.id)
      .then(() => handleSuccess({ description: 'Bin removed successfully.' }, true))
      .catch((error) => handleFailure(error, 'Bin removal failed!'))
  }

  const handleFormSubmit = (actionType, formData) => {
    switch (actionType) {
    case 'UPDATE':
      return updateBin(formData)
        .then(() =>
          handleSuccess({ description: 'Bin updated' }),
        )
        .catch((error) =>
          handleFailure(error, 'Bin update failed!'),
        )

    case 'CREATE':
      return createBin(formData)
        .then(() =>
          handleSuccess({ description: 'Bin created' }),
        )
        .catch((error) =>
          handleFailure(error,'Bin creation failed!'),
        )
    default:
      return
    }
  }

  const handleOpenModal = (selectedBin) => {
    setSelectedBin(selectedBin)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchBinList(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  return (
    <PageContainer>
      <PageTitleRow title="Bins">
        <Space>
          <Can
            name="exportBinSelenium"
            requiredPermission="bin.get.export"
            yes={() => (
              <Button
                data-testid="exportBtn"
                name="exportBinBtnSelenium"
                onClick={handleExportBinsCSV}
              >
                Export CSV
              </Button>
            )}
          />
          <Can
            requiredPermission="bin.post.create"
            yes={() => (
              <Button
                data-testid="newBinBtn"
                name="createBinSelenium"
                onClick={() => handleOpenModal(null)}
                type="primary"
              >
                NEW BIN
              </Button>
            )}
          />
        </Space>
      </PageTitleRow>
      <Row>
        <Col span={24}>
          <CustomTable
            className="BinsPageTableSelenium"
            columns={tableColumns}
            dataSource={bins}
            defaultOrder={{
              sortBy: undefined,
              sortDir: undefined,
            }}
            loading={isLoading}
            metaData={metaData}
          />

          <CustomPagination metaData={metaData} />

          {isModalVisible && (
            <BinDetailsModal
              binTypes={binTypeFilters}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onDelete={handleDeleteBin}
              onSubmit={handleFormSubmit}
              retailers={config.config.retailers}
              selectedBin={selectedBin}
              setModalLoading={setModalLoading}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

Bins.propTypes = {
  config: configPropTypes,
}

export default withConfig(Bins)
