import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Button, Input, Form, notification } from 'antd'
import { Auth, withProfile, withConfig } from '../../modules'
import { configPropTypes, userPropTypes } from '../../types'
import { handleBackendError } from '../../utils'

export const Login = ({ user, config }) => {
  const navigate = useNavigate()

  const onFinish = (form) => {
    const { email, password } = form

    Auth.signIn({
      email,
      password,
    })
      .then(({ data }) => {
        Auth.saveEmail(data.data.email)

        user.refetch()
        config.refetch()

        navigate('/')
      })
      .catch((error) => {
        notification.error({
          description: handleBackendError(error, 'Login failed!'),
          message: 'Login error',
        })
      })
  }

  return(
    <Col className="site-layout-content site-layout-background" span="14">
      <Form
        initialValues={{ remember: true }}
        labelCol={{ span: 16 }}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ message: 'Please input your email!', required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ message: 'Please input your password!', required: true }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item className="center" wrapperCol={{ offset: 6, span: 12 }}>
          <Button htmlType="submit" type="primary">
                Submit
          </Button>
        </Form.Item>
      </Form>
    </Col>
  )
}

Login.propTypes = {
  config: configPropTypes,
  user: userPropTypes,
}

export default withConfig(withProfile(Login))
