import React from 'react'
import PropTypes from 'prop-types'
import { ModifiedModalFooter } from '../../elements'
import { Alert, Button, Form, Input, Modal, Popconfirm, Select, Space } from 'antd'
import { DeleteOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Can } from '../../components'
import { withConfig } from '../../modules'
import { configPropTypes } from '../../types'

const { Option } = Select

export const DeleteButton = ({ isUpdate, onDelete }) => {
  if (!isUpdate) return null

  return (
    <Can
      key="delete"
      requiredPermission="admin_user.delete.admin_user_id"
      yes={() => (
        <Popconfirm
          cancelText="No"
          className="action-list-button"
          okText="Yes"
          onConfirm={onDelete}
          title="Are you sure you want to delete this admin?"
        >
          <Button
            danger
            data-testid="modalDeleteBtn"
            icon={<DeleteOutlined />}
            name="deleteAdminModalBtnSelenium"
            style={{ float: 'left' }}
          >
            Delete
          </Button>
        </Popconfirm>
      )}
    />
  )
}

DeleteButton.propTypes = {
  isUpdate: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
}

export const AdminDetailsModal = ({
  config,
  errorMessage,
  hideModal,
  isModalLoading,
  onDelete,
  onSubmit,
  selectedAdmin,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedAdmin

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()
    const passwordFieldValues = form.getFieldsValue([
      'password',
      'password_confirmation',
    ])

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const requiredFieldsValue = form.getFieldsValue([
            'name',
            'email',
            'roles',
          ])

          const fieldsValue = {
            ...requiredFieldsValue,
            ...(passwordFieldValues.password && passwordFieldValues),
            ...(isUpdate && { id: selectedAdmin?.id }),
          }

          const actionType = isUpdate ? 'UPDATE' : 'CREATE'

          onSubmit(actionType, fieldsValue)
        })
    } else {
      hideModal()
    }
  }

  const initialValues = {
    email: selectedAdmin?.email,
    name: selectedAdmin?.name,
    password: '',
    password_confirmation: '',
    roles: selectedAdmin?.roles,
  }

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <DeleteButton isUpdate={isUpdate} key="delete" onDelete={() => onDelete(selectedAdmin)} />
          <Space>
            <Button key="back" name="cancelAdminModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              key={isUpdate ? 'update' : 'add'}
              requiredPermission={isUpdate ? 'admin_user.put.admin_user_id' : 'admin_user.post.create'}
              yes={() => (
                <Button data-testid={isUpdate ? 'updateBtn' : 'addBtn'} key="submit" loading={isModalLoading} name={isUpdate ? 'updateAdminDetailsSelenium' : 'addAdminSelenium'} onClick={handleSubmit} type="primary">
                  {isUpdate ? 'Update' : 'Add'}
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title={isUpdate ? 'Update admin' : 'Create admin'}
    >
      <Form data-test="admin-details-form" data-testid="admin-details-form" form={form} initialValues={initialValues} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ message: 'Please provide a name!', required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              message: 'Please provide an email!',
              required: true,
            },
            {
              message: 'The input is not valid email!',
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Roles" name="roles" rules={[{ message: 'Please select roles!', required: true }]}>
          <Select data-testid="rolesSelect" mode="multiple">
            {config.config.admin_user_roles.sort().map((role) =>
              <Option key={role} name="adminRolesOption" value={role}>{role}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item label={`Password${isUpdate ? ' (New)' : ''}`} name="password" rules={[{ message: 'Password must be minimum 8 characters.', min: 8 }, { message: 'Please provide a password!', required: !isUpdate }]}>
          <Input.Password
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            placeholder={isUpdate ? 'Leave empty to keep the current. Type to set a new one.' : ''}
          />
        </Form.Item>

        <Form.Item
          dependencies={['password']}
          label={`Password Confirmation${isUpdate ? ' (New)' : ''}`}
          name="password_confirmation"
          rules={[
            {
              message: 'Please confirm your password!',
              required: !isUpdate,
            },
            {
              message: 'Password must be minimum 8 characters.',
              min: 8,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            }),
          ]}
        >
          <Input.Password
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            placeholder={isUpdate ? 'Leave empty to keep the current. Type to set a new one.' : ''}
          />
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

AdminDetailsModal.propTypes = {
  config: configPropTypes,
  errorMessage: PropTypes.node,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedAdmin: PropTypes.object,
  setModalLoading: PropTypes.func.isRequired,
}

export default withConfig(AdminDetailsModal)
