import React from 'react'
import PropTypes from 'prop-types'
import { Result } from 'antd'
import { translations } from '../../../constants'

const ThankYouPage = ({ language }) => (
  <Result
    status="success"
    subTitle={translations[language].thankYouSubTitle}
    title={translations[language].thankYouTitle}
  />
)

ThankYouPage.propTypes = {
  language: PropTypes.string,
}

export default ThankYouPage
