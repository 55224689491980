/**
 * Returns the response for success, thows error on failure.
 *
 * @param {Object} response - fetch request's result
 *
 * @returns {Object} The parsed JSON when it exists - or the detailed error.
 */
const apiExportResponseHandler = async (response) => {
  const statusCode = response.status

  switch (true) {
  case (statusCode >= 500):
    throw Error(response.statusText)
  case (statusCode >= 400): {
    const { details, error } = await response.json()
    const fields = Object.keys(details)

    const errorMessages = fields.map((field) => (
      `${field} - ${details[field]}`
    ))

    throw Error(`${response.status} ${error}: \n${errorMessages.join('\n')}`)
  }
  case (statusCode === 204):
    return {}
  default:
    return {
      blob: await response.blob(),
      fileName: response.headers.get('content-disposition'),
    }
  }
}

export default apiExportResponseHandler
