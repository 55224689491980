import 'react-app-polyfill/ie9'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Root from './admin/Root'

const root = createRoot(document.body.appendChild(document.createElement('div')))

document.addEventListener('DOMContentLoaded', () => {
  root.render(<Root />)
})
