import instance from '../../api'

const BASE_ENDPOINT = '/api/admin/notifications'

export const createNotification = (fields) => instance.post(BASE_ENDPOINT, fields)

export const updateNotification = (fields, id) => instance.put(`${BASE_ENDPOINT}/${id}`, fields)

export const deleteNotification = (id) => instance.delete(`${BASE_ENDPOINT}/${id}`)

export const getTargetItems = (country_id) => instance.get(`${BASE_ENDPOINT}/target_items`, {
  params: {
    country_id,
  },
})

export const getNotificationList = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const getUserCount = ({ country_id, target_skus, user_type, zip_codes }) => instance.get(`${BASE_ENDPOINT}/user_count`, {
  params: {
    country_id,
    target_skus,
    user_type,
    zip_codes,
  },
})
