import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { compact, has, isEmpty, orderBy } from 'lodash'
import { Button, Col, notification, Popconfirm, Row, Tooltip } from 'antd'
import {
  Can,
  Copyable,
  CustomPagination,
  CustomTable,
  Icon,
  IconContainer,
  InfoModal,
  PageContainer,
  PageTitleRow,
} from '../../components'
import { withConfig } from '../../modules'
import { configPropTypes } from '../../types'
import {
  createDownloadLink,
  getTableHeaderCheckboxFilter,
  getTableHeaderDatePicker,
  getTableHeaderSearchInput,
  handleBackendError,
  localizedDate,
} from '../../utils'
import { getCountryList } from '../country-management/api'
import { WithdrawalInfoModal } from './WithdrawalInfoModal'

import {
  cancelWithdrawal,
  exportWithdrawals,
  payoutWithdrawal,
  getBankData,
  getWithdrawalHistory,
} from './api'

const WithdrawalStatus = styled.div`
  text-transform: capitalize;
  color: #cc3434;
  ${props => props.type === 'completed' && 'color: #2fb327'};
  ${props => props.type === 'pending' && 'color: #36f'};
`
export const WithdrawHistory = ({ config }) => {
  const [searchParams] = useSearchParams()

  const [isWithrawalInfoModalVisible, setWithdrawalModalVisibility] = useState(false)
  const [isBankInfoModalVisible, setBankInfoModalVisibility] = useState(false)

  const [withdrawals, setWithdrawals] = useState([])
  const [selectedWithdrawal, setSelectedWithdrawal] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [countryFilters, setCountryFilters] = useState([])

  const [metaData, setMetaData] = useState()

  const typeFilter = [
    { text: 'Paypal', value: 'PaymentMethods::Paypal' },
    { text: 'Pring', value: 'PaymentMethods::Pring' },
    { text: 'Transferwise', value: 'PaymentMethods::Transferwise' },
    { text: 'LINE Pay', value: 'PaymentMethods::Line' },
  ]

  const statusFilters = orderBy(config.config?.withdrawal_statuses.map(status => ({ text: status, value: status })), 'text', 'desc')

  useEffect(() => {
    getCountryList()
      .then(({ data }) => {
        const filters = orderBy(data.data.map(({ name, id }) => ({ text: name, value: id })), 'text', 'desc')

        if (filters.length > 0) {
          setCountryFilters(filters)
        }
      })
      .catch((error) => {
        handleFailure(error, 'Fetching countries failed!')
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchWithdrawals(searchParams.toString())
  }, [searchParams])

  const fetchWithdrawals = (params) => {
    setIsLoading(true)

    getWithdrawalHistory(params)
      .then(({ data }) => {
        setMetaData(data.meta)
        setWithdrawals(data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        handleFailure(error, 'Fetching withdrawal history failed!')
      })
  }

  const userCanCheckDetails = has(config.config.user_permissions, 'withdrawal.get.withdrawal_id')
  const userCanCheckBankData = has(config.config.user_permissions, 'withdrawal.get.withdrawal_id_bank_data')

  const tableColumns = compact([
    {
      className: 'withdrawalIdSelenium',
      filteredValue: searchParams.getAll('id'),
      key: 'id',
      render: (_, record) => (
        <div style={{ maxWidth: 150 }}>
          <Copyable text={record.id} />
        </div>
      ),
      title: 'Withdrawal Id',
      ...getTableHeaderSearchInput('withdrawalIdSelenium', 'id'),
    },
    {
      className: 'withdrawalStatusSelenium',
      filteredValue: searchParams.getAll('status[]'),
      key: 'status',
      render: (_, record) => (
        <WithdrawalStatus type={record.status}>{record.status}</WithdrawalStatus>
      ),
      title: 'Status',
      ...getTableHeaderCheckboxFilter('withdrawalStatusSelenium', statusFilters, 'status', 'multiple'),
    },
    {
      className: 'userNameSelenium',
      dataIndex: 'name',
      filteredValue: searchParams.getAll('name'),
      key: 'name',
      title: 'User name',
      ...getTableHeaderSearchInput('userNameSelenium', 'name'),
    },
    {
      className: 'countrySelenium',
      filteredValue: searchParams.getAll('country_id'),
      key: 'country_id',
      render: (_, record) => (
        <>{record.country_name}</>
      ),
      title: 'Country',
      ...getTableHeaderCheckboxFilter('countrySelenium', countryFilters, 'country_id', 'single'),
    },
    {
      className: 'createdAtSelenium',
      dataIndex: 'created_at',
      filteredValue: searchParams.getAll(''),
      key: 'created_at',
      render: (_, record) => (
        <span>{localizedDate(record.created_at)}</span>
      ),
      sorter: true,
      title: 'Created at',
      ...getTableHeaderDatePicker('createdAtDatePickerSelenium', 'created_at_gteq', 'created_at_lteq'),
    },
    {
      className: 'updatedAtSelenium',
      key: 'updated_at',
      render: (_, record) => (
        <span>{localizedDate(record.updated_at)}</span>
      ),
      title: 'Updated at',
    },
    {
      className: 'amountSelenium',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => (
        <div>{new Intl.NumberFormat(record.locale, { currency: record.currency_symbol, style: 'currency' }).format(record.amount)}</div>
      ),
      sorter: true,
      title: 'Amount',
    },
    {
      className: 'typeSelenium',
      filteredValue: searchParams.getAll('type[]'),
      key: 'type',
      render: (_, record) => (
        <div>{record.type.replace('PaymentMethods::', '')}</div>
      ),
      title: 'Type',
      ...getTableHeaderCheckboxFilter('typeSelenium', typeFilter, 'type', 'multiple'),
    },
    userCanCheckBankData
      ? {
        className: 'toSelenium',
        key: 'to',
        render: (_, record) => (
          record.type === 'PaymentMethods::Transferwise'
            ? (
              <IconContainer>
                <Button data-testid="bankDataBtn" name="bankDataButtonSelenium" onClick={() => handleOpenBankInfoModal(record)} type="primary">BANK DATA</Button>
              </IconContainer>
            )
            : (
              <span name="accountNumberSelenium">{record.account_number}</span>
            )
        ),
        title: 'To',
      }
      : null,
    userCanCheckDetails
      ? {
        className: 'detailsSelenium',
        key: 'details',
        render: (_, record) => (
          <Button data-testid="detailsButtonSelenium" name="detailsButtonSelenium" onClick={() => handleOpenWithdrawalInfoModal(record)} type="link">Details</Button>
        ),
        title: 'Details',
      }
      : null,
    {
      className: 'withdrawActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          {
            (record.status === 'pending') &&
              <>
                <Can
                  requiredPermission="withdrawal.put.withdrawal_id_payout"
                  yes={() => (
                    <Tooltip title="Pay out">
                      <Popconfirm
                        cancelText="Cancel"
                        className="action-list-button"
                        okText="Confirm"
                        onConfirm={() => handlePayoutConfirmation(record.id)}
                        title="Are you sure you want to confirm this withdrawal payout?"
                      >
                        <Icon data-testid="confirmPayoutSelenium" name="confirmPayoutSelenium" style={{ color: 'green' }} type="checkCircle" />
                      </Popconfirm>
                    </Tooltip>
                  )}
                />
                <Can
                  requiredPermission="withdrawal.put.withdrawal_id_cancel"
                  yes={() => (
                    <Tooltip title="Decline">
                      <Popconfirm
                        cancelText="Cancel"
                        className="action-list-button"
                        okText="Confirm"
                        onConfirm={() => handleCancelWithdrawal(record)}
                        title={`Are you sure you want to confirm cancelling this ${record.type.replace('PaymentMethods::', '')} withdrawal?`}
                      >
                        <Icon data-testid="cancelWithdrawalSelenium" name="cancelWithdrawalSelenium" style={{ color: 'red' }} type="close" />
                      </Popconfirm>
                    </Tooltip>
                  )}
                />
              </>
          }
        </IconContainer>
      ),
      title: 'Actions',
    },
  ])

  const handleExportWithdrawalsCSV = () => {
    exportWithdrawals(searchParams.toString())
      .then(({ blob, fileName }) => {
        createDownloadLink(blob, fileName)

        notification.success({
          description: 'Withdrawals exported',
          duration: 3,
          message: 'Success',
        })
      })
      .catch((error) => {
        handleFailure(error, 'Withdrawals export failed!')
      })
  }

  const handleCancelWithdrawal = (record) => (
    cancelWithdrawal(record.id)
      .then(() => handleSuccess({ description: `${record.type.replace('PaymentMethods::', '')} withdrawal cancelled` }, true))
      .catch((error) => handleFailure(error, `${record.type.replace('PaymentMethods::', '')} withdrawal cancellation failed!`))
  )

  const handleOpenWithdrawalInfoModal = (selectedWithdrawal) => {
    setSelectedWithdrawal(selectedWithdrawal)
    setWithdrawalModalVisibility(true)
  }

  const handleOpenBankInfoModal = (selectedWithdrawal) => {
    setSelectedWithdrawal(selectedWithdrawal)
    setBankInfoModalVisibility(true)
  }

  const handleSuccess = (messageOptions) => {
    fetchWithdrawals(searchParams.toString())

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }
    const errorMessage = handleBackendError(error, message)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const handlePayoutConfirmation = (id) => {
    return payoutWithdrawal(id)
      .then(() =>
        handleSuccess({ description: 'Withdrawal payout confirmed' }),
      )
      .catch((error) =>
        handleFailure(error, 'Withdrawal payout confirmation failed!'),
      )
  }

  return (
    <PageContainer>
      <PageTitleRow title="Withdrawals">
        <Can
          requiredPermission="withdrawal.get.export"
          yes={() => (
            <Button
              data-testid="exportWithdrawalsSelenium"
              name="exportWithdrawalsSelenium"
              onClick={handleExportWithdrawalsCSV}
            >
              Export CSV
            </Button>
          )}
        />

      </PageTitleRow>
      <Row>
        <Col span={24}>
          <CustomTable
            className="withdrawalHistoryPageTableSelenium"
            columns={tableColumns}
            data-testid="withdrawalHistoryPageTableSelenium"
            dataSource={withdrawals}
            defaultOrder={{
              sortBy: undefined,
              sortDir: undefined,
            }}
            loading={isLoading}
            metaData={metaData}
            scroll={{ x: true }}
          />

          <CustomPagination metaData={metaData} />

          {isWithrawalInfoModalVisible && (
            <WithdrawalInfoModal
              hideModal={() => setWithdrawalModalVisibility(false)}
              selectedWithdrawal={selectedWithdrawal}
            />
          )}

          {isBankInfoModalVisible && (
            <InfoModal
              apiRequest={getBankData}
              hideModal={() => setBankInfoModalVisibility(false)}
              selectedId={selectedWithdrawal.id}
              title="Details of TransferWise withdrawal"
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

WithdrawHistory.propTypes = {
  config: configPropTypes,
}

export default withConfig(WithdrawHistory)
