import React from 'react'
import { ModifiedModalFooter } from '../../elements'
import { find } from 'lodash'
import { Can } from '../../components'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
} from 'antd'

const { Option } = Select

const FaqCategoryModal = ({
  countries,
  errorMessage,
  hideModal,
  highestPosition = 0,
  isModalLoading,
  onSubmit,
  selectedCountryId,
  selectedCategory,
  selectedFaqCategoryType,
  setModalLoading,
}) => {
  const isUpdate = !!selectedCategory
  const activeCountry = isUpdate ? find(countries, { id: selectedCategory.country_id}) : find(countries, { id: selectedCountryId })
  const [form] = Form.useForm()

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()
    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fields = ['title', 'position', 'category_type', 'country_id']
          const fieldsValue = form.getFieldsValue(fields)
          const id = isUpdate ? selectedCategory.id : undefined

          onSubmit(fieldsValue, id)
        })
    } else {
      hideModal()
    }
  }

  const initialValues = {
    category_type: selectedFaqCategoryType,
    country_id: activeCountry.id,
    position: isUpdate ? selectedCategory.position : (highestPosition + 1),
    title: isUpdate ? selectedCategory.title : '',
  }

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <Space>
            <Button key="back" name="cancelCategoryModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              key={isUpdate ? 'update' : 'add'}
              requiredPermission={isUpdate ? 'faq_category.put.faq_category_id' : 'faq_category.post.create'}
              yes={() => (
                <Button data-testid={isUpdate ? 'updateBtn' : 'addBtn'} key="submit" loading={isModalLoading} name={isUpdate ? 'updateCategorySelenium' : 'addCategorySelenium'} onClick={handleSubmit} type="primary">
                  {isUpdate ? 'Update' : 'Add'}
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title={isUpdate ? 'Edit category' : 'New category'}
    >
      <Form data-test="faq-details-form" form={form} initialValues={initialValues} layout="vertical">
        <Form.Item
          label="Position"
          max={1e+6}
          name="position"
          rules={[{ message: 'Please provide a valid position!', required: true, type: 'number' }]}
        >
          <InputNumber parser={value => Math.floor(value)} style={{ width: '100%' }} type="number" />
        </Form.Item>

        <Form.Item label="Country">
          <Input disabled value={activeCountry.name} />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              message: 'Please provide a category title!',
              required: true,
            },
            {
              max: 2000,
              message: 'Category title should be less than 2000 character',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Type" name="category_type">
          <Select disabled>
            <Option name="categoryTypeOption" value="regular">Regular</Option>,
            <Option name="categoryTypeOption" value="corporate">Corporate</Option>,
          </Select>
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

FaqCategoryModal.propTypes = {
  countries: PropTypes.array,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  highestPosition: PropTypes.number,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object,
  selectedCountryId: PropTypes.string,
  selectedFaqCategoryType: PropTypes.string,
  setModalLoading: PropTypes.func.isRequired,
}

export default FaqCategoryModal
