import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Flex,
  Menu,
  notification,
  Popconfirm,
  Radio,
  Row,
  Tooltip,
} from 'antd'
import MDEditor from '@uiw/react-md-editor'
import { filter, isEmpty } from 'lodash'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Can,
  CustomPagination,
  CustomTable,
  DragAndDrop,
  DragButton,
  Icon,
  PageContainer,
  PageTitleRow,
} from '../../components'
import {
  getTableHeaderCheckboxFilter,
  handleBackendError,
  useCustomSearchParams,
} from '../../utils'
import { IconContainer } from '../../elements'
import { getCountryList } from '../country-management/api'
import FaqDetailsModal from './FaqDetailsModal'
import { getFaqCategories } from '../faq-categories/api'
import {
  createFaq,
  deleteFaq,
  getFaqs,
  updateFaq,
} from './api'

const Faq = () => {
  const [searchParams] = useSearchParams()
  const [categoryFilters, setCategoryFilters] = useState(false)
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [faqs, setFaqs] = useState([])
  const [countries, setCountries] = useState([])
  const [defaultCountryId, setDefaultCountryId] = useState(null)
  const [selectedFaq, setSelectedFaq] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const items = countries.map( country => (
    {
      key: country.id,
      label: (<span>{country.name}</span>),
    }
  ))

  const [metaData, setMetaData] = useState()
  const [search, setSearch] = useCustomSearchParams()

  useEffect(() => {
    getCountryList()
      .then(({ data }) => {
        const tempParams = { ...search }
        const defaultCountryId = filter(data.data, { name: 'United States' })[0].id

        setCountries(data.data)
        setDefaultCountryId(defaultCountryId)

        setSearch({
          ...tempParams,
          category_type: search.category_type || 'regular',
          country_id:  search.country_id || defaultCountryId,
        })
      })
      .catch((error) => {
        handleFailure(error, 'Fetching countries failed!')
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(searchParams.toString())) {
      fetchFaqs(searchParams.toString())
      fetchCategories(`category_type=${search.category_type || 'regular'}&country_id=${search.country_id || defaultCountryId}`)
    }
  }, [searchParams])

  const fetchFaqs = (params) => {
    setIsLoading(true)
    getFaqs(params)
      .then(({ data }) => {
        setFaqs(data.data)
        setMetaData(data.meta)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        handleFailure(error, 'Fetching FAQs failed!')
      })
  }

  const fetchCategories = (params) => {
    getFaqCategories(params)
      .then(({ data }) => setCategoryFilters(() => data.data.map(({ id, title }) => ({ text: title, value: id }))))
      .catch((error) => handleFailure(error, 'Fetching categories failed!'))
  }

  const tableColumns = [
    {
      align: 'center',
      className: 'positionSelenium',
      dataIndex: 'position',
      key: 'position',
      render: () => <DragButton />,
      width: 80,
    },
    {
      className: 'categorySelenium',
      dataIndex: 'Category',
      filteredValue: searchParams.getAll('faq_category_id_eq'),
      key: 'Category',
      render: (_, record) => (
        <p>{record.category_title}</p>
      ),
      title: 'Category',
      ...getTableHeaderCheckboxFilter('faqCategorySelenium', categoryFilters, 'faq_category_id_eq', 'single'),
    },
    {
      className: 'questionSelenium',
      key: 'question',
      render: (_, record) => (
        <p>{record.question[Object.keys(record.question)[0]]}</p>
      ),
      title: 'Question',
    },
    {
      className: 'answerSelenium',
      key: 'answer',
      render: (_, record) => (
        <MDEditor.Markdown
          source={record.answer[Object.keys(record.answer)[0]]}
          style={{ backgroundColor: 'transparent' }}
        />
      ),
      title: 'Answer',
    },
    {
      className: 'faqActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          <Can
            no={() => (
              <Tooltip title="View">
                <Icon
                  data-testid="updateFaqSelenium"
                  name="updateFaqSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="eye"
                />
              </Tooltip>
            )}
            requiredPermission="faq.put.faq_id"
            yes={() => (
              <Tooltip title="Edit">
                <Icon
                  data-testid="updateFaqSelenium"
                  name="updateFaqSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="edit"
                />
              </Tooltip>
            )}
          />
          <Can
            requiredPermission="faq.delete.faq_id"
            yes={() => (
              <Tooltip title="Delete">
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => handleDeleteFaq(record.id)}
                  title="Are you sure you want to delete this faq?"
                >
                  <Icon data-testid="deleteFaqSelenium" name="deleteFaqSelenium" type="delete" />
                </Popconfirm>
              </Tooltip>
            )}
          />
        </IconContainer>
      ),
      title: 'Actions',
    },
  ]

  const onTypeChange = (event) => {
    const tempParams = { ...search }

    delete tempParams.faq_category_id_eq
    setSearch({ ...tempParams, category_type: event.target.value, items_per_page: 25, page: 1 })
  }

  const handleClickCountry = ({ key }) => {
    const tempParams = { ...search }

    delete tempParams.faq_category_id_eq
    setSearch({ ...tempParams, country_id: key, items_per_page: 25, page: 1 })
  }

  const handleDeleteFaq = (recordId) => {
    deleteFaq(recordId)
      .then(() => handleSuccess({ description: 'Faq removed' }))
      .catch((error) => handleFailure(error, 'Faq removal failed!'),
      )
  }

  const handleFormSubmit = (formData, id) => (
    (id)
      ? (
        updateFaq(formData, id)
          .then(() => handleSuccess({ description: 'Faq updated' }))
          .catch((error) => handleFailure(error, 'Faq update failed!'),
          )
      )
      : (
        createFaq(formData)
          .then(() => handleSuccess({ description: 'Faq created' }))
          .catch((error) => handleFailure(error, 'Faq creation failed!'),
          )
      )
  )

  const handleOpenModal = (selectedFaq) => {
    setSelectedFaq(selectedFaq)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchFaqs(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const onDragEnd = ({ active, over }) => {
    if (active.id === over?.id) return false

    setIsLoading(true)

    updateFaq({ position: over.data.current.position }, active.id)
      .then((error) => fetchFaqs(searchParams.toString()))
      .catch((error) => handleFailure(error, 'Category update failed!'))
  }

  return (
    <PageContainer data-color-mode="light">
      <PageTitleRow
        title="FAQs"
        titleChildren={
          <Flex
            gap="middle"
            vertical
          >
            <b>FAQ Type</b>
            <Radio.Group buttonStyle="solid" defaultValue={search.category_type || 'regular'} onChange={onTypeChange}>
              <Radio.Button value="regular">Regular</Radio.Button>
              <Radio.Button value="corporate">Corporate</Radio.Button>
            </Radio.Group>
          </Flex>
        }
      >
        <Col
          style={{
            alignItems: 'center',
            color:'#1890ff',
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'flex-end',
            marginBottom: '15px',
          }}
        >
          <Icon type="partition" />
          <Link to="/faq_categories">Edit or create new category</Link>
          <Icon type="arrowRight" />
        </Col>
      </PageTitleRow>

      <Row>

        <Col span={20}>
          {countries &&
            <Menu data-testid="menu" items={items} mode="horizontal" onClick={handleClickCountry} selectedKeys={[search.country_id]} />
          }
        </Col>
        <Col span={4} style={{ borderBottom: '1px solid #f0f0f0', textAlign:'right' }}>
          <Can
            requiredPermission="faq.post.create"
            yes={() => (
              <Button
                data-testid="addNewFaqSelenium"
                name="addNewFaqSelenium"
                onClick={() => handleOpenModal(null)}
                type="primary"
              >
                NEW FAQ
              </Button>
            )}
          />
        </Col>
        <Col span={24}>
          <DragAndDrop items={faqs.map(({ id }) => id)} onDragEnd={onDragEnd}>
            <CustomTable
              className="faqPageTableSelenium"
              columns={tableColumns}
              data-testid="faqPageTableSelenium"
              dataSource={faqs}
              defaultOrder={{
                sortBy: undefined,
                sortDir: undefined,
              }}
              loading={isLoading}
              rowKey="id"
            />
          </DragAndDrop>

          {defaultCountryId &&
            <CustomPagination metaData={metaData} />
          }

          {isModalVisible && (
            <FaqDetailsModal
              countries={countries}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              isModalLoading={isModalLoading}
              onDelete={handleDeleteFaq}
              onSubmit={handleFormSubmit}
              selectedCountryId={search.country_id}
              selectedFaq={selectedFaq}
              selectedFaqCategoryType={search.category_type}
              setModalLoading={setModalLoading}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

export default Faq
