import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import {
  AuditOutlined,
  BarcodeOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  RestOutlined,
  SendOutlined,
  ShopOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { getPermittedValues } from '../..'
import { withConfig } from '../../../modules'
import { configPropTypes } from '../../../types'

/* eslint-disable */
const POSSIBLE_ITEMS = {
  admin_user: {
    icon: <AuditOutlined />,
    key: '/admin-user',
    label: (<Link to="/admin-user">Admins</Link>),
    name: 'adminUserSideMenuItemSelenium',
  },
  country: {
    icon: <GlobalOutlined />,
    key: '/countries',
    label: (<Link to="/countries">Countries</Link>),
    name:'countriesManagementSideMenuItemSelenium',
  },
  bin_type: {
    icon: <RestOutlined />,
    key: '/bin-types',
    label: (<Link to="/bin-types">Bin types</Link>),
    name:'binTypeSideMenuItemSelenium',
  },
  bin: {
    icon: <EnvironmentOutlined />,
    key: '/bins',
    label: (<Link to="/bins">Bins</Link>),
    name:'binsSideMenuItemSelenium',
  },
  store: {
    icon: <ShopOutlined />,
    key: '/stores',
    label: (<Link to="/stores">Stores</Link>),
    name:'storesSideMenuitemSelenium',
  },
  user: {
    icon: <UserOutlined />,
    key: '/users',
    label: (<Link to="/users">Users</Link>),
    name:'usersSideMenuItemSelenium',
  },
  scan: {
    icon: <BarcodeOutlined />,
    key: '/scans',
    label: (<Link to="/scans">Scans</Link>),
    name:'scansSideMenuItemSelenium',
  },
  withdrawal: {
    icon: <SendOutlined />,
    key: '/withdrawals',
    label: (<Link to="/withdrawals">Withdrawals</Link>),
    name:'withdrawalsSideMenuItemSelenium',
  },
  faq: {
    icon: <QuestionCircleOutlined />,
    key: '/FAQs',
    label: (<Link to="/FAQs">FAQs</Link>),
    name:'FAQsSideMenuItemSelenium',
  },
  notification: {
    icon: <MessageOutlined />,
    key: '/notifications',
    label: (<Link to="/notifications">Notifications</Link>),
    name:'notificationsSideMenuItemSelenium',
  },
}
/* eslint-enable */

export const SideMenu = ({ config }) => {
  const location = useLocation()

  const items = getPermittedValues(POSSIBLE_ITEMS, config.config.user_permissions)

  return (
    <StyledMenu
      items={items}
      mode="inline"
      selectedKeys={[location.pathname]}
    />
  )
}

const StyledMenu = styled(Menu)`
  height: 100%;
  width: 100%;

  padding: 20px 0 80px;
  border-right: 0;

  overflow-x: hidden;
`

SideMenu.propTypes = {
  config: configPropTypes,
}

export default withConfig(SideMenu)
