import instance from '../../api'

const BASE_ENDPOINT = '/api/admin/admin_users'

export const createAdmin = (fields) => instance.post(BASE_ENDPOINT, fields)

export const deleteAdmin = (id) => instance.delete(`${BASE_ENDPOINT}/${id}`)

export const getAdminList = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const updateAdmin = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.id}`, fields)
