import PropTypes from 'prop-types'
import { compact, has } from 'lodash'
import { withConfig } from '../../modules'
import { configPropTypes } from '../../types'

export const getPermittedValues = (items, usersPermissions) =>
  usersPermissions ?
    compact(Object.keys(items).map((key) =>
      usersPermissions[key]?.get?.index ? items[key] : null,
    ))
    : []

const defaultFunc = () => null

export const Can = ({ yes = defaultFunc, no = defaultFunc, requiredPermission, config }) => {
  const usersPermissions = config.config?.user_permissions || {}

  return has(usersPermissions, requiredPermission)
    ? yes()
    : no()
}

Can.propTypes = {
  config: configPropTypes,
  no: PropTypes.func,
  requiredPermission: PropTypes.string.isRequired,
  yes: PropTypes.func.isRequired,
}

Can.displayName = 'Can'

export default withConfig(Can)
