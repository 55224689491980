import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { range } from 'lodash'
import {
  Calendar,
  Cascader,
  Col,
  Row,
  Form,
  Select,
  TimePicker,
} from 'antd'

const { Option } = Select

const expireRange = {
  day: {
    range: 8,
  },
  hour: {
    range: 25,
  },
  minute: {
    range: 60,
  },
  week: {
    range: 5,
  },
}

const SchedulingStep = ({
  scheduling,
  setScheduling,
  hasTimeError,
  selectedCountryTimeZone,
}) => {
  const form = Form.useFormInstance()
  const [schedulePickerIsOpen, setSchedulePickerIsOpen ] = useState(undefined)

  const scheduleOnChange = (value) => {
    if (!value) return

    if (!!~value.indexOf('now')) {
      setScheduling(value[0])
    }
  }

  const setScheduleDate = (date) => {
    form.setFieldsValue({ schedule_type: ['Schedule', date.format('DD.MM.YYYY')] })

    setScheduling(date)
  }

  const resetExpiresValue = (newValue) => {
    const currentExpiresValue = form.getFieldValue('expires_in_value')

    if (currentExpiresValue > expireRange[newValue].range)
      form.setFieldsValue({ expires_in_value: expireRange[newValue].range -1 })
  }

  return (
    <>
      <p>Send to eligible users</p>

      <Row>
        <Col span={9}>
          <Form.Item name="schedule_type" rules={[{ message: 'Please select scheduling!', required: true }]}>
            <Cascader
              displayRender={label => label.join(' - ')}
              onChange={scheduleOnChange}
              onClick={() => setSchedulePickerIsOpen(undefined)}
              open={schedulePickerIsOpen}
              options={[
                {
                  label: 'Send now',
                  value: 'now',
                },
                {
                  children: [{
                    disabled: true,
                    label: (
                      <CalendarWrapper>
                        <Calendar
                          disabledDate={current => current && current <= dayjs().subtract(1, 'day')}
                          fullscreen={false}
                          onSelect={setScheduleDate}
                        />
                      </CalendarWrapper>
                    ),
                  }],
                  label: 'Schedule',
                  value: 'scedule',
                },
              ]}
            />
          </Form.Item>
        </Col>

        {scheduling !== 'now' &&
          <Col span={9} style={{ marginLeft: '10px' }}>
            <Row>
              <Col span={12}>
                <Form.Item
                  hasFeedback={hasTimeError}
                  help={hasTimeError ? 'The notification must be scheduled for a future date. Please schedule it accordingly.' : null}
                  name="schedule_hour_min"
                  rules={[{ message: 'Please select time!', required: scheduling !== 'now' }]}
                  validateStatus={hasTimeError ? 'warning' : 'validating'}
                >
                  <TimePicker format="HH:mm" style={{ width: 100 }} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <p style={{ lineHeight: '6px', marginLeft: '10px' }}>{selectedCountryTimeZone}</p>
              </Col>
            </Row>
          </Col>
        }
      </Row>

      <Row>
        <Col span={4} style={{ marginRight: '10px' }}>
          <Form.Item
            label="Expire"
            name="expires_in_value"
            rules={[{ message: 'Please select a number!', required: true }]}
            tooltip="How long the message should be kept for redelivery. The maximum expiration period is four weeks after the first delivery attempt."
          >
            <Select>
              {React.Children.toArray(range(expireRange[Form.useWatch('expires_in_type', form)]?.range || 4).map((value) =>
                <Option name="expirestypeOption" value={value}>{value}</Option>,
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label=" " name="expires_in_type">
            <Select onChange={resetExpiresValue}>
              <Option name="expireTypeOption" value="week">Weeks</Option>,
              <Option name="expireTypeOption" value="day">Days</Option>,
              <Option name="expireTypeOption" value="hour">Hours</Option>,
              <Option name="expireTypeOption" value="minute">Minutes</Option>,
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

const CalendarWrapper = styled.div`
  border: 1px solid #d9d9d9;
  width: 300px
`

SchedulingStep.propTypes = {
  hasTimeError: PropTypes.bool,
  scheduling: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedCountryTimeZone: PropTypes.string,
  setScheduling: PropTypes.func,
}

export default SchedulingStep
