import { createContext } from 'react'

export const defaultValues = {
  admin_user_roles: [],
  bin_types: [],
  currencies: [],
  error: null,
  scan_statuses: [],
  user_statuses: [],
  user_types: [],
  widthdraw_statuses: [],
  withdraw_types: [],
}

export default createContext(defaultValues)
