import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Divider, Modal, Select, Typography } from 'antd'
import { CaForm, EuForm, JpForm, UkForm, UsForm } from './countries'
import { createPaymentMethod } from './api'

const { Option } = Select
const { Text } = Typography

export const BankDetailsModal = (props) => {
  const { handleFailure, handleSuccess, selectedStore } = props
  const { name } = selectedStore

  const createPaymentMethodOnSubmit = ({ country, requiredFieldsValue, setModalLoading }) => {
    createPaymentMethod(country, { ...requiredFieldsValue, store_id: selectedStore.id })
      .then(() => {
        handleSuccess({ description: 'Bank data added' })
      })
      .catch((error) => {
        handleFailure(error, 'Bank data submission failed!')
        setModalLoading(false)
      })
  }

  const countriesFormData = {
    ca: {
      component: <CaForm {...props} onSubmit={createPaymentMethodOnSubmit} />,
      name: 'Canada',
    },
    fr: {
      component: <EuForm {...props} onSubmit={createPaymentMethodOnSubmit} />,
      name: 'France',
    },
    jp: {
      component: <JpForm {...props} onSubmit={createPaymentMethodOnSubmit} />,
      name: 'Japan',
    },
    uk: {
      component: <UkForm {...props} onSubmit={createPaymentMethodOnSubmit} />,
      name: 'United Kingdom',
    },
    us: {
      component: <UsForm {...props} onSubmit={createPaymentMethodOnSubmit} />,
      name: 'United States',
    },
  }

  const selectedRegion = countriesFormData[props.selectedStore.country_code] ? props.selectedStore.country_code : 'us'

  return (
    <Modal footer={null} onCancel={props.hideModal} open title="Add bank details">
      <Text style={{ display: 'block', marginBottom: '15px' }}>
        Store name
      </Text>

      <Text strong style={{ display: 'block', marginBottom: '15px' }}>{name}</Text>

      <Select
        defaultValue={selectedRegion}
        disabled
        name="regionSelectSelenium"
        style={{
          width: '100%',
        }}
      >
        {Object.keys(countriesFormData).map(key =>
          (<Option key={key} value={key}>{countriesFormData[key].name}</Option>),
        )}
      </Select>
      <Divider />

      {selectedRegion && countriesFormData[selectedRegion].component}

      {props.errorMessage && (
        <Alert
          description={props.errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

BankDetailsModal.propTypes = {
  errorMessage: PropTypes.string,
  handleFailure: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
}

export default BankDetailsModal
