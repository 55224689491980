import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { isEmpty, sumBy } from 'lodash'
import { CustomTable } from '../../components'
import { ModifiedModalFooter } from '../../elements'
import {
  Alert,
  Button,
  Divider,
  DatePicker,
  Form,
  Modal,
  notification,
  Progress,
  Popconfirm,
  Select,
  Space,
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { getTableHeaderSearchInput, handleBackendError, useCustomSearchParams } from '../../utils'

import { getStoresBatchBalance } from './api'
import { payoutBalance } from '../withdraw-history/api'

const { RangePicker } = DatePicker
const { Option } = Select

const defaultRangeDate = {
  begin_date: null,
  end_date: null,
}

const ModifiedRangePicker = styled(RangePicker)`
  margin-bottom: 15px;
`

const BatchPayoutModal = ({
  countries = [],
  hideModal,
}) => {
  const [searchParams] = useSearchParams()
  const [dateRange, setDateRange] = useState(defaultRangeDate)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isPayoutInProgress, setPayoutInProgress] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [countryStores, setCountryStores] = useState([])
  const [progressBarStatus, setProgressBarStatus] = useState('active')
  const [progressBarPercent, setProgressBarPercent] = useState(0)
  const [selectedStoreIds, setSelectedStoreIds] = useState([])
  const [selectedStoreErrors, setSelectedStoreErrors] = useState({})
  const [selectedCountryId, setSelectedCountryId] = useState(null)
  const [rangeAmount, setRangeAmount] = useState(0)

  useCustomSearchParams({ items_per_page: 25, page: 1, sort_by: 'name asc' })

  useEffect(() => {
    if (!dateRange.end_date || !dateRange.begin_date || !selectedCountryId)
      return

    getStoreListWithPeriodBalance()
  }, [dateRange, selectedCountryId, searchParams])

  const tableColumns = [
    {
      className: 'storeNameSelenium',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      filteredValue: searchParams.getAll('modal_name_cont'),
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      title: 'Name',
      ...getTableHeaderSearchInput('storeBatchPayoutModalNameSelenium', 'modal_name_cont'),
    },
    {
      className: 'storeSelectedPeriodBalanceSelenium',
      dataIndex: 'balance',
      key: 'balance',
      render: (_, record) => (new Intl.NumberFormat(record.country_locales[0], { currency: record.currency, style: 'currency' }).format(record.period_balance)),
      title: 'Balance for the selected period',
    },
  ]

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: ((parseFloat(record.balance) === 0) || (!record.wise_id)),
      name: record.name,
    }),
    onChange: (_, selectedRows) => {
      const selectedValidRowIds = selectedRows.filter(({ period_balance, wise_id }) => parseFloat(period_balance) > 0 && wise_id)

      setRangeAmount(sumBy(selectedValidRowIds, ({ period_balance }) => parseFloat(period_balance)))
      setSelectedStoreIds(selectedValidRowIds.map(({ id }) => id))
    },
    selectedRowKeys: selectedStoreIds,
  }

  const handleDateRangerChange = (_, date) => {
    setDateRange({
      begin_date: date[0],
      end_date: date[1],
    })
  }

  const batchPayoutApiCall = (storeIds, index) => {
    const hasNextStep = (index+1) < storeIds.length
    const progressBarPercent = parseFloat(((index + 1) / storeIds.length) * 100).toFixed(2)
    const store_id = storeIds[index]

    payoutBalance({
      begin_date: dateRange.begin_date,
      end_date: dateRange.end_date,
      store_id,
    })
      .then((data) => {
        setProgressBarPercent(progressBarPercent)

        if (hasNextStep) {
          batchPayoutApiCall(storeIds, index+1)
        } else {
          finishizeBatchPayoutMethod()
        }
      })
      .catch((error) => {
        setProgressBarPercent(progressBarPercent)

        const errorMessage = handleBackendError(error, 'Something went wrong')

        setSelectedStoreErrors((prevState) => ({
          ...prevState,
          [store_id]: errorMessage,
        }))

        if (hasNextStep) {
          batchPayoutApiCall(storeIds, index + 1)
        } else {
          finishizeBatchPayoutMethod(true)
        }
      })
  }

  const finishizeBatchPayoutMethod = (hasError = false) => {
    setProgressBarStatus('success')
    setModalLoading(false)
    setPayoutInProgress(false)
    setSelectedStoreIds([])
    setRangeAmount(0)
    getStoreListWithPeriodBalance()

    if (isEmpty(selectedStoreErrors) && !hasError) {
      notification.success({
        description: 'All selected stores were paid out successfully',
        duration: 5,
        message: 'Success',
      })
    }
  }

  const handleClick = () => {
    setError(null)

    if (!dateRange.end_date || !dateRange.begin_date) {
      setError('Missing start or end date!')

      return false
    }

    if (isEmpty(selectedStoreIds)) {
      setError('Missing selected stores')

      return false
    }

    setModalLoading(true)
    setPayoutInProgress(true)
    setProgressBarPercent(0)
    setProgressBarStatus('active')

    batchPayoutApiCall(selectedStoreIds, 0)
  }

  const handleCountryChange = (value) => {
    setSelectedCountryId(value)
  }

  const getStoreListWithPeriodBalance = () => {
    setModalLoading(true)

    getStoresBatchBalance(`begin_date=${dateRange.begin_date}&end_date=${dateRange.end_date}&country_id_eq=${selectedCountryId}&name_cont=${searchParams.getAll('modal_name_cont')}`)
      .then(({ data }) => {
        rowSelection.onChange([], data)

        setCountryStores(data)

        setModalLoading(false)
      })
      .catch((error) => {
        handleFailure(error, 'Fetching store list failed!')
        setModalLoading(false)
      })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)
    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const hasBatchPaymentError = !isEmpty(selectedStoreErrors)

  return (
    <Modal
      closeIcon={isPayoutInProgress ? <div /> : <CloseOutlined />}
      footer={[
        <ModifiedModalFooter key="padding">
          <Space>
            <Button
              disabled={isPayoutInProgress}
              key="back"
              name="cancelStoreBatchPayoutModalBtnSelenium"
              onClick={hideModal}
            >
              Cancel
            </Button>
            <Popconfirm
              cancelText="Cancel"
              disabled={isEmpty(selectedStoreIds)}
              okText="Confirm"
              onConfirm={handleClick}
              title="Are you sure you want to confirm payouts?"
            >
              <Button
                data-testid="batchPayoutModalBtn"
                disabled={isEmpty(selectedStoreIds)}
                key="submit"
                loading={isModalLoading || isPayoutInProgress}
                name="batchPayOutSelenium"
                type="primary"
              >
              Batch Payout
              </Button>
            </Popconfirm>
          </Space>
        </ModifiedModalFooter>,
      ]}
      keyboard={!isPayoutInProgress}
      maskClosable={!isPayoutInProgress}
      onCancel={hideModal}
      open
      title="Batch Payout"
    >
      <Form data-test="batch-payout-countries-form" layout="vertical">
        <Form.Item label="Country" name="country_id" wrapperCol={{span: 12}}>
          <Select data-testid="countrySelect" onChange={handleCountryChange}>
            {countries.map(({ text, value }) =>
              <Option key={value} name="countryOption" value={value}>{text}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Select period" name="date_range_picker" wrapperCol={{span: 12}}>
          <ModifiedRangePicker onChange={handleDateRangerChange} />
        </Form.Item>
      </Form>

      <Divider data-testid="batchPayoutModal" orientation="left" orientationMargin={0} plain>
        Balance for the selected period: <b>{rangeAmount && !isEmpty(countryStores) ? new Intl.NumberFormat(countryStores[0].country_locales[0], { currency: countryStores[0].currency, style: 'currency' }).format(rangeAmount) : '-'}</b>
      </Divider>

      {isPayoutInProgress &&
          <Alert
            description="The selected stores are being paid out. Please do not close this window until the process is finished."
            showIcon
            style={{ marginBottom: 20, marginTop: 20 }}
            type="warning"
          />
      }

      {isPayoutInProgress &&
        <div style={{ textAlign: 'center' }}>
          <Progress
            data-testid="progressBar"
            percent={progressBarPercent}
            status={progressBarStatus}
          />
        </div>
      }

      {progressBarStatus === 'success' && hasBatchPaymentError &&
        <Alert
          data-testid="beErrorAlert"
          description={<BatchPayoutErrors errors={selectedStoreErrors} stores={countryStores} />}
          message="The following stores could not be paid out because an error occurred."
          showIcon
          style={{ marginBottom: 20, marginTop: 20 }}
          type="error"
        />
      }

      {progressBarStatus === 'success' && !hasBatchPaymentError &&
        <Alert
          message="All selected stores were paid out successfully"
          showIcon
          style={{ marginBottom: 20, marginTop: 20 }}
          type="success"
        />
      }

      {(errorMessage || error) && (
        <Alert
          description={errorMessage || error}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}

      <CustomTable
        className="batchPayoutTableSelenium"
        columns={tableColumns}
        dataSource={countryStores}
        loading={isModalLoading}
        rowClassName={(record) => !!selectedStoreErrors[record.id] ? 'batch-error' : null}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </Modal>
  )
}

const BatchPayoutErrors = ({ errors, stores }) => (
  <ul>
    {Object.keys(errors).map((storeId) => <li key={storeId}><b>{stores.find(store => store.id === storeId).name}:</b> {errors[storeId]}</li>)}
  </ul>
)

BatchPayoutModal.propTypes = {
  countries: PropTypes.array.isRequired,
  hideModal: PropTypes.func.isRequired,
}

BatchPayoutErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  stores: PropTypes.array.isRequired,
}

export default BatchPayoutModal
