import PropTypes from 'prop-types'

export default PropTypes.shape({
  isLoading: PropTypes.bool,
  me: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
  refetch: PropTypes.func,
})
