import React from 'react'
import styled from 'styled-components'
import { EmojiPicker } from '../../../components'
import { Form, Input } from 'antd'
import { SmileOutlined } from '@ant-design/icons'

const { TextArea } = Input

const NotificationStep = () => {
  const form = Form.useFormInstance()

  const addEmojiToInput = (emoji, fieldName) => {
    const cursorPosition = document.getElementById(fieldName).selectionStart
    const regExp = new RegExp(`^(.{${cursorPosition}})(.*)`)
    const currentFieldValue = form.getFieldValue(fieldName) || ''
    const newValue = currentFieldValue.match(regExp).slice(1).join(emoji)

    form.setFieldsValue({ [fieldName]: newValue })

    document.getElementById(fieldName).selectionStart = cursorPosition
  }

  return (
    <>
      <Form.Item
        label="Notification name"
        name="name"
        rules={[
          { message: 'Please provide a notification name!', required: true },
          { max: 124 },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Notification title"
        name="title"
        rules={[{ max: 34 }]}
      >
        <Input
          suffix={
            <EmojiPicker onSelect={(emoji) => addEmojiToInput(emoji.native, 'title')}>
              <SmileOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </EmojiPicker>
          }
        />
      </Form.Item>

      <RelativeDiv>
        <Form.Item
          label="Notification text"
          max={245}
          name="body"
          rules={[
            { message: 'Please add text!', required: true },
            { max: 245 },
          ]}
        >
          <TextArea style={{ height: 200 }} />
        </Form.Item>

        <EmojiPicker onSelect={(emoji) => addEmojiToInput(emoji.native, 'body')}>
          <AbsoluteEmojiIcon style={{ color: 'rgba(0,0,0,.45)' }} />
        </EmojiPicker>
      </RelativeDiv>
    </>
  )
}

const RelativeDiv = styled.div`
  position: relative;
`

const AbsoluteEmojiIcon = styled(SmileOutlined)`
  position: absolute;
  top: 40px;
  right: 10px;
`

export default NotificationStep
