import instance from '../../api'
import apiExportResponseHandler from '../../api-utils/apiExportResponseHandler'

const BASE_ENDPOINT = '/api/admin/withdrawals'

export const cancelWithdrawal = (id) => instance.put(`${BASE_ENDPOINT}/${id}/cancel`)

// Unused
export const createWithdrawal = (fields) => instance.post(BASE_ENDPOINT, fields)

export const getBankData = (id) => instance.get(`${BASE_ENDPOINT}/${id}/bank_data`)

export const getWithdrawal = (id) => instance.get(`${BASE_ENDPOINT}/${id}`)

export const getWithdrawalHistory = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const payoutWithdrawal = (id) => instance.put(`${BASE_ENDPOINT}/${id}/payout`)

export const payoutBalance = (data) => instance.post(`${BASE_ENDPOINT}/payout_balance`, data)

export const exportWithdrawals = (filter) => (
  fetch(`${BASE_ENDPOINT}/export${filter ? `?${filter}` : ''}`,
    {
      headers: {
        'content-type': 'text/csv;charset=UTF-8',
      },
    })
    .then(apiExportResponseHandler)
)
