import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import { ModifiedModalFooter } from '../../elements'
import { Col, Flex, Form, Button, Image, Input, Modal, notification, Row, Spin, Tag, Timeline } from 'antd'
import { handleBackendError, localizedDate } from '../../utils'
import { CustomTable } from '../../components'
import assets from '../../../../assets/images'
import { getScan, setScanStatusNote } from './api'

const FieldGroup = styled.div`
  margin: 20px 0;
`

const Label = styled.span`
  margin: 3px 0;

  font-weight: bold;
`
const Hint = styled.span`
  margin: 3px;

  font-style: italic;
`

const FieldValue = styled.div`
  margin: 3px 0;

  text-transform: capitalize;
`

export const ScanDetails = ({
  hideDetails,
  selectedScan,
}) => {
  const fields = getScanDetails(selectedScan)
  const [items, setItems] = useState()
  const [submitCount, setSubmitCount] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [notes, setNotes] = useState(selectedScan.notes)

  const onFinish = (values) => {
    form
      .validateFields()
      .then(async() => {
        const newNote = {
          ...values,
          scan_id: selectedScan.id,
        }
        await setScanStatusNote(newNote)
          .then(async () => {
            await setNotes(newNote)
            notification.success({
              description: 'Note added successfully!',
              duration: 3,
              message: 'Success',
            })
          })

        await setSubmitCount((count) => _.add(count, 1))
        form.resetFields()
      })
      .catch((error) => {
        notification.error({
          description: handleBackendError(error, 'Note adding failed!'),
          message: 'Error',
        })
      })
  }

  useEffect(() => {
    setLoading(true)
    getScan(selectedScan.id)
      .then(scan => {
        setNotes(scan.data.data.notes)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        notification.error({
          description: handleBackendError(error, 'Fecthing scan failed!'),
          message: 'Error',
        })
      })
  }, [selectedScan, form, submitCount])

  useEffect(() => {
    setLoading(true)
    getScan(selectedScan.id)
      .then(scan => {
        setItems(scan.data.data.return_items)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)

        notification.error({
          description: handleBackendError(error, 'Fecthing scan failed!'),
          message: 'Error',
        })
      })
  }, [selectedScan, form])

  const tableColumns = [
    {
      className: 'itemNameSelenium',
      key: 'name',
      render: (_, { name }) => (
        <span>{name}</span>
      ),
      title: 'Name',
    },
    {
      className: 'itemConditionSelenium',
      key: 'condition',
      render: (_, { condition }) => (
        <span>{condition}</span>
      ),
      title: 'Condition',
    },
    {
      className: 'itemPriceSelenium',
      key: 'price_value',
      render: (_, { price_value }) => (
        <Row justify="center">
          <span>{price_value}</span>
        </Row>
      ),
      title: 'Price',
    },
    {
      className: 'itemCurrencySelenium',
      key: 'price_currency',
      render: (_, { price_currency }) => (
        <Row justify="center">
          <span>{price_currency}</span>
        </Row>
      ),
      title: 'Currency',
    },
    {
      className: 'itemImageSelenium',
      key: 'item_image',
      render: (_, { image_url }) => (
        <Row justify="center">
          <Image
            fallback={assets.fallbackImage}
            src={image_url}
            width={50}
          />
        </Row>
      ),
      title: 'Image',
    },
    {
      className: 'itemQuantitySelenium',
      key: 'quantity',
      render: (_, { quantity }) => (
        <Row justify="center">
          <span>{quantity}</span>
        </Row>
      ),
      title: 'Quantity',
    },
  ]

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <Button data-testid="cancelModalBtn" key="back" name="cancelDetailsModalBtnSelenium" onClick={hideDetails}>
            Cancel
          </Button>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideDetails}
      open
      title={'Scan Details'}
      width="60%"
    >
      <Col>
        <Flex>
          <Col>
            {fields.map(field => (
              <FieldGroup key={field.name} name={field.name}>
                <Label>{field.label}</Label>
                {field.labelHint &&
                  <Hint>{field.labelHint}</Hint>
                }
                <FieldValue>{field.value}</FieldValue>
              </FieldGroup>
            ))}
          </Col>
          <Col offset={1}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              requiredMark={<Tag>(not visible for the customer)</Tag>}
            >
              <Form.Item
                label={
                  <span style={{ fontWeight: 'bold', marginTop: '20px' }}>
                    Notes &nbsp;
                    <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>(not visible for the customer)</span>
                  </span>
                }
                name="note"
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item className="text-right">
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            {isLoading ? <Spin /> : (
              notes && Object.keys(notes).length > 0 && (
                <Timeline>
                  {Object.entries(notes).map(([date, note]) => (
                    <Timeline.Item key={date}>
                      <h4>{date}</h4>
                      <p>Note: {note.note}</p>
                      <p>User: {note.admin}</p>
                    </Timeline.Item>
                  ))}
                </Timeline>
              ))}
          </Col>
        </Flex>

        <CustomTable
          className="scanPageDetailsTableSelenium"
          columns={tableColumns}
          dataSource={items}
          loading={isLoading}
        />
      </Col>
    </Modal>
  )
}

ScanDetails.propTypes = {
  hideDetails: PropTypes.func.isRequired,
  selectedScan: PropTypes.object,
}

const getScanDetails = ({ id, user_id, status, user, created_at, finalized_at, shipping_container_link, qr_code, amount, comment, scan_type, cancellation_reason }) => {
  const details = [
    { label: 'Scan id', name: 'ScanIdDetailSelenium', value: id },
    { label: 'User id', name: 'userIdDetailSelenium', value: user_id },
    { label: 'Status', name: 'scanStatusDetailSelenium', value: status },
    { hidden: !cancellation_reason, label: 'Cancellation Reason', labelHint: '(not visible for the customer)', name: 'cancellationReasonSelenium', value: cancellation_reason && cancellation_reason.replace(/_/g, ' ') },
    { label: 'Country', name: 'countryDetailSelenium', value: user.country_name },
    { label: 'Created at', name: 'createdAtDetailSelenium', value: localizedDate(created_at) },
    { label: 'Finalized at', name: 'finalizedAtDetailSelenium', value: localizedDate(finalized_at) },
    { label: 'QR Code', name: 'qrCodeDetailSelenium', value: shipping_container_link ? <a href={shipping_container_link} rel="noreferrer" target="_blank">{qr_code}</a> : qr_code},
    { label: 'Amount', name: 'amountDetailSelenium', value: new Intl.NumberFormat(user.country_locales[0], { currency: user.currency_symbol, style: 'currency' }).format(amount) },
    { hidden: !comment, label: 'Admin Comment', labelHint: '(visible for the customer)', name: 'commentSelenium', value: comment},
    { label: 'Scan Mode', name: 'scanModeSelenium', value: scan_type },
  ]

  return details.filter(detail => !detail.hidden)
}

export default ScanDetails
