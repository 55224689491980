const ca = {
  accountNumber: 'Account Number',
  accountType: 'Account type',
  addContactName: 'Please make sure the full name is entered',
  addEmail: 'Your email address format is invalid',
  addIban: 'Please make sure your IBAN number is correct',
  addPhone: 'Invalid phone number',
  addressLine: 'Address Line',
  addZip: 'Please enter your postcode',
  bankDetails: 'BANK DETAILS',
  city: 'City',
  contactDetails: 'CONTACT',
  contactName: 'Full Name',
  country: 'Country',
  email: 'Email Address',
  errorPageSubtitle: 'For more information, please get in touch with your Loop Retail Partnerships contact.',
  errorPageTitle: 'This page does not exist.',
  institutionNumber: 'Institution Number',
  legalEntity: 'Legal Entity',
  phoneNumber: 'Phone Number',
  sendIn: 'Send in',
  state: 'Municipality',
  storeDetails: 'STORE DETAILS',
  storeName: 'Store Name',
  submit: 'Send in',
  thankYouSubTitle: 'Thank you',
  thankYouTitle: 'The form has been successfully sent.',
  title: 'Invoicing Form',
  transitNumber: 'Transit Number',
  zipCode: 'Postcode',
}

const fr = {
  addContactName: 'Veuillez saisir vos noms et prénoms',
  addEmail: 'Le format de votre adresse e-mail n\'est pas valide',
  addIban: 'Le numéro IBAN est incorrect',
  addPhone: 'Numéro de téléphone invalide',
  addressLine: 'Adresse',
  addZip: 'Veuillez entrer votre code postal',
  bankDetails: 'COORDONNÉES BANCAIRES',
  cancel: 'Cancel',
  city: 'Ville',
  contactDetails: 'CONTACT',
  contactName: 'Nom Prénom',
  country: 'Pays',
  email: 'Adresse email',
  errorPageSubtitle: 'Pour plus d’informations, veuillez contacter votre interlocuteur Loop pour plus d’informations.',
  errorPageTitle: 'Cette page n’existe pas. ',
  iban: 'IBAN',
  legalEntity: 'Raison Sociale',
  phoneNumber: 'Numéro de téléphone',
  sendIn: 'Send in',
  storeDetails: 'MAGASIN',
  storeName: 'Nom du magasin',
  submit: 'Enregister',
  thankYouSubTitle: 'Merci !',
  thankYouTitle: 'Votre formulaire a été envoyé',
  title: 'Formulaire de facturation',
  zipCode: 'Code postal',
}

const jp = {
  accountNumber: '口座番号',
  accountType: '口座種別',
  addAccountNumber: '口座番号を入力してください',
  addAccountType: '口座種別',
  addBankCode: '銀行コードが無効です',
  addBranchCode: '銀行支店コードが無効です',
  addContactName: '氏名を入力してください',
  addEmail: 'メールアドレスが無効です',
  addPhone: '電話番号が無効です',
  addressLine: '番地・建物名・部屋番号',
  addZip: '郵便番号を入力してください',
  bankCode: '銀行名',
  bankDetails: '口座情報',
  branchCode: '銀行支店名',
  cancel: 'Cancel',
  checking: '当座',
  city: '市町村',
  contactDetails: '連絡先',
  contactName: '氏名',
  country: '国名',
  current: '普通',
  email: 'メールアドレス',
  errorPageSubtitle: 'For more information, please get in touch with your Loop Retail Partnerships contact.',
  errorPageTitle: 'This page does not exist.',
  legalEntity: '会社名',
  phoneNumber: '電話番号',
  savings: '貯蓄',
  sendIn: '送信',
  state: '都道府県',
  storeDetails: '店舗情報',
  storeName: '店舗名',
  submit: '送信',
  thankYouSubTitle: 'Thank you',
  thankYouTitle: 'The form has been successfully sent.',
  title: '登録フォーム',
  zipCode: '郵便番号',
}

const uk = {
  accountNumber: 'Account Number',
  addContactName: 'Please make sure the full name is entered',
  addEmail: 'Your email address format is invalid',
  addIban: 'Please make sure your IBAN number is correct',
  addPhone: 'Invalid phone number',
  addressLine: 'Address Line',
  addZip: 'Please enter your postcode',
  bankDetails: 'BANK DETAILS',
  city: 'City',
  contactDetails: 'CONTACT',
  contactName: 'Full Name',
  country: 'Country',
  email: 'Email Address',
  errorPageSubtitle: 'For more information, please get in touch with your Loop Retail Partnerships contact.',
  errorPageTitle: 'This page does not exist.',
  legalEntity: 'Legal Entity',
  phoneNumber: 'Phone Number',
  sendIn: 'Send in',
  sortCode: 'Sort Code',
  state: 'Locality (if relevant)',
  storeDetails: 'STORE DETAILS',
  storeName: 'Store Name',
  submit: 'Send in',
  thankYouSubTitle: 'Thank you',
  thankYouTitle: 'The form has been successfully sent.',
  title: 'Invoicing Form',
  zipCode: 'Postcode',
}

const us = {
  abartn: 'ACH Routing Number',
  accountNumber: 'Account Number',
  accountType: 'Account type',
  addAccountNumber: 'Please enter your account number',
  addAccountType: 'Please enter your account type',
  addBankCode: 'Please enter a valid bank code',
  addBranchCode: 'Please enter a valid branch code',
  addContactName: 'Please make sure the full name is entered',
  addEmail: 'Your email address format is invalid',
  addIban: 'Please provide an IBAN number!',
  addPhone: 'Invalid phone number',
  address: 'Address',
  addressLine: 'Address Line',
  addZip: 'Please enter your postcode',
  bankCode: 'Bank code',
  bankDetails: 'BANK DETAILS',
  branchCode: 'Branch code',
  cancel: 'Cancel',
  city: 'City',
  contactDetails: 'CONTACT',
  contactName: 'Full Name',
  country: 'Country',
  email: 'Email Address',
  errorPageSubtitle: 'Pour plus d’informations, veuillez contacter votre interlocuteur Loop pour plus d’informations. \n For more information, please get in touch with your Loop Retail Partnerships contact.',
  errorPageTitle: 'Cette page n’existe pas. \n This page does not exist.',
  iban: 'IBAN',
  institutionNumber: 'Institution Number',
  invoicingForm: 'Invoicing Form',
  legalEntity: 'Legal Entity',
  phoneNumber: 'Phone Number',
  postCode: 'Post code',
  sendIn: 'Send in',
  sortCode: 'Sort Code',
  state: 'State',
  storeDetails: 'STORE DETAILS',
  storeName: 'Store Name',
  submit: 'Submit',
  thankYouSubTitle: 'Thank you',
  thankYouTitle: 'The form has been successfully sent.',
  title: 'Invoicing Form',
  transitNumber: 'Transit Number',
  zipCode: 'Zip code',
}

const languages = {
  ca, fr, jp, uk, us,
}

export default languages
