import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ConfigContext } from '../../'
import instance from '../../../api'

class ConfigProvider extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      config: null,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.refetchConfig()
  }

  refetchConfig = () => {
    instance.get('/api/admin/configs')
      .then(({ data }) => {
        this.setState({
          config: data.data,
          isLoading: false,
        })
      })
      .catch(() => {
        this.setState({
          config: null,
          isLoading: false,
        })
      })
  }

  render() {
    const { children } = this.props
    const { isLoading, config } = this.state
    const value = {
      config: config || null,
      isLoading,
      refetch: this.refetchConfig,
    }

    return (
      <ConfigContext.Provider value={value}>
        {children}
      </ConfigContext.Provider>
    )
  }
}

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ConfigProvider
