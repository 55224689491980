import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Flex,
  Menu,
  notification,
  Popconfirm,
  Radio,
  Row,
  Tooltip,
} from 'antd'
import { filter, isEmpty, maxBy } from 'lodash'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Can,
  CustomTable,
  DragAndDrop,
  DragButton,
  Icon,
  PageContainer,
  PageTitleRow,
} from '../../components'
import { handleBackendError, useCustomSearchParams } from '../../utils'
import { IconContainer } from '../../elements'
import { getCountryList } from '../country-management/api'
import FaqCategoryModal from './FaqCategoryModal'
import {
  createFaqCategories,
  deleteFaqCategories,
  getFaqCategories,
  updateFaqCategories,
} from './api'

const FaqCategories = () => {
  const [searchParams] = useSearchParams()
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [faqCategories, setFaqCategories] = useState([])
  const [countries, setCountries] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const items = countries.map(({ id, name }) => (
    {
      key: id,
      label: (<span>{name}</span>),
    }
  ))

  const [search, setSearch] = useCustomSearchParams()

  useEffect(() => {
    getCountryList()
      .then(({ data }) => setCountries(data.data))
      .catch((error) => handleFailure(error, 'Fetching countries failed!'))
  }, [])

  useEffect(() => {
    if (isEmpty(search) && !isEmpty(countries)) {
      setSearch({ category_type: 'regular', country_id: filter(countries, { name: 'United States' })[0].id })
    }
  }, [countries])

  useEffect(() => {
    if (!isEmpty(searchParams.toString()))
      fetchFaqCategories(searchParams.toString())
  }, [searchParams])

  const fetchFaqCategories = (params) => {
    setIsLoading(true)

    getFaqCategories(params)
      .then(({ data }) => {
        setFaqCategories(data.data)

        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        handleFailure(error, 'Fetching categories is failed!')
      })
  }

  const tableColumns = [
    {
      align: 'center',
      className: 'positionSelenium',
      dataIndex: 'position',
      key: 'position',
      render: () => <DragButton />,
      width: 80,
    },
    {
      className: 'categoryTitleSelenium',
      dataIndex: 'categoryTitle',
      key: 'categoryTitle',
      render: (_, record) => (
        <p>{record.title}</p>
      ),
      title: 'Category title',
    },
    {
      className: 'categoryActionSelenium',
      key: 'action',
      render: (_, record) => (
        <IconContainer>
          <Can
            no={() => (
              <Tooltip title="View">
                <Icon
                  data-testid="viewBtn"
                  name="updateFaqCategoriesSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="eye"
                />
              </Tooltip>
            )}
            requiredPermission="faq_category.put.faq_category_id"
            yes={() => (
              <Tooltip title="Edit">
                <Icon
                  data-testid="editBtn"
                  name="updateFaqCategoriesSelenium"
                  onClick={() => handleOpenModal(record)}
                  type="edit"
                />
              </Tooltip>
            )}
          />
          <Can
            requiredPermission="faq_category.delete.faq_category_id"
            yes={() => (
              <Tooltip title="Delete">
                <Popconfirm
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => handleDeleteFaqCategories(record.id)}
                  title="Are you sure you want to delete this category?"
                >
                  <Icon data-testid="deleteBtn" name="deleteFaqCategorySelenium" type="delete" />
                </Popconfirm>
              </Tooltip>
            )}
          />
        </IconContainer>
      ),
      title: 'Actions',
      width: '10%',
    },
  ]

  const handleClickCountry = ({ key }) => {
    setSearch({ ...search, country_id: key })
  }

  const onTypeChange = (event) => {
    setSearch({ ...search, category_type: event.target.value, items_per_page: 25, page: 1 })
  }

  const handleDeleteFaqCategories = (recordId) => {
    deleteFaqCategories(recordId)
      .then(() => handleSuccess({ description: 'Category removed' }))
      .catch((error) => handleFailure(error, 'Category removal failed!'))
  }

  const handleFormSubmit = (formData, id) => (
    (id)
      ? (
        updateFaqCategories(formData, id)
          .then(() => handleSuccess({ description: 'Category updated' }))
          .catch((error) => handleFailure(error, 'Category update failed!'))
      )
      : (
        createFaqCategories(formData)
          .then(() => handleSuccess({ description: 'Category created' }))
          .catch((error) => handleFailure(error, 'Category creation failed!'))
      )
  )

  const handleOpenModal = (selectedCategory) => {
    setSelectedCategory(selectedCategory)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleSuccess = (messageOptions) => {
    fetchFaqCategories(searchParams.toString())
    setErrorMessage(null)
    setModalVisibility(false)

    notification.success({
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, message) => {
    if (error) {
      console.log(error)
    }

    const errorMessage = handleBackendError(error, message)

    setErrorMessage(errorMessage)
    setModalLoading(false)

    notification.error({
      description: errorMessage,
      message: 'Error',
    })
  }

  const onDragEnd = ({ active, over }) => {
    if (active.id === over?.id) return false

    setIsLoading(true)

    updateFaqCategories({ position: over.data.current.position }, active.id)
      .then((error) => fetchFaqCategories(searchParams.toString()))
      .catch((error) => handleFailure(error, 'Category update failed!'))
  }

  return (
    <PageContainer>
      <PageTitleRow
        title="FAQ Category"
        titleChildren={
          <Flex
            gap="middle"
            vertical
          >
            <b>FAQ Category Type</b>
            <Radio.Group buttonStyle="solid" defaultValue={search.category_type || 'regular'} onChange={onTypeChange}>
              <Radio.Button value="regular">Regular</Radio.Button>
              <Radio.Button value="corporate">Corporate</Radio.Button>
            </Radio.Group>
          </Flex>
        }
      >
        <Col
          style={{
            alignItems: 'center',
            color:'#1890ff',
            display: 'flex',
            fontWeight: 'bold',
            marginBottom: '15px',
          }}
        >
          <Link to="/FAQs">Back to the FAQ page</Link>
          <Icon type="arrowRight" />
        </Col>
      </PageTitleRow>

      <Row>
        <Col span={20}>
          {countries &&
            <Menu data-testid="categoryMenu" items={items} mode="horizontal" onClick={handleClickCountry} selectedKeys={[search.country_id]} />
          }
        </Col>

        <Col span={4} style={{ borderBottom: '1px solid #f0f0f0', textAlign:'right' }}>
          <Col>
            <Can
              requiredPermission="faq_category.post.create"
              yes={() => (
                <Button
                  data-testid="addNewCategorySelenium"
                  name="addNewCategorySelenium"
                  onClick={() => handleOpenModal(null)}
                  type="primary"
                >
                  New category
                </Button>
              )}
            />
          </Col>
        </Col>

        <Col span={24}>
          <DragAndDrop items={faqCategories.map(({ id }) => id)} onDragEnd={onDragEnd}>
            <CustomTable
              className="faqPageTableSelenium"
              columns={tableColumns}
              dataSource={faqCategories}
              loading={isLoading}
              rowKey="id"
            />
          </DragAndDrop>

          {isModalVisible && (
            <FaqCategoryModal
              countries={countries}
              errorMessage={errorMessage}
              hideModal={() => setModalVisibility(false)}
              highestPosition={isEmpty(faqCategories) ? 0 : maxBy(faqCategories, 'position').position}
              isModalLoading={isModalLoading}
              onSubmit={handleFormSubmit}
              selectedCategory={selectedCategory}
              selectedCountryId={search.country_id}
              selectedFaqCategoryType={search.category_type}
              setModalLoading={setModalLoading}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  )
}

export default FaqCategories
