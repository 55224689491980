import React from 'react'
import { useNavigate } from 'react-router-dom'
import { notification } from 'antd'
import { Auth, withProfile } from '../../modules'
import { userPropTypes } from '../../types'

export const LogoutPage = ({ user }) => {
  if (user?.isLoading) {
    return null
  }
  if (user?.me && !user.isLoading) {
    return <AuthSignOut user={user} />
  }
}

LogoutPage.propTypes = {
  user: userPropTypes,
}

export const AuthSignOut = ({ user }) => {
  let navigate = useNavigate()

  Auth.signOut()
    .then(() => {
      Auth.removeEmail()

      user.refetch()

      navigate('/')

      notification.success({
        description: 'Logged out',
        message: 'Success',
      })
    })

  return null
}

AuthSignOut.propTypes = {
  user: userPropTypes,
}

export default withProfile(LogoutPage)
