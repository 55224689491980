import instance from '../../api'

const BASE_ENDPOINT = '/api/admin/bin_types'

export const getBinTypes = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const updateBinType = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.id}`, fields)

export const deleteBinType = (id) => instance.delete(`${BASE_ENDPOINT}/${id}`)

export const createBinType = (fields) => instance.post(BASE_ENDPOINT, fields)
