import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ConfigProvider, UserProvider } from './modules'

const basename = (window.location.host.split('.')[0] === 'admin') ? '/' : '/admin'

const Root = () => (
  <BrowserRouter basename={basename}>
    <UserProvider>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </UserProvider>
  </BrowserRouter>
)

export default Root
