import instance from '../../api'
import apiExportResponseHandler from '../../api-utils/apiExportResponseHandler'

const BASE_ENDPOINT = '/api/admin/scans'

export const getScanList = (search) => instance.get(`${BASE_ENDPOINT}${search ? `?${search}` : ''}`)

export const getScan = (id) => instance.get(`${BASE_ENDPOINT}/${id}`)

export const createScan = (form) => instance.post(BASE_ENDPOINT, {...form})

export const exportScans = (type, filter) => (
  fetch(`${BASE_ENDPOINT}/${type}${filter ? `?${filter}` : ''}`,
    {
      headers: {
        'content-type': 'text/csv;charset=UTF-8',
      },
    })
    .then(apiExportResponseHandler)
)

export const setScanStatusToCanceled = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.scan_id}/cancel`, fields)

export const setScanStatusNote = (fields) => instance.put(`${BASE_ENDPOINT}/${fields.scan_id}/note`, fields)
