import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Col, Form, Input, notification, Typography } from 'antd'
import { handleBackendError } from '../../utils'
import { CaForm, EuForm, JpForm, UkForm, UsForm } from '../../components/bank-details-modal/countries'
import { getStoreFromParam, updateStoreFromParam } from './api'
import { translations } from '../../constants'
import StoreCreateErrorPage from './error-page/StoreCreateErrorPage'
import ThankYouPage from './thank-you-page/ThankYouPage'
import logo from '../../../../assets/images/loop-logo.jpeg'

const { Text, Title } = Typography

export const CreateNewStore = () => {
  const [form] = Form.useForm()
  const [language, setLanguage] = useState('us')
  const [storeInfo, setStoreInfo] = useState({})
  const [isFormFinished, setIsFormFinished] = useState(false)
  const [showErrorPage, setShowErrorPage] = useState(false)

  const isStateVisible = language !== 'fr'

  let { id } = useParams()

  useEffect(() => {
    getStoreFromParam(id)
      .then(({ data }) => {
        const { country_code } = data
        setStoreInfo(data)
        setLanguage(country_code)
      })
      .catch((error) => {
        handleFailure()
        setShowErrorPage(true)
      })
  }, [])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, storeInfo])

  const handleFailure = (error, message) => {
    console.log(error)
  }

  const handleSubmit = ({ requiredFieldsValue, setModalLoading }) => {
    form
      .validateFields()
      .then(() => {
        setModalLoading(true)

        const formData = Object.assign(
          {},
          form.getFieldsValue(),
          Object.assign({ payment_method: requiredFieldsValue }),
        )

        updateStoreFromParam(id, formData)
          .then(() => {
            setIsFormFinished(true)}
          )
          .catch((error) => {
            const errorMessage = handleBackendError(error, 'Error')

            notification.error({
              description: errorMessage,
              message: 'Error',
            })
            setModalLoading(false)
          })
      })
      .catch(() => {
        window.scrollTo(0, 0)
        setModalLoading(false)
      })
  }

  const formProps = {
    handleFailure: handleFailure,
    isFormNeedPaymentMethods: false,
    locale: language,
    onSubmit: handleSubmit,
    selectedStore: storeInfo,
  }

  const countriesFormData = {
    ca: <CaForm {...formProps} />,
    fr: <EuForm {...formProps} />,
    jp: <JpForm {...formProps} />,
    uk: <UkForm {...formProps} />,
    us: <UsForm {...formProps} />,
  }

  if (showErrorPage)
    return <StoreCreateErrorPage language={language} />

  if (isFormFinished)
    return <ThankYouPage language={language} />

  const initialValues = {
    address_line: storeInfo?.address_line,
    city: storeInfo?.city,
    contact_name: storeInfo?.contact_name,
    contact_phone: storeInfo?.contact_phone,
    legal_entity: storeInfo?.legal_entity,
    reporting_email: storeInfo?.reporting_email,
    state: storeInfo?.state,
    zip_code: storeInfo?.zip_code,
  }

  return (
    <>
      <Col className="site-layout-content site-layout-background" sm={14} style={{ textAlign: 'center' }} xs={24}>
        <img
          alt="Loop logo"
          name="homepageLogoSelenium"
          src={logo}
          style={{ height: '115px' }}
        />
      </Col>
      <Col className="site-layout-content site-layout-background" sm={14} xs={24}>
        <Title className="center" level={3}>
          {translations[language].title}
        </Title>
      </Col>
      <Col className="site-layout-content site-layout-background" sm={14} xs={24}>
        <CustomTitle level={4}>
          {translations[language].contactDetails}
        </CustomTitle>
        <Form
          form={form}
          initialValues={storeInfo}
          labelCol={{ span: 16 }}
          layout="vertical"
          name="basic"
        >
          <Form.Item
            label={translations[language].contactName}
            name="contact_name"
            rules={[{
              message: translations[language].addContactName,
              required: true,
            }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translations[language].phoneNumber}
            name="contact_phone"
            rules={[{
              message: translations[language].addPhone,
              pattern: new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im),
              required: true,
            }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translations[language].email}
            name="reporting_email"
            rules={[{ message: translations[language].addEmail, required: true, type: 'email' }]}
          >
            <Input />
          </Form.Item>
          <CustomTitle level={4}>
            {translations[language].storeDetails}
          </CustomTitle>

          <Text style={{display: 'block', marginBottom: '8px'}}>
            {translations[language].storeName}
          </Text>

          <Text strong style={{display: 'block', marginBottom: '15px'}}>{storeInfo?.name}</Text>

          <Form.Item
            label={translations[language].legalEntity}
            name="legal_entity"
            rules={[{ message: 'Please enter the Legal Entity!', required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translations[language].country}
            name="country"
          >
            <p><b>{storeInfo?.country_name}</b></p>
          </Form.Item>

          {isStateVisible && (
            <Form.Item
              label={translations[language].state}
              name="state"
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label={translations[language].city}
            name="city"
            rules={[{ message: 'Please enter your city!', required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translations[language].zipCode}
            name="zip_code"
            rules={[{ message: translations[language].addZip, required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={translations[language].addressLine}
            name="address_line"
            rules={[{ message: 'Please enter your address line', required: true }]}
          >
            <Input />
          </Form.Item>
          <CustomTitle level={4}>
            {translations[language].bankDetails}
          </CustomTitle>
        </Form>
        {countriesFormData[language]}
      </Col>
    </>
  )
}

const CustomTitle = styled(Title)`
  &.ant-typography {
    color: #1890ff !important;
    text-transform: uppercase !important;
  }
`

export default CreateNewStore
