import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Popover } from 'antd'

const EmojiPicker = ({ children, onSelect }) => {
  const [open, setOpen] = useState(false)

  return (
    <StyledPopover
      content={<Picker data={data} onEmojiSelect={onSelect} />}
      onOpenChange={() => setOpen(!open)}
      open={open}
      overlayClassName="emoji-picker"
      title={null}
      trigger="click"
    >
      {children}
    </StyledPopover>
  )
}

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0;
    background: transparent;
  }
`

EmojiPicker.propTypes = {
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default EmojiPicker
