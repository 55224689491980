import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Can } from '../'

const ModalDeleteButton = ({ confirmationText, isUpdate, name, onDelete, requiredPermission }) => {
  if (!isUpdate) return null

  return (
    <Can
      key="delete"
      requiredPermission={requiredPermission}
      yes={() => (
        <Popconfirm
          cancelText="No"
          className="action-list-button"
          okText="Yes"
          onConfirm={onDelete}
          title={confirmationText}
        >
          <Button
            danger
            data-testid="deleteBtn"
            icon={<DeleteOutlined />}
            name={name}
            style={{ float: 'left' }}
          >
            Delete
          </Button>
        </Popconfirm>
      )}
    />
  )
}

ModalDeleteButton.propTypes = {
  confirmationText: PropTypes.string,
  isUpdate: PropTypes.bool,
  name: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  requiredPermission: PropTypes.string.isRequired,
}

export default ModalDeleteButton
