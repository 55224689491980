import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const TopMenu = ({ userEmail }) => {
  const items = [
    {
      children: [
        {
          key: 'changePassword',
          label: (<Link to="/change-password">Change Password</Link>),
        },
        {
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'logout',
          label: (<Link to="/logout">Logout</Link>),
        },
      ],
      icon: <UserOutlined />,
      key: 'userMenu',
      label: <span>{userEmail}</span>,
      style: {
        marginLeft: 'auto',
      },
    },
  ]

  return (
    <Menu items={items} mode="horizontal" theme="dark" />
  )
}

TopMenu.propTypes = {
  userEmail: PropTypes.string,
}

export default TopMenu
