import React from 'react'
import PropTypes from 'prop-types'

import { Form, Select } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const { Option } = Select

const TargetStep = ({
  countries,
  getPotentialUsers,
  isLoading,
  onCountryChange,
  potentialUsers,
  targetItemList,
}) => (
  <>
    <Form.Item label="Country" name="country_id" rules={[{ message: 'Please select a country!', required: true }]}>
      <Select
        loading={isLoading.countryList}
        onChange={onCountryChange}
      >
        {React.Children.toArray(countries.map((country) =>
          <Option key={country.id} name="targetCountryOption" value={country.id}>{country.name}</Option>,
        ))}
      </Select>
    </Form.Item>

    <Form.Item label="Target user if..." name="target_skus">
      <Select
        loading={isLoading.targetItemList}
        mode="multiple"
        onChange={getPotentialUsers}
        optionFilterProp="label"
        optionLabelProp="label"
        placeholder="select products"
        style={{ width: '100%' }}
      >
        {React.Children.toArray(targetItemList.map(({ name, sku }) =>
          <Option label={name} name="targetItemOption" value={sku}>{`${name} (${sku})`}</Option>,
        ))}
      </Select>
    </Form.Item>

    <Form.Item label="User ZIP code start with..." name="zip_codes">
      <Select
        mode="tags"
        onChange={getPotentialUsers}
        optionFilterProp="label"
        optionLabelProp="label"
        placeholder="type ZIP code(s)"
        style={{ width: '100%' }}
      >

      </Select>
    </Form.Item>

    <Form.Item label="User type" name="user_type">
      <Select onChange={getPotentialUsers}>
        <Option name="userType" value="regular_and_corporate">Regular and corporate</Option>,
        <Option name="userType" value="regular">Regular</Option>,
        <Option name="userType" value="corporate">Corporate</Option>,
      </Select>
    </Form.Item>

    <p style={{ opacity: isLoading.potentialUsers ? 0.1 : 1 }}>
      {potentialUsers?.percentage_in_selected_country}% of potential users are eligible for this campaign: <b>{potentialUsers?.count_of_notified_users}<UserOutlined /></b>
    </p>
  </>
)

TargetStep.propTypes = {
  countries: PropTypes.array,
  getPotentialUsers: PropTypes.func,
  isLoading: PropTypes.object,
  onCountryChange: PropTypes.func,
  potentialUsers: PropTypes.object,
  targetItemList: PropTypes.array,
}

export default TargetStep
