import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd'
import { isEmpty } from 'lodash'
import { CustomTable } from '../../components'
import { ModifiedModalFooter } from '../../elements'
import { localizedDate } from '../../utils'

const FieldGroup = styled.div`
  margin: 20px 0;
`

const Label = styled.div`
  margin: 3px 0;

  font-weight: bold;
`

const FieldValue = styled.div`
  margin: 3px 0;

  text-transform: capitalize;
`

export const WithdrawalInfoModal = ({
  hideModal,
  selectedWithdrawal,
}) => {
  const [fields, setFields]= useState([])

  useEffect(() => {
    setFields(getWithdrawDetails(selectedWithdrawal))

  }, [selectedWithdrawal])

  const tableColumns = [
    {
      className: 'adjustmentUsersNameSelenium',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (_, record) => <Link target="_blank" to={`/users?items_per_page=25&page=1&sort_by=created_at+desc&id=${record.user_id}`}>{record.user_name}</Link>,
      title: 'Name',
    },
    {
      className: 'adjustmentUsersAmountSelenium',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => new Intl.NumberFormat(selectedWithdrawal.locale, { currency: selectedWithdrawal.currency_symbol, style: 'currency' }).format(record.amount),
      title: 'Amount',
    },
  ]

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <Button data-testid="cancelInfoModalBtnSelenium" key="back" name="cancelInfoModalBtnSelenium" onClick={hideModal}>
            Cancel
          </Button>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      open
      title="Withdrawal details"
    >
      <div>
        {fields.map(({ label, name, value }) => (
          value
            ? (
              <FieldGroup key={name} name={name}>
                <Label>{label}</Label>
                <FieldValue>{value}</FieldValue>
              </FieldGroup>
            )
            : null
        ))}
      </div>

      {!isEmpty(selectedWithdrawal.adjustments) &&
        <div>
          <FieldGroup name="adjustments">
            <Label>Corporate Users</Label>
            <FieldValue></FieldValue>
          </FieldGroup>

          <CustomTable
            className="usersTransactionHistoryTableSelenium"
            columns={tableColumns}
            dataSource={selectedWithdrawal.adjustments}
          />
        </div>
      }
    </Modal>
  )
}

WithdrawalInfoModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  selectedWithdrawal: PropTypes.object,
}

const getWithdrawDetails = (record) => ([
  { label: 'Withdrawal id', name: 'withdrawalIdDetailSelenium', value: record.id },
  { label: 'User id', name: 'userIdDetailSelenium', value: record.user_id },
  { label: 'Store id', name: 'storeIdDetailSelenium', value: record.store_id },
  { label: 'Withdrawal type', name: 'typeDetailSelenium', value: record.type.replace('PaymentMethods::', '') },
  { label: 'Status', name: 'statusDetailSelenium', value: record.status },
  { label: 'Country', name: 'countryDetailSelenium', value: record.country_name },
  { label: 'Created at', name: 'createdAtDetailSelenium', value: localizedDate(record.created_at) },
  { label: 'Finalized at', name: 'finalizedAtDetailSelenium', value: localizedDate(record.finalized_at) },
  { label: 'Amount', name: 'amountDetailSelenium', value: new Intl.NumberFormat(record.locale, { currency: record.currency_symbol, style: 'currency' }).format(record.amount)},
  { label: 'Comment', name: 'commentSelenium', value: record.comment },
])

export default WithdrawalInfoModal
